// colors

$color-1: #445b7c; // blue dark
$color-2: #dc717d; // salmon
$color-3: #ffffff; // white

$white: white;
$black: #080808;
$gold: #f7edab;

$red: #e20315;
$yellow: #ffc221;
$grey: #3f393b;
$greyer: #c4c4c4;

// animation

$transition: .3s ease;

// fonts 

$primary-font: 'Raleway', sans-serif;

// bounce-in duration

$bounce-in-duration: .7s;

$mobile-breakpoint: 720px;