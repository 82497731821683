@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,700,800,900");
@import url("https://fonts.googleapis.com/css?family=Montserrat:700");
@import url("https://fonts.googleapis.com/css?family=Niconne|Rochester");
@font-face {
  font-family: 'adjust';
  src: url("../fonts/adjust-font.eot");
  src: url("../fonts/adjust-font.eot?#iefix") format("embedded-opentype"), url("../fonts/adjust-font.woff") format("woff"), url("../fonts/adjust-font.ttf") format("truetype"), url("../fonts/adjust-font.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Malbeck';
  src: url("../fonts/Malbeck-Regular.eot");
  src: url("../fonts/Malbeck-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Malbeck-Regular.woff") format("woff"), url("../fonts/Malbeck-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

body {
  font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum=1';
  -ms-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  -o-font-feature-settings: 'lnum'; }

/* -------------------------------- 

	Primary style

-------------------------------- */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

.breakdown {
  display: none;
  -webkit-transform: translateX(1000vw);
  -ms-transform: translateX(1000vw);
  transform: translateX(1000vw);
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #2d2d2d; }
  .breakdown img {
    display: block;
    margin: 0 auto; }
  @media screen and (max-width: 700px) and (orientation: landscape) {
    .breakdown {
      display: block;
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
      z-index: 99999999999; } }

.clearfix {
  clear: both !important; }

a, .button {
  cursor: pointer; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Raleway', sans-serif; }
  @media screen and (max-width: 700px) {
    body.is-open {
      position: fixed; } }
  body a.adjust {
    font-family: 'adjust';
    color: white; }

p {
  font-weight: 500; }

embed {
  width: 100% !important;
  height: 400px !important; }

.no-border {
  display: block;
  border: none; }

.inner.articles blockquote {
  border-left: none; }

blockquote p {
  margin-bottom: 0;
  font-style: italic; }

blockquote .author {
  font-style: normal;
  font-size: .9em;
  display: block; }
  blockquote .author::before {
    content: "−";
    padding-left: 1rem;
    padding-right: .5rem; }

video {
  display: block;
  width: 80%;
  margin: 3rem auto; }

div.linear-back {
  position: fixed;
  width: 100%;
  height: 100%;
  content: '';
  background-size: 100% auto !important;
  top: 0;
  left: 0; }

.back-row {
  display: block;
  width: 100%; }
  .back-row .back {
    display: inline-block;
    padding: 4px 8px;
    background: rgba(196, 196, 196, 0.4);
    font-weight: bold; }

.back {
  color: #e20315;
  font-size: 0.9em;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
  margin-left: 10px; }
  .back.inpage {
    margin-left: 0; }
  .back i {
    margin: 0 0.5em;
    transition: 0.4s;
    margin-left: 0; }
  .back h2 {
    color: #e20315;
    font-size: 0.9em; }
    .back h2 i {
      margin: 0 0.5em;
      transition: 0.4s;
      margin-left: 0; }
  .back:hover h2 i, .back:hover i {
    margin-right: 1em; }
  .back.blog {
    position: relative;
    color: white; }
    .back.blog h2 {
      color: white;
      text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3); }
      .back.blog h2 i {
        margin-left: 0.5em; }

.discover {
  border: 1px solid #080808;
  border-radius: 50px;
  display: inline-block;
  padding: 8px 20px;
  font-weight: 600;
  position: relative;
  transition: 0.3s ease;
  font-weight: 500; }
  .discover:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, #a2130b 0%, #e40215 50%, #a2130b 100%);
    z-index: -1;
    border-radius: 50px;
    opacity: 0;
    transition: 0.3s ease; }
  .discover:hover {
    color: white;
    border: 1px solid rgba(8, 8, 8, 0); }
    .discover:hover:before {
      opacity: 1; }

/* -------------------------------- 

	Main components 

-------------------------------- */
a.cta {
  color: white;
  text-transform: uppercase;
  background: linear-gradient(to right, #fff 50%, transparent), linear-gradient(to right, #a2130b 0%, #e40215 50%, #a2130b 100%);
  background-position: 200% 0, 50%;
  background-size: 200% 100%, 100% 100%;
  background-repeat: no-repeat, repeat;
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;
  font-size: .9em;
  font-weight: 500;
  border: 1px solid #FFF;
  transition: .3s;
  position: relative;
  overflow: hidden; }
  a.cta:hover {
    color: #e20315;
    background-position: 0 0, 50%; }
    .menu a.cta:hover {
      color: white; }
  a.cta.white {
    color: #e20315;
    background-position: 0 0, 50%; }
  a.cta.white:hover {
    background-position: 200% 0, 50%;
    color: white; }

header {
  position: absolute;
  z-index: 1; }
  header a.logo {
    text-align: center; }
  header ul.language {
    display: inline;
    position: absolute;
    left: 230px;
    top: 50%;
    transform: translateY(-75%); }
    header ul.language li {
      display: inline-block;
      padding: 0.3em; }
      header ul.language li a {
        color: #FFFFFF;
        text-decoration: none;
        opacity: 0;
        transition: .4s; }
        header ul.language li a:hover {
          text-decoration: underline; }
        header ul.language li a.active {
          opacity: 1; }
        header ul.language li a:hover {
          color: #a2130b; }
    header ul.language:hover li a {
      opacity: 1; }

.error-warning {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px; }

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    transform: translate3d(4px, 0, 0); } }

.thanks {
  display: block;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20; }
  .thanks .content {
    background: white;
    padding: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2em;
    border-top: 10px solid #e20315; }
    .thanks .content a.close {
      position: absolute;
      right: 2px;
      bottom: 2px;
      cursor: pointer;
      font-size: .8em;
      color: black;
      line-height: 0.5em;
      padding: 10px;
      opacity: .7; }

section.slider {
  height: 100vh;
  width: 100%;
  background-size: cover !important;
  background-position: 50% 50% !important;
  position: relative;
  overflow: hidden; }
  section.slider div.scroll a {
    position: absolute;
    height: 45px;
    width: 45px;
    border: 2px solid white;
    border-radius: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 20px;
    background: url("../img/arrow-pointing-to-down.svg") 50% 50%;
    background-size: cover; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  section.slider div.title {
    color: white;
    margin: 0 auto;
    transform: translateY(-50%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.5) 0%, transparent 75%);
    padding: 40px;
    opacity: 0; }
    @media screen and (max-width: 670px) {
      section.slider div.title {
        width: 100%; } }
    section.slider div.title.animation-3 {
      opacity: 1;
      animation-name: fadein;
      animation-timing-function: ease;
      animation-duration: 1s;
      animation-delay: 2s;
      animation-fill-mode: backwards; }
      @media screen and (max-width: 670px) {
        section.slider div.title.animation-3 {
          animation-delay: .5s; } }
    section.slider div.title h1 {
      font-family: 'Malbeck';
      font-size: 4em;
      font-weight: 100;
      margin: 0; }
    section.slider div.title h2 {
      font-family: 'Malbeck';
      font-size: 2em;
      font-weight: 100;
      margin: 0;
      margin-bottom: 20px;
      margin-top: -20px; }
    section.slider div.title p {
      margin-top: 0;
      margin-bottom: 30px; }
    section.slider div.title span.special {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      opacity: .5;
      font-size: 25em;
      font-weight: 700;
      z-index: -1; }
      section.slider div.title span.special:first-of-type {
        top: -130px;
        left: -50px; }
      section.slider div.title span.special:last-of-type {
        bottom: -230px;
        right: -60px; }
    section.slider div.title a {
      pointer-events: auto;
      position: relative;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      width: 150px;
      display: block; }

section.wearefamily {
  background: #f0f0f3;
  overflow: hidden;
  text-align: center;
  color: rgba(8, 8, 8, 0.8); }
  section.wearefamily h2 {
    font-weight: 300;
    font-size: 3em;
    margin-top: 45px;
    margin-bottom: 20px; }
  section.wearefamily p {
    width: 65%;
    margin: 0 auto;
    margin-bottom: 50px;
    line-height: 25px; }
  section.wearefamily a.play {
    position: relative;
    top: -25px; }
    section.wearefamily a.play img {
      height: 50px;
      width: 52px;
      opacity: .8; }

section.newcheese {
  background: #f0f0f3;
  overflow: hidden;
  text-align: left;
  color: rgba(8, 8, 8, 0.8);
  padding: 36px 0 30px; }
  section.newcheese .row {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 900px) {
      section.newcheese .row {
        justify-content: center; } }
    @media screen and (max-width: 640px) {
      section.newcheese .row {
        flex-direction: column; } }
    section.newcheese .row .text-block {
      padding: 0 0 26px;
      width: 40%;
      white-space: nowrap; }
      @media screen and (max-width: 1040px) {
        section.newcheese .row .text-block {
          padding: 0 40px 26px 20px;
          width: auto; } }
      @media screen and (max-width: 640px) {
        section.newcheese .row .text-block {
          padding: 25px 50px;
          width: 100%; } }
      @media screen and (max-width: 550px) {
        section.newcheese .row .text-block {
          padding: 25px; } }
      @media screen and (max-width: 380px) {
        section.newcheese .row .text-block {
          white-space: normal; } }
      section.newcheese .row .text-block h2 {
        text-transform: uppercase;
        font-size: 4rem;
        margin: 0; }
        @media screen and (max-width: 380px) {
          section.newcheese .row .text-block h2 {
            font-size: 3.4rem; } }
      section.newcheese .row .text-block p {
        line-height: 1.4rem;
        margin-bottom: 36px; }
      section.newcheese .row .text-block a.cta {
        border: none; }
    section.newcheese .row img {
      margin: 0;
      padding: 0;
      min-width: 0;
      min-height: 0;
      width: 40%;
      height: auto; }
      @media screen and (max-width: 1000px) {
        section.newcheese .row img {
          width: 40%;
          padding: 0 20px; } }
      @media screen and (max-width: 640px) {
        section.newcheese .row img {
          width: 70%; } }
      @media screen and (max-width: 380px) {
        section.newcheese .row img {
          width: 80%; } }
      section.newcheese .row img.diapos {
        width: 30%;
        margin-right: 40px; }
        @media screen and (max-width: 1000px) {
          section.newcheese .row img.diapos {
            display: none; } }

section.mosaic {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden; }
  section.mosaic.mosaic_home div.mosaic_block div.mosaic_background {
    height: 100% !important; }
  section.mosaic.mosaic_home div.mosaic_block div.mosaic_content {
    position: absolute;
    background: none;
    bottom: 0px;
    top: initial;
    height: auto;
    padding: 0; }
  section.mosaic.mosaic_home .discover {
    border: 2px solid white;
    border-radius: 50px;
    display: inline-block;
    padding: 8px 20px;
    font-weight: 600;
    position: relative;
    transition: 0.3s ease;
    color: white;
    z-index: 2;
    position: relative;
    top: 75%; }
    @media screen and (max-width: 1000px) {
      section.mosaic.mosaic_home .discover {
        font-size: 1.3em; } }
    section.mosaic.mosaic_home .discover:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to right, #a2130b 0%, #e40215 50%, #a2130b 100%);
      z-index: -1;
      border-radius: 50px;
      opacity: 0;
      transition: 0.3s ease;
      z-index: -1; }
    section.mosaic.mosaic_home .discover:hover {
      color: white; }
      section.mosaic.mosaic_home .discover:hover:before {
        opacity: 1; }
  section.mosaic.mosaic_blog {
    padding-top: 200px;
    background-size: cover; }
    section.mosaic.mosaic_blog div.linear-back {
      position: fixed;
      width: 100%;
      height: 100%;
      content: '';
      background-size: 100% auto !important;
      top: 0;
      left: 0; }
    section.mosaic.mosaic_blog div.mosaic_block {
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
      height: auto !important; }
      section.mosaic.mosaic_blog div.mosaic_block .debug_id {
        position: absolute;
        top: 1em;
        right: 1em;
        background: white;
        padding: 0.5em;
        z-index: 30; }
        section.mosaic.mosaic_blog div.mosaic_block .debug_id a {
          color: black; }
          section.mosaic.mosaic_blog div.mosaic_block .debug_id a:hover {
            text-decoration: underline; }
      section.mosaic.mosaic_blog div.mosaic_block .goto {
        position: absolute;
        right: 18px;
        bottom: 10px;
        width: 20px;
        opacity: .5;
        transition: .3s ease; }
      section.mosaic.mosaic_blog div.mosaic_block:hover .goto {
        right: 12px; }
      section.mosaic.mosaic_blog div.mosaic_block.articles div.mosaic_content {
        border-top: 10px solid #e20315; }
        section.mosaic.mosaic_blog div.mosaic_block.articles div.mosaic_content h3 {
          color: #e20315; }
      section.mosaic.mosaic_blog div.mosaic_block.articles div.link-holder {
        background: #e20315; }
      section.mosaic.mosaic_blog div.mosaic_block.recettes div.mosaic_content {
        border-top: 10px solid #ffc221; }
        section.mosaic.mosaic_blog div.mosaic_block.recettes div.mosaic_content h3 {
          color: #ffc221; }
      section.mosaic.mosaic_blog div.mosaic_block.recettes div.link-holder {
        background: #ffc221; }
      section.mosaic.mosaic_blog div.mosaic_block.tips div.mosaic_content {
        border-top: 10px solid #c4c4c4; }
        section.mosaic.mosaic_blog div.mosaic_block.tips div.mosaic_content h3 {
          color: #c4c4c4; }
      section.mosaic.mosaic_blog div.mosaic_block.tips div.link-holder {
        background: #c4c4c4; }
      section.mosaic.mosaic_blog div.mosaic_block.videos div.mosaic_content {
        border-top: 10px solid #3f393b; }
        section.mosaic.mosaic_blog div.mosaic_block.videos div.mosaic_content h3 {
          color: #3f393b; }
      section.mosaic.mosaic_blog div.mosaic_block.videos div.link-holder {
        background: #3f393b; }
      section.mosaic.mosaic_blog div.mosaic_block div.mosaic_content {
        height: 140px;
        padding: 10px 20px;
        bottom: 0;
        top: initial;
        color: #080808;
        text-align: left;
        transition: 0.3s ease;
        position: relative; }
        section.mosaic.mosaic_blog div.mosaic_block div.mosaic_content h3 {
          margin-top: 0;
          text-transform: uppercase;
          margin-bottom: 8px;
          text-align: left; }
        section.mosaic.mosaic_blog div.mosaic_block div.mosaic_content p {
          font-weight: 600;
          margin: 0;
          font-weight: 500;
          font-size: 1.1em;
          max-width: 450px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          color: rgba(8, 8, 8, 0.8);
          text-align: left; }
        section.mosaic.mosaic_blog div.mosaic_block div.mosaic_content a {
          text-align: center; }
      section.mosaic.mosaic_blog div.mosaic_block div.link-holder {
        background: red;
        position: absolute;
        width: 100%;
        height: 60px;
        bottom: 0;
        left: 0;
        text-align: center;
        transform: translateY(60px);
        transition: 0.3s ease; }
        section.mosaic.mosaic_blog div.mosaic_block div.link-holder a {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          border: 2px solid white;
          border-radius: 50px;
          width: 65px;
          margin: 0 auto;
          padding: 7px 0px; }
          section.mosaic.mosaic_blog div.mosaic_block div.link-holder a img {
            width: 13px;
            position: relative;
            top: 1px;
            transition: 0.3s ease; }
  section.mosaic div.container {
    max-width: 960px;
    margin: 0 auto;
    overflow: hidden; }
    @media screen and (max-width: 670px) {
      section.mosaic div.container {
        padding-top: 20px; } }
  section.mosaic div.mosaic_block {
    width: calc((100% / 3) - 20px);
    float: left;
    margin: 10px;
    position: relative;
    overflow: hidden;
    transition: 0.3s ease; }
    @media screen and (max-width: 1000px) {
      section.mosaic div.mosaic_block {
        width: calc((100% / 2) - 20px); } }
    section.mosaic div.mosaic_block:hover {
      transform: scale(0.98); }
      section.mosaic div.mosaic_block:hover .goto {
        right: 12px; }
    section.mosaic div.mosaic_block div.mosaic_background {
      width: 100%;
      height: 100%;
      background-size: cover !important;
      background-position: 50% 50% !important;
      top: 0;
      left: 0;
      background: #e20315;
      transition: 0.3s ease;
      position: relative;
      height: 210px; }
    section.mosaic div.mosaic_block div.mosaic_content {
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 10px;
      position: relative;
      padding: 25px;
      float: left;
      height: 140px;
      width: 100%;
      background: white; }
      section.mosaic div.mosaic_block div.mosaic_content button {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        border: 2px solid white;
        border-radius: 50px;
        width: 65px;
        margin: 0 auto;
        padding: 7px 0px;
        background: transparent; }
        section.mosaic div.mosaic_block div.mosaic_content button img {
          width: 13px;
          position: relative;
          top: 1px;
          transition: 0.3s ease; }
      section.mosaic div.mosaic_block div.mosaic_content a.cta {
        position: absolute;
        bottom: 20px;
        font-weight: 500;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 80px;
        transition: 0.3s ease;
        border: 1px solid white;
        background: none; }
        section.mosaic div.mosaic_block div.mosaic_content a.cta:hover:before {
          opacity: 1; }
        section.mosaic div.mosaic_block div.mosaic_content a.cta:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(to right, #a2130b 0%, #e40215 50%, #a2130b 100%);
          z-index: -1;
          border-radius: 50px;
          opacity: 0;
          transition: 0.3s ease; }

section.app {
  position: relative;
  background: url("../img/easydinner.jpg");
  background-size: cover;
  background-position: 0% 50%;
  overflow: hidden;
  width: 100%;
  height: 180px; }
  section.app img {
    width: 100%; }
  section.app div.app_download {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 210px; }
    @media screen and (min-width: 1620px) {
      section.app div.app_download {
        margin-left: 310px; } }
    section.app div.app_download h3 {
      text-transform: uppercase;
      color: white;
      font-size: 1em;
      margin: 0;
      margin-bottom: 20px;
      position: relative; }
      section.app div.app_download h3:after {
        content: '';
        position: absolute;
        right: -25px;
        bottom: -20px;
        width: 30px;
        height: 30px;
        background: url("../img/arrow-red.png");
        background-size: cover; }
    section.app div.app_download img {
      width: 140px;
      display: block;
      margin-top: 10px; }

section.front-banner,
section.cheeseletter,
section.cheeseletter_old {
  background: #f0f0f3;
  overflow: hidden;
  padding: 50px;
  color: rgba(8, 8, 8, 0.8);
  position: relative; }
  section.front-banner > div:not(.cta-form),
  section.cheeseletter > div:not(.cta-form),
  section.cheeseletter_old > div:not(.cta-form) {
    max-width: 1200px;
    position: relative;
    margin: 0 auto; }
  section.front-banner p,
  section.cheeseletter p,
  section.cheeseletter_old p {
    margin: 0; }
  section.front-banner h3,
  section.cheeseletter h3,
  section.cheeseletter_old h3 {
    text-transform: uppercase;
    font-weight: 500;
    margin: 0;
    margin-top: 5px;
    font-size: 1.5em; }
    section.front-banner h3 b,
    section.cheeseletter h3 b,
    section.cheeseletter_old h3 b {
      color: #080808; }
  section.front-banner form,
  section.front-banner a[data-call="cheeseletter-subscribe"],
  section.cheeseletter form,
  section.cheeseletter a[data-call="cheeseletter-subscribe"],
  section.cheeseletter_old form,
  section.cheeseletter_old a[data-call="cheeseletter-subscribe"] {
    position: absolute;
    right: 0;
    bottom: 0; }
  @media screen and (max-width: 1100px) {
    section.front-banner form,
    section.front-banner a[data-call="cheeseletter-subscribe"],
    section.cheeseletter form,
    section.cheeseletter a[data-call="cheeseletter-subscribe"],
    section.cheeseletter_old form,
    section.cheeseletter_old a[data-call="cheeseletter-subscribe"] {
      position: relative;
      bottom: initial;
      right: initial;
      margin-top: 30px; }
    section.front-banner a[data-call="cheeseletter-subscribe"],
    section.cheeseletter a[data-call="cheeseletter-subscribe"],
    section.cheeseletter_old a[data-call="cheeseletter-subscribe"] {
      display: inline-block; } }
  section.front-banner input,
  section.cheeseletter input,
  section.cheeseletter_old input {
    border: none;
    margin: 0;
    padding: 0;
    font-weight: 500; }
  section.front-banner input[type=text],
  section.cheeseletter input[type=text],
  section.cheeseletter_old input[type=text] {
    padding: 10px 20px;
    border-radius: 50px 0px 0px 50px;
    font-size: .9em;
    width: 300px;
    position: relative;
    right: -5px;
    background: white;
    outline: initial;
    height: auto;
    box-shadow: initial;
    box-sizing: inherit;
    color: #080808; }
    section.front-banner input[type=text]:focus,
    section.cheeseletter input[type=text]:focus,
    section.cheeseletter_old input[type=text]:focus {
      border-bottom: none !important;
      box-shadow: none !important; }
    section.front-banner input[type=text]::-webkit-input-placeholder,
    section.cheeseletter input[type=text]::-webkit-input-placeholder,
    section.cheeseletter_old input[type=text]::-webkit-input-placeholder {
      color: #171717 !important; }
    section.front-banner input[type=text]::-moz-placeholder,
    section.cheeseletter input[type=text]::-moz-placeholder,
    section.cheeseletter_old input[type=text]::-moz-placeholder {
      color: #171717 !important; }
    section.front-banner input[type=text]::-ms-input-placeholder,
    section.cheeseletter input[type=text]::-ms-input-placeholder,
    section.cheeseletter_old input[type=text]::-ms-input-placeholder {
      color: #171717 !important; }
    section.front-banner input[type=text]::placeholder,
    section.cheeseletter input[type=text]::placeholder,
    section.cheeseletter_old input[type=text]::placeholder {
      color: #171717 !important; }
    @media screen and (max-width: 745px) {
      section.front-banner input[type=text],
      section.cheeseletter input[type=text],
      section.cheeseletter_old input[type=text] {
        width: 100%;
        border-radius: 50px; } }
  section.front-banner input[type=submit],
  section.cheeseletter input[type=submit],
  section.cheeseletter_old input[type=submit] {
    background: linear-gradient(to right, #a2130b 0%, #e40215 50%, #a2130b 100%);
    padding: 10px 20px;
    border-radius: 0px 50px 50px 0px;
    color: white;
    font-size: .9em; }
    @media screen and (max-width: 745px) {
      section.front-banner input[type=submit],
      section.cheeseletter input[type=submit],
      section.cheeseletter_old input[type=submit] {
        border-radius: 50px;
        width: 103%;
        margin-top: 1em; } }

section.cheeseletter a[data-call="cheeseletter-subscribe"] {
  position: relative; }

section.inner {
  width: 100%;
  background-size: cover !important;
  background-position: 50% 0% !important;
  padding-top: 200px;
  padding-bottom: 60px; }
  section.inner.articles.Recettes div.container {
    border-top: 10px solid #ffc221; }
    section.inner.articles.Recettes div.container h2, section.inner.articles.Recettes div.container h3 {
      color: #ffc221; }
      section.inner.articles.Recettes div.container h2 a i, section.inner.articles.Recettes div.container h3 a i {
        color: #ffc221; }
  section.inner.articles.Videos div.container {
    border-top: 10px solid #3f393b; }
    section.inner.articles.Videos div.container h2, section.inner.articles.Videos div.container h3 {
      color: #3f393b; }
      section.inner.articles.Videos div.container h2 a i, section.inner.articles.Videos div.container h3 a i {
        color: #3f393b; }
  section.inner.articles.Tips div.container {
    border-top: 10px solid #c4c4c4; }
    section.inner.articles.Tips div.container h2, section.inner.articles.Tips div.container h3 {
      color: #c4c4c4; }
      section.inner.articles.Tips div.container h2 a i, section.inner.articles.Tips div.container h3 a i {
        color: #c4c4c4; }
  section.inner.articles div.container {
    border-top: 10px solid #e20315; }
    section.inner.articles div.container ul:first-of-type {
      list-style-type: disc;
      padding-left: 24px; }
      section.inner.articles div.container ul:first-of-type li {
        list-style-type: inherit; }
    section.inner.articles div.container ul:last-of-type, section.inner.articles div.container ol {
      padding-left: 20px; }
      section.inner.articles div.container ul:last-of-type li, section.inner.articles div.container ol li {
        list-style-type: decimal;
        line-height: 25px;
        color: inherit;
        position: relative;
        z-index: 2;
        padding-left: 5px;
        font-weight: 500;
        font-size: 14px; }
        section.inner.articles div.container ul:last-of-type li:before, section.inner.articles div.container ol li:before {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          background: #e20315;
          top: 3px;
          left: -20px;
          z-index: -1; }
        section.inner.articles div.container ul:last-of-type li span, section.inner.articles div.container ol li span {
          color: #080808;
          font-weight: 400;
          font-size: 16px; }
    section.inner.articles div.container ul li {
      line-height: 20px; }
    section.inner.articles div.container h2 {
      color: #e20315; }
      section.inner.articles div.container h2 a i {
        color: #e20315; }
    section.inner.articles div.container p span {
      color: #e20315;
      font-style: italic;
      text-align: center;
      display: block; }
    section.inner.articles div.container h3 {
      color: #e20315; }
    section.inner.articles div.container p.breadcrumb {
      color: rgba(8, 8, 8, 0.8);
      margin-bottom: 20px;
      margin-top: -10px;
      font-size: .9em;
      opacity: .9;
      margin-left: 10px; }
      section.inner.articles div.container p.breadcrumb a {
        color: inherit; }
        section.inner.articles div.container p.breadcrumb a.active {
          color: #e20315; }
  section.inner.recettes div.container {
    border-top: 10px solid #ffc221; }
    section.inner.recettes div.container h2 {
      color: #ffc221; }
      section.inner.recettes div.container h2 a i {
        color: #ffc221; }
  section.inner.videos div.container {
    border-top: 10px solid #3f393b; }
    section.inner.videos div.container h2 {
      color: #3f393b; }
      section.inner.videos div.container h2 a i {
        color: #3f393b; }
  section.inner.tips div.container {
    border-top: 10px solid #c4c4c4; }
    section.inner.tips div.container h2 {
      color: #c4c4c4; }
      section.inner.tips div.container h2 a i {
        color: #c4c4c4; }
  section.inner div.container {
    max-width: 960px;
    margin: 0 auto;
    background: white;
    padding: 40px 50px;
    position: relative;
    border-top: 10px solid red;
    box-shadow: 0px 10px 80px 0px rgba(0, 0, 0, 0.4);
    color: rgba(8, 8, 8, 0.9); }
    section.inner div.container img.img-article {
      width: 400px;
      float: right;
      padding-left: 10px; }
    section.inner div.container .social {
      position: absolute;
      right: 1em;
      bottom: 1em; }
    section.inner div.container:after {
      content: "";
      display: table;
      clear: both; }
    section.inner div.container h2 {
      margin-top: 0;
      text-transform: uppercase;
      transition: 0.3s ease;
      margin-top: -15px; }
      section.inner div.container h2:hover {
        margin-left: -5px; }
      section.inner div.container h2 i {
        margin-right: 10px; }
      section.inner div.container h2 a {
        margin-right: 10px;
        position: relative;
        transition: 0.3s ease;
        left: 0; }
        section.inner div.container h2 a:hover {
          left: -5px; }
    section.inner div.container h1 {
      margin-top: 0;
      font-size: 1.4em;
      margin-bottom: 5px; }
    section.inner div.container span.timing {
      opacity: .6;
      font-style: italic;
      font-weight: 500; }
    section.inner div.container p.subline {
      font-weight: 700; }
    section.inner div.container p {
      line-height: 1.4em; }

@keyframes popup {
  from {
    transform: translateX(100%); }
  to {
    transform: translateX(0%); } }

div.cta-block.nope div.container {
  right: -100%;
  transition: 1s ease; }

div.cta-block div.container {
  position: fixed;
  z-index: 2;
  background: white;
  top: 100px;
  right: 0;
  text-align: center;
  box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.4);
  transform: translateX(100%);
  transition: 0.3s ease;
  max-width: 320px; }
  div.cta-block div.container div.form-container {
    max-height: 70vh;
    overflow: scroll;
    padding: 40px; }
  div.cta-block div.container.show {
    animation-name: popup;
    animation-timing-function: ease;
    animation-duration: 1s;
    animation-delay: 3s;
    animation-fill-mode: forwards; }
  div.cta-block div.container.moved {
    transform: translateX(0); }
  div.cta-block div.container a.stored {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    background: #e20315;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../img/cheese.svg");
    display: none; }
  div.cta-block div.container.stored a.stored {
    display: block; }

div.cta-block h2 {
  margin-top: 0;
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: 500;
  font-weight: bolder; }

div.cta-block a.close {
  position: absolute;
  right: 10px;
  top: 6px;
  color: #e20315;
  font-size: 1.2em; }
  div.cta-block a.close span {
    font-size: .6em;
    bottom: 1px;
    position: relative; }

div.cta-block input {
  border: none;
  margin: 0;
  padding: 0;
  font-weight: 500; }

div.cta-block ::-webkit-input-placeholder {
  color: #080808; }

div.cta-block ::-moz-input-placeholder {
  color: #080808; }

div.cta-block ::-ms-input-placeholder {
  color: #080808; }

div.cta-block input[type=text] {
  padding: 10px 20px;
  border-radius: 50px;
  font-size: .9em;
  width: 100%;
  position: relative;
  background: rgba(196, 196, 196, 0.3);
  display: block;
  outline: initial;
  height: auto;
  box-shadow: initial;
  box-sizing: inherit;
  color: #080808;
  margin-bottom: .25rem; }

div.cta-block input[type=submit] {
  background: linear-gradient(to right, #a2130b 0%, #e40215 50%, #a2130b 100%);
  padding: 10px 20px;
  border-radius: 50px;
  width: 100%;
  color: white;
  font-size: .9em;
  margin-top: 15px; }

div.cta-block form label {
  text-align: left;
  margin: .5rem 0 0;
  display: inline-block; }

div.cta-block label + p {
  font-size: .8rem;
  color: #888; }

div.cta-block label span {
  text-align: left;
  line-height: 1.34; }

div.cta-block label input[type="checkbox"] {
  position: static;
  opacity: 1; }

section.inner.articles.Recettes div.container ul:last-of-type li:before {
  background: #ffc221; }

section.inner.articles.Videos div.container ul:last-of-type li:before {
  background: #3f393b; }

section.inner.articles.Tips div.container ul:last-of-type li:before {
  background: #c4c4c4; }

section.front-banner,
section.cheeseletter {
  background: linear-gradient(90deg, #a2130b 0, #e40215 50%, #a2130b);
  padding-bottom: 60px;
  padding: 25px 100px 50px; }
  section.front-banner span,
  section.cheeseletter span {
    color: white;
    text-transform: uppercase;
    font-size: 110px;
    font-weight: 800;
    position: relative; }
    @media screen and (max-width: 1280px) {
      section.front-banner span,
      section.cheeseletter span {
        font-size: 80px; } }
  section.front-banner h3, section.front-banner p,
  section.cheeseletter h3,
  section.cheeseletter p {
    color: white;
    margin-left: 8px; }
    section.front-banner h3 b, section.front-banner p b,
    section.cheeseletter h3 b,
    section.cheeseletter p b {
      color: white; }
  section.front-banner p,
  section.cheeseletter p {
    margin-bottom: 35px;
    font-size: 14px; }
  section.front-banner h3,
  section.cheeseletter h3 {
    text-transform: initial;
    font-size: 20px;
    margin: initial;
    margin-bottom: 10px;
    margin-left: 8px; }
    @media screen and (max-width: 1280px) {
      section.front-banner h3,
      section.cheeseletter h3 {
        font-size: 18px; } }
  section.front-banner img,
  section.cheeseletter img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 120%;
    right: 100px; }
    @media screen and (max-width: 1280px) {
      section.front-banner img,
      section.cheeseletter img {
        height: 100%; } }
  section.front-banner.sos,
  section.cheeseletter.sos {
    background: #f24d67;
    text-align: center;
    padding: 2em;
    border-bottom: 60px solid white; }
    section.front-banner.sos p,
    section.cheeseletter.sos p {
      font-size: 1.2em;
      margin: 0.8em;
      margin-bottom: 1.6em; }
    section.front-banner.sos a.cta,
    section.cheeseletter.sos a.cta {
      background: transparent;
      color: white;
      text-transform: none;
      transition: 0.4s; }
      section.front-banner.sos a.cta:hover,
      section.cheeseletter.sos a.cta:hover {
        background: white;
        color: #f24d67; }
    section.front-banner.sos img,
    section.cheeseletter.sos img {
      position: static;
      height: auto;
      width: 250px;
      transform: none; }

section.voucher {
  padding: 25px 50px 50px; }
  section.voucher > div:not(.cta-form) {
    margin: 0 auto;
    max-width: 1200px;
    position: relative; }
  section.voucher h3, section.voucher p, section.voucher a {
    margin-left: 5px; }
  @media screen and (max-width: 550px) {
    section.voucher {
      padding: 15px 25px 40px !important; }
      section.voucher span {
        font-size: 4rem; } }
  section.voucher img {
    right: 0;
    top: 56%;
    height: 150%; }
    @media screen and (max-width: 1280px) {
      section.voucher img {
        height: 160%; } }
    @media screen and (max-width: 940px) {
      section.voucher img {
        height: 140%; } }
    @media screen and (max-width: 900px) {
      section.voucher img {
        display: none; } }

/* Cheeseletter box */
#cheeseletter-box {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: none; }
  #cheeseletter-box div.cta-form-holder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 750px;
    padding: 100px 50px;
    background: white;
    border-radius: 2px;
    background: linear-gradient(90deg, #a2130b, #e40215 50%, #a2130b);
    text-align: center; }
    @media screen and (max-width: 745px) {
      #cheeseletter-box div.cta-form-holder {
        padding: 50px 50px 20px; } }
    #cheeseletter-box div.cta-form-holder h3 {
      margin-bottom: 20px;
      margin-left: 0;
      margin-top: 0;
      text-transform: initial;
      font-size: 20px;
      color: white;
      font-weight: 500; }
      @media screen and (max-width: 1280px) {
        #cheeseletter-box div.cta-form-holder h3 {
          font-size: 18px; } }
      @media screen and (max-width: 745px) {
        #cheeseletter-box div.cta-form-holder h3 {
          font-size: 15px;
          margin-bottom: 10px; } }
    #cheeseletter-box div.cta-form-holder form {
      position: initial;
      bottom: initial;
      right: initial;
      text-align: center; }
      #cheeseletter-box div.cta-form-holder form input:not([type="checkbox"]) {
        box-shadow: none;
        padding: 10px 20px;
        font-size: .9em;
        width: 300px;
        border: none;
        outline: none; }
        @media screen and (max-width: 745px) {
          #cheeseletter-box div.cta-form-holder form input:not([type="checkbox"]) {
            width: 100%;
            border-radius: 50px;
            padding: 6px 20px;
            font-size: .8em; } }
      #cheeseletter-box div.cta-form-holder form .input-radius {
        border-radius: 50px;
        margin: 20px 10px; }
        @media screen and (max-width: 745px) {
          #cheeseletter-box div.cta-form-holder form .input-radius {
            margin-top: 0;
            margin-bottom: .5rem;
            margin-left: 0; } }
      #cheeseletter-box div.cta-form-holder form input.fullradius {
        width: 96%;
        border-radius: 50px; }
        @media screen and (max-width: 745px) {
          #cheeseletter-box div.cta-form-holder form input.fullradius {
            width: 100%; } }
      #cheeseletter-box div.cta-form-holder form input.fullsubmit {
        border-radius: 50px;
        margin-top: 30px;
        width: 200px;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
        transition: .3s ease;
        background-image: linear-gradient(left, #a2130b, #e40215 50%, #a2130b);
        color: white;
        outline: none;
        border: none;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25); }
        #cheeseletter-box div.cta-form-holder form input.fullsubmit:hover {
          opacity: .8; }
        @media screen and (max-width: 745px) {
          #cheeseletter-box div.cta-form-holder form input.fullsubmit {
            margin-top: .5em;
            margin-left: auto;
            margin-right: auto;
            font-size: 15px; } }
    #cheeseletter-box div.cta-form-holder p {
      font-size: .7rem;
      color: white;
      padding: 0 1rem; }
      @media screen and (max-width: 745px) {
        #cheeseletter-box div.cta-form-holder p {
          margin: .25rem 0 .25rem -2rem;
          padding: 0;
          width: calc(100% + 4rem); } }
      #cheeseletter-box div.cta-form-holder p a {
        color: white;
        text-decoration: underline; }
    #cheeseletter-box div.cta-form-holder label {
      display: block;
      width: 100%;
      color: white;
      position: relative;
      margin-top: 1rem;
      padding: 1rem; }
      @media screen and (max-width: 745px) {
        #cheeseletter-box div.cta-form-holder label {
          margin-top: 10px;
          margin-bottom: .5rem; } }
      #cheeseletter-box div.cta-form-holder label + label {
        margin-top: 0; }
      #cheeseletter-box div.cta-form-holder label span {
        display: inline-block;
        padding-left: 2rem;
        text-align: left;
        width: 100%; }
        #cheeseletter-box div.cta-form-holder label span a {
          color: white;
          text-decoration: underline; }
      #cheeseletter-box div.cta-form-holder label input {
        display: inline-block;
        opacity: 1;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 1rem;
        top: 1rem; }
        @media screen and (max-width: 745px) {
          #cheeseletter-box div.cta-form-holder label input {
            top: 0; } }
      @media screen and (max-width: 745px) {
        #cheeseletter-box div.cta-form-holder label {
          padding: 0;
          width: calc(100% + 4rem);
          margin-left: -2rem;
          font-size: .65rem;
          line-height: 1.3; }
          #cheeseletter-box div.cta-form-holder label span {
            padding-left: 1.5rem; }
          #cheeseletter-box div.cta-form-holder label input {
            left: 0; } }
    #cheeseletter-box div.cta-form-holder a.close-cta-form {
      position: absolute;
      top: 20px;
      left: 20px;
      color: white;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      text-transform: uppercase; }
      @media screen and (max-width: 745px) {
        #cheeseletter-box div.cta-form-holder a.close-cta-form {
          left: 50%;
          transform: translateX(-50%); } }

/*  Cookie popin  */
.cookie-popin {
  width: 100%;
  padding: 2rem;
  position: fixed;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #a2130b, #e40215, #a2130b);
  transition: .3s;
  z-index: 100; }
  .cookie-popin.close {
    transform: translateY(100%); }
  .cookie-popin .container {
    width: 100%;
    max-width: 71.875rem;
    margin: auto;
    color: white; }
    .cookie-popin .container::after {
      content: "";
      display: table;
      clear: both; }
  .cookie-popin p {
    width: 80%;
    padding-right: 2rem;
    float: left;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1.4; }
    .cookie-popin p a {
      color: #fff4f1;
      text-decoration: underline; }
  .cookie-popin p + a {
    width: 20%;
    float: right;
    color: #e40215;
    background: white;
    padding: .75rem 1rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500; }
  @media screen and (max-width: 768px) {
    .cookie-popin {
      text-align: center; }
      .cookie-popin p {
        padding-right: 0;
        padding-bottom: 1rem; }
      .cookie-popin .container > * {
        float: none;
        margin-bottom: 1rem;
        width: 100%; }
      .cookie-popin p + a {
        max-width: 420px; } }
  @media screen and (max-width: 420px) {
    .cookie-popin p {
      font-size: .85rem; } }

div.slider-holder {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0; }

.cd-image-container {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0em auto;
  margin-top: 50vh;
  transform: translateY(-50%);
  background: url("../img/Bel_180502_1880_BDEF-compressor.jpg") 50% 50%;
  background-size: cover; }
  .cd-image-container img {
    display: block; }

.cd-image-label {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  padding: 1em;
  opacity: 0;
  transition: transform 0.3s 0.7s, opacity 0.3s 0.7s; }
  .cd-image-label.is-hidden {
    visibility: hidden; }
  .is-visible .cd-image-label {
    opacity: 1;
    transform: translateY(0); }

.cd-resize-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  transform: translateZ(0);
  backface-visibility: hidden; }
  @media screen and (max-width: 1000px) {
    .cd-resize-img {
      width: 100% !important; } }
  .cd-resize-img div.img-holder-full {
    background: url("../img/Bel_180502_1880_BDEF-2-compressor.jpg") 50% 50%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw; }
    .cd-resize-img div.img-holder-full .title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      @media screen and (max-width: 1000px) {
        .cd-resize-img div.img-holder-full .title {
          text-align: center; } }
  .cd-resize-img img {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: auto;
    max-width: none; }
  .is-visible .cd-resize-img {
    width: 15%; }
  .cd-resize-img.animation-1 {
    width: 85%;
    animation-name: discoverwidth;
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-delay: .5s;
    animation-fill-mode: backwards; }
    @media screen and (max-width: 670px) {
      .cd-resize-img.animation-1 {
        animation-delay: 0s; } }

@keyframes discover {
  from {
    left: 15%; }
  to {
    left: 85%; } }

@keyframes discoverwidth {
  from {
    width: 15%; }
  to {
    width: 85%; } }

@keyframes cd-bounce-in {
  0% {
    width: 0; }
  60% {
    width: 55%; }
  100% {
    width: 50%; } }

.cd-handle {
  position: absolute;
  height: 140px;
  width: 140px;
  /* center the element */
  left: 15%;
  top: 50%;
  margin-left: -70px;
  margin-top: -70px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  cursor: move;
  border: 5px solid white;
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(0); }
  .cd-handle.animation-2 {
    left: 85%;
    animation-name: discover;
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-delay: .5s;
    animation-fill-mode: backwards; }
    @media screen and (max-width: 670px) {
      .cd-handle.animation-2 {
        animation-delay: 0s; } }
  .cd-handle:before, .cd-handle:after {
    content: " ";
    display: block;
    width: 5px;
    background: white;
    height: 9999px;
    position: absolute;
    left: 50%;
    margin-left: -2px;
    z-index: 30; }
  .cd-handle:before {
    bottom: 50%;
    margin-bottom: 68px; }
  .cd-handle:after {
    top: 50%;
    margin-top: 68px; }
  .cd-handle img {
    max-width: initial;
    width: 215px;
    position: absolute;
    top: 50%;
    transform: translateY(-45%);
    left: -42px; }
  .cd-handle.draggable {
    /* change background color when element is active */ }
  .is-visible .cd-handle {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
    transition: transform 0.3s 0.7s, opacity 0s 0.7s; }

header {
  position: fixed;
  width: 100%;
  padding: 40px;
  top: 0;
  left: 0;
  z-index: 12;
  background: linear-gradient(0deg, transparent 0%, rgba(20, 20, 20, 0.6)); }
  header a.logo {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 200px;
    top: 20px; }
    header a.logo img {
      width: 100%; }
  header a.lang {
    color: white;
    text-decoration: none;
    position: relative;
    top: 6px;
    font-weight: 500;
    margin-left: 150px; }
  header a.home {
    color: white;
    text-decoration: none;
    padding: 10px 19px;
    border: 1px solid white;
    border-radius: 50px;
    position: relative;
    width: 10px;
    height: 10px;
    font-weight: 500;
    transition: 0.3s ease;
    bottom: 0;
    position: relative;
    top: 6px;
    z-index: 15; }
    header a.home:hover {
      bottom: -3px; }
      header a.home:hover:before {
        opacity: 1; }
    header a.home:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to right, #a2130b 0%, #e40215 50%, #a2130b 100%);
      z-index: -1;
      border-radius: 50px;
      opacity: 0;
      transition: 0.3s ease; }
    header a.home.inspiration {
      text-transform: uppercase;
      font-size: .9em;
      position: relative;
      overflow: hidden; }
      header a.home.inspiration i {
        position: initial;
        top: initial;
        transform: none;
        margin-right: 5px; }
    header a.home i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center; }

@media screen and (max-width: 1000px) {
  header ul:not(.language) li:nth-child(2), header ul:not(.language) li:nth-child(3), header ul:not(.language) li:nth-child(4) {
    display: none; } }

@media screen and (max-width: 1000px) {
  body.is-open header ul {
    z-index: 15; }
    body.is-open header ul li:nth-child(1) {
      display: none; }
    body.is-open header ul li:nth-child(2), body.is-open header ul li:nth-child(3), body.is-open header ul li:nth-child(4) {
      display: inline-block; } }

ul.mini-nav {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  float: right;
  position: relative;
  top: 6px; }
  ul.mini-nav li {
    display: inline-block;
    padding-left: 10px; }
    ul.mini-nav li a {
      color: white;
      text-decoration: none;
      padding: 10px 19px;
      border: 1px solid white;
      border-radius: 50px;
      position: relative;
      width: 10px;
      height: 10px;
      font-weight: 500;
      transition: 0.3s ease;
      bottom: 0; }
      ul.mini-nav li a:hover {
        bottom: -3px; }
        ul.mini-nav li a:hover:before {
          opacity: 1; }
      ul.mini-nav li a.home {
        border: 1px solid rgba(226, 3, 21, 0.8); }
        ul.mini-nav li a.home:before {
          opacity: 1; }
      ul.mini-nav li a:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(to right, #a2130b 0%, #e40215 50%, #a2130b 100%);
        z-index: -1;
        border-radius: 50px;
        opacity: 0;
        transition: 0.3s ease; }
      ul.mini-nav li a.inspiration {
        text-transform: uppercase;
        font-size: .9em;
        position: relative;
        overflow: hidden; }
        ul.mini-nav li a.inspiration i {
          position: initial;
          top: initial;
          transform: none;
          margin-right: 5px; }
      ul.mini-nav li a i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center; }

div.burger-holder {
  border: 1px solid white;
  border-radius: 50px;
  overflow: hidden;
  float: left;
  position: absolute;
  top: 35px;
  z-index: 15;
  left: 96px;
  overflow: hidden; }
  body.is-open div.burger-holder {
    position: fixed; }

.burger-menu {
  position: relative;
  width: 118px;
  height: 38px;
  color: white;
  text-transform: initial;
  float: left;
  transition: .3s ease .12s; }
  .burger-menu.is-open {
    width: 120px;
    transition: .3s ease; }
  .burger-menu:hover:before {
    opacity: 1; }
  .burger-menu:before {
    position: absolute;
    content: '';
    left: -20px;
    top: -20px;
    height: 200%;
    width: 200%;
    background: linear-gradient(to right, #a2130b 0%, #e40215 50%, #a2130b 100%);
    z-index: -1;
    border-radius: 50px;
    opacity: 0;
    transition: 0.3s ease; }

.burger-click-region {
  padding: 11px 19px;
  position: absolute;
  width: 100%;
  height: 40px;
  cursor: pointer;
  z-index: 1; }

.burger-menu-txt {
  display: block;
  position: absolute;
  left: 54px;
  top: 10px;
  cursor: default;
  transition: opacity 300ms ease-out, transform 300ms cubic-bezier(0.34, 0.55, 0.25, 0.83);
  font-size: 1em;
  font-weight: 500; }
  .is-open .burger-menu-txt {
    opacity: 0;
    transform: translate3d(50px, 0, 0); }

.burger-menu-txt-2 {
  display: block;
  position: absolute;
  left: 54px;
  top: 10px;
  cursor: default;
  transition: opacity 300ms ease-out, transform 300ms cubic-bezier(0.34, 0.55, 0.25, 0.83);
  font-size: 1em;
  font-weight: 500;
  opacity: 0;
  transform: translate3d(50px, 0, 0); }
  .is-open .burger-menu-txt-2 {
    opacity: 0;
    transform: translate3d(0px, 0, 0);
    opacity: 1; }

.burger-menu-piece {
  display: block;
  position: absolute;
  width: 20px;
  border-top: 2px solid white;
  transform-origin: 50% 50%;
  transition: transform 400ms ease-out; }
  .burger-menu-piece:nth-child(1) {
    top: 11px; }
  .burger-menu-piece:nth-child(2) {
    top: 17px;
    opacity: 1;
    transition: transform 400ms ease-out, opacity 0ms linear 200ms; }
  .burger-menu-piece:nth-child(3) {
    top: 23px; }
  .active .burger-menu-piece:nth-child(1) {
    animation: burger-open-top 400ms ease-out forwards; }
  .active .burger-menu-piece:nth-child(2) {
    opacity: 0;
    transition: transform 400ms ease-out, opacity 0ms linear 200ms; }
  .active .burger-menu-piece:nth-child(3) {
    animation: burger-open-bot 400ms ease-out forwards; }
  .closing .burger-menu-piece:nth-child(1) {
    animation: burger-close-top 400ms ease-out forwards; }
  .closing .burger-menu-piece:nth-child(3) {
    animation: burger-close-bot 400ms ease-out forwards; }

@keyframes burger-open-top {
  50% {
    transform: translate3d(0, 6px, 0); }
  100% {
    transform: translate3d(0, 6px, 0) rotate(45deg); } }

@keyframes burger-open-bot {
  50% {
    transform: translate3d(0, -6px, 0); }
  100% {
    transform: translate3d(0, -6px, 0) rotate(-45deg); } }

@keyframes burger-close-top {
  0% {
    transform: translate3d(0, 6px, 0) rotate(45deg); }
  50% {
    transform: translate3d(0, 6px, 0) rotate(0deg); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes burger-close-bot {
  0% {
    transform: translate3d(0, -6px, 0) rotate(-45deg); }
  50% {
    transform: translate3d(0, -6px, 0) rotate(0deg); }
  100% {
    transform: translate3d(0, 0, 0); } }

div.menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(8, 8, 8, 0.9);
  z-index: 10;
  top: 0;
  left: 0;
  transform: translateY(-100vh);
  transition: .5s ease; }
  @media screen and (max-width: 670px) {
    div.menu {
      height: 100%;
      transform: translateY(-200vh); } }
  body.is-open div.menu {
    transform: translateY(0); }
  div.menu div.selector-holder {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center; }
    div.menu div.selector-holder div.selector {
      color: white;
      width: calc(100% / 3);
      padding: 10px 60px;
      float: left;
      position: relative;
      min-height: 295px; }
      @media screen and (max-width: 1030px) {
        div.menu div.selector-holder div.selector {
          padding: 10px 30px; } }
      @media screen and (max-width: 1000px) {
        div.menu div.selector-holder div.selector {
          width: 100%;
          min-height: auto; } }
      @media screen and (max-width: 820px) {
        div.menu div.selector-holder div.selector .cta {
          margin: 0 -1rem;
          left: calc(50% + 1rem) !important; } }
      @media screen and (max-width: 670px) {
        div.menu div.selector-holder div.selector {
          max-height: 160px; } }
      div.menu div.selector-holder div.selector:hover h2 {
        color: #f7edab; }
      div.menu div.selector-holder div.selector:hover p {
        color: white; }
      div.menu div.selector-holder div.selector:nth-child(2) {
        border-left: 1px solid rgba(255, 255, 255, 0.8);
        border-right: 1px solid rgba(255, 255, 255, 0.8); }
      div.menu div.selector-holder div.selector h2 {
        text-transform: uppercase;
        font-size: 2.2em;
        transition: 0.3s ease; }
      div.menu div.selector-holder div.selector p {
        font-family: 'Malbeck';
        font-weight: 300;
        font-size: 2em;
        color: rgba(255, 255, 255, 0.9);
        transition: 0.3s ease;
        letter-spacing: 1px; }
      div.menu div.selector-holder div.selector a.cta {
        position: absolute;
        bottom: 2px;
        font-weight: 500;
        left: 50%;
        transform: translateX(-50%);
        transition: 0.3s ease;
        border: 1px solid white;
        background: none; }
        div.menu div.selector-holder div.selector a.cta:hover:before {
          opacity: 1; }
        div.menu div.selector-holder div.selector a.cta:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(to right, #a2130b 0%, #e40215 50%, #a2130b 100%);
          z-index: -1;
          border-radius: 50px;
          opacity: 0;
          transition: 0.3s ease; }

footer {
  min-height: 500px;
  width: 100%;
  background: linear-gradient(180deg, transparent 0%, rgba(5, 5, 5, 0.6)), url("../img/footer.jpg");
  background-size: cover;
  background-position: 50% 0%;
  overflow: hidden;
  padding: 50px;
  padding-bottom: 30px;
  position: relative; }
  footer div.upper {
    margin-top: 150px;
    overflow: hidden; }
    @media screen and (max-width: 1000px) {
      footer div.upper {
        margin-top: 40px; } }
    footer div.upper ul {
      list-style: none;
      margin: 0;
      padding: 0;
      float: left;
      padding: 40px; }
      @media screen and (max-width: 1000px) {
        footer div.upper ul {
          width: 50%; } }
      @media screen and (min-width: 1000px) {
        footer div.upper ul:first-of-type {
          padding-left: 0; } }
      footer div.upper ul h4 {
        text-transform: uppercase;
        margin: 0;
        color: #f7edab;
        margin-bottom: 5px; }
      footer div.upper ul li {
        margin: 0;
        padding: 2px 0px; }
        footer div.upper ul li a {
          text-decoration: none;
          color: white;
          font-weight: 500;
          transition: .3s ease;
          position: relative;
          left: 0; }
          footer div.upper ul li a:hover {
            left: 5px; }
  footer div.lower {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    margin-top: 40px;
    padding-top: 30px;
    overflow: hidden; }
    footer div.lower a.logo {
      width: 100px;
      float: left; }
      footer div.lower a.logo img {
        width: 100px; }
  footer p.legal {
    color: white;
    opacity: .8;
    float: left;
    margin-left: 10px;
    font-size: .8em; }
  footer ul.mini-nav {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    float: right;
    position: relative;
    top: 6px;
    opacity: .8; }
    @media screen and (max-width: 1000px) {
      footer ul.mini-nav {
        width: 50%; } }
    footer ul.mini-nav li {
      display: inline-block;
      padding-left: 10px; }
      footer ul.mini-nav li a {
        color: white;
        text-decoration: none;
        padding: 10px 19px;
        border: 1px solid white;
        border-radius: 50px;
        position: relative;
        width: 10px;
        height: 10px;
        font-weight: 500; }
        footer ul.mini-nav li a.inspiration {
          text-transform: uppercase;
          font-size: .9em; }
          footer ul.mini-nav li a.inspiration i {
            position: initial;
            top: initial;
            transform: none;
            margin-right: 5px; }
        footer ul.mini-nav li a i {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;
          margin: 0 auto;
          text-align: center; }

div.content-inner-header {
  width: 100%;
  height: 450px;
  background-color: tomato;
  background-position: 50% 50% !important;
  background-size: cover !important; }

div.no-hashtag h2 {
  margin-top: 30px; }

div.no-hashtag p {
  margin-bottom: 30px; }

table.table-product {
  width: 100%; }
  table.table-product tbody td {
    border-top: 1px solid #dddddd; }

section.content-inner-page {
  width: 100%;
  background: white;
  overflow: hidden;
  margin-bottom: 40px; }
  section.content-inner-page.fromages div.container div.container-block {
    width: calc(25% - 20px); }
    @media screen and (max-width: 1000px) {
      section.content-inner-page.fromages div.container div.container-block {
        width: calc(50% - 20px); } }
    section.content-inner-page.fromages div.container div.container-block div.container-block-background {
      height: 250px !important; }
      section.content-inner-page.fromages div.container div.container-block div.container-block-background img {
        width: 100%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        padding: 20px; }
  section.content-inner-page div.container {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px; }
    section.content-inner-page div.container p.breadcrumb {
      color: rgba(8, 8, 8, 0.8);
      font-size: .9em;
      opacity: .9;
      margin-left: 10px; }
      section.content-inner-page div.container p.breadcrumb a {
        color: inherit; }
        section.content-inner-page div.container p.breadcrumb a.active {
          color: #e20315; }
    section.content-inner-page div.container div.container-block {
      overflow: hidden;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
      margin-bottom: 20px;
      transition: 0.3s ease;
      width: calc(33.33333% - 20px);
      float: left;
      margin: 10px; }
      section.content-inner-page div.container div.container-block:hover {
        transform: scale(0.98); }
      section.content-inner-page div.container div.container-block div.container-block-background {
        width: 100%;
        height: 300px !important;
        background-position: 50% 50% !important;
        background-size: cover !important; }
      section.content-inner-page div.container div.container-block div.container-block-txt {
        height: auto;
        width: 100%;
        padding: 30px;
        color: rgba(8, 8, 8, 0.9);
        text-align: center;
        position: relative; }
        section.content-inner-page div.container div.container-block div.container-block-txt h2 {
          margin-top: 0;
          font-size: 1.5em;
          font-weight: 500; }
        section.content-inner-page div.container div.container-block div.container-block-txt p {
          line-height: 20px; }
          section.content-inner-page div.container div.container-block div.container-block-txt p.discover {
            margin: 0;
            opacity: .8; }
        section.content-inner-page div.container div.container-block div.container-block-txt button {
          border: 2px solid #080808;
          border-radius: 50px;
          width: 65px;
          margin: 0 auto;
          padding: 5px 0px;
          background: transparent;
          opacity: .9; }
          section.content-inner-page div.container div.container-block div.container-block-txt button img {
            width: 13px;
            position: relative;
            top: 1px;
            transition: 0.3s ease; }
      section.content-inner-page div.container div.container-block.products {
        width: calc(33.33333% - 20px);
        box-shadow: none; }
        section.content-inner-page div.container div.container-block.products .container-block-background {
          background-size: contain !important;
          background-repeat: no-repeat !important; }
        section.content-inner-page div.container div.container-block.products .container-block-txt h2 {
          font-size: 1em; }
      section.content-inner-page div.container div.container-block div.packaging {
        position: absolute;
        background: white;
        height: 120px;
        width: 120px;
        border-radius: 120px;
        top: 160px;
        left: 0;
        right: 0;
        margin: 0 auto;
        opacity: 0;
        transform: scale(0.5);
        transition: .2s ease;
        overflow: hidden; }
        section.content-inner-page div.container div.container-block div.packaging img {
          max-width: 100%;
          position: relative;
          top: 50%;
          transform: translateY(-50%); }
      section.content-inner-page div.container div.container-block:hover div.packaging {
        opacity: 1;
        transform: scale(1); }

section.content-inner-page.fondus div.container a div.container-block, section.content-inner-page.bloc div.container a div.container-block, section.content-inner-page.tranches div.container a div.container-block, section.content-inner-page.fagotin div.container a div.container-block {
  box-shadow: 0 0 20px 0 transparent; }
  section.content-inner-page.fondus div.container a div.container-block div.container-block-background, section.content-inner-page.bloc div.container a div.container-block div.container-block-background, section.content-inner-page.tranches div.container a div.container-block div.container-block-background, section.content-inner-page.fagotin div.container a div.container-block div.container-block-background {
    background-size: auto 100% !important;
    background-repeat: no-repeat !important;
    height: 200px !important; }
  section.content-inner-page.fondus div.container a div.container-block div.container-block-txt, section.content-inner-page.bloc div.container a div.container-block div.container-block-txt, section.content-inner-page.tranches div.container a div.container-block div.container-block-txt, section.content-inner-page.fagotin div.container a div.container-block div.container-block-txt {
    padding-top: 10px; }
    section.content-inner-page.fondus div.container a div.container-block div.container-block-txt h2, section.content-inner-page.bloc div.container a div.container-block div.container-block-txt h2, section.content-inner-page.tranches div.container a div.container-block div.container-block-txt h2, section.content-inner-page.fagotin div.container a div.container-block div.container-block-txt h2 {
      height: 70px;
      font-family: 'Niconne', cursive;
      font-size: 1.8em;
      line-height: 1em;
      color: rgba(8, 8, 8, 0.8); }

section.content-inner-page.fagotin div.container a div.container-block div.container-block-background {
  background-size: 100% !important; }

.product-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(23, 23, 23, 0.75);
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  z-index: 15; }
  .product-modal-container .breadcrumb,
  .product-modal-container .breadcrump {
    display: none; }
  .product-modal-container .content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 3em;
    background: white;
    padding: 2em;
    max-width: 960px;
    width: 100%;
    margin-bottom: 50px; }
    .product-modal-container .content h1 {
      color: #e20315; }
    .product-modal-container .content .close {
      position: absolute;
      right: 0.5em;
      top: 0;
      font-size: 4em;
      cursor: pointer; }

.load-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #fdba2c;
  z-index: 500; }

.bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center; }
  .bar:nth-child(1) {
    background-color: #da4733;
    animation: loading 2s linear infinite; }
  .bar:nth-child(2) {
    background-color: #fdba2c;
    animation: loading 2s linear 1s infinite; }

@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100; }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10; }
  to {
    left: 0;
    width: 100%; } }

section.mosaic_recettes {
  background: none !important; }
  section.mosaic_recettes div.linear-back {
    position: fixed;
    width: 100%;
    height: 100%;
    content: '';
    background-size: 100% auto !important;
    top: 0;
    left: 0; }
  section.mosaic_recettes div.container div.selector {
    background: white;
    padding: 15px;
    padding-top: 20px;
    padding-left: 20px;
    width: calc(100% - 20px);
    margin: 0 auto;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden; }
    section.mosaic_recettes div.container div.selector.search {
      padding-top: 15px; }
      section.mosaic_recettes div.container div.selector.search input {
        clear: both;
        display: block;
        width: 100%;
        padding: 0.3em 0.6em;
        font-size: 1em;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #999;
        margin: 0; }
        section.mosaic_recettes div.container div.selector.search input::-webkit-input-placeholder {
          color: #171717 !important; }
        section.mosaic_recettes div.container div.selector.search input::-moz-placeholder {
          color: #171717 !important; }
        section.mosaic_recettes div.container div.selector.search input::-ms-input-placeholder {
          color: #171717 !important; }
        section.mosaic_recettes div.container div.selector.search input::placeholder {
          color: #171717 !important; }
        section.mosaic_recettes div.container div.selector.search input:focus {
          border-color: #171717;
          box-shadow: none; }
    section.mosaic_recettes div.container div.selector form p {
      margin: 0;
      float: left;
      margin-right: 18px; }
      section.mosaic_recettes div.container div.selector form p label {
        line-height: 22px;
        color: #919191;
        font-size: .9em;
        padding-left: 24px; }
      section.mosaic_recettes div.container div.selector form p [type=checkbox].filled-in:not(:checked) + label:after {
        background-color: rgba(90, 90, 90, 0.12);
        border: 2px solid rgba(90, 90, 90, 0); }
      section.mosaic_recettes div.container div.selector form p [type=checkbox].filled-in:checked + label:after {
        border: 2px solid #e20315;
        background-color: #e20315; }
      section.mosaic_recettes div.container div.selector form p img {
        width: 17px;
        position: relative;
        top: 2px;
        margin-right: 4px;
        opacity: .6; }
  section.mosaic_recettes div.container a.filtered, section.mosaic_recettes div.container a.hide {
    display: none; }
  section.mosaic_recettes div.container div.mosaic_block {
    height: auto !important;
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15); }
    section.mosaic_recettes div.container div.mosaic_block:hover {
      transform: scale(0.99); }
    section.mosaic_recettes div.container div.mosaic_block a.goto {
      position: absolute;
      right: 18px;
      bottom: 10px;
      width: 20px;
      opacity: .5;
      transition: .3s ease; }
    section.mosaic_recettes div.container div.mosaic_block:hover .goto {
      right: 12px; }
    section.mosaic_recettes div.container div.mosaic_block div.mosaic_background  {
      width: 320px;
      float: left;
      position: relative;
      height: 210px;
      background-position: 80% 50% !important; }
    section.mosaic_recettes div.container div.mosaic_block div.mosaic_infos {
      position: relative;
      padding: 25px;
      float: left;
      height: 140px;
      width: 100%; }
      section.mosaic_recettes div.container div.mosaic_block div.mosaic_infos.videos p.timing:after {
        content: '';
        position: absolute;
        height: 18px;
        width: 18px;
        background: url("../img/youtube_picto.svg");
        right: -28px;
        top: 2px;
        opacity: .8; }
      section.mosaic_recettes div.container div.mosaic_block div.mosaic_infos h3 {
        margin: 0;
        color: #e40215;
        font-weight: 500;
        font-size: 1.1em;
        max-width: 450px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical; }
      section.mosaic_recettes div.container div.mosaic_block div.mosaic_infos p {
        margin: 8px 0;
        color: rgba(8, 8, 8, 0.7);
        font-size: .9em;
        position: absolute;
        bottom: 11px; }
      section.mosaic_recettes div.container div.mosaic_block div.mosaic_infos p.level {
        position: absolute;
        bottom: 20px;
        margin-bottom: 0;
        opacity: .8;
        font-size: .8em; }
      section.mosaic_recettes div.container div.mosaic_block div.mosaic_infos p.timing img {
        width: 15px;
        opacity: .7;
        position: relative;
        top: 3px; }
    section.mosaic_recettes div.container div.mosaic_block div.arrow {
      position: absolute;
      width: 50px;
      height: 50px;
      top: 48px;
      right: 100px;
      background: url("../img/next.svg") 50% 50%;
      background-size: cover;
      transition: .5s ease; }
    section.mosaic_recettes div.container div.mosaic_block:hover .goto {
      opacity: 1; }
    section.mosaic_recettes div.container div.mosaic_block div.type {
      position: absolute;
      right: 0;
      top: 0;
      height: 210px;
      width: 50px;
      background: #e40215;
      background: rgba(48, 48, 48, 0.65); }
      section.mosaic_recettes div.container div.mosaic_block div.type span {
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        height: 210px;
        width: 210px;
        text-align: center;
        font-size: 1em;
        position: absolute;
        margin-left: 15px; }
        section.mosaic_recettes div.container div.mosaic_block div.type span img {
          width: 30px;
          transform: rotate(90deg);
          margin-top: -6px; }
    section.mosaic_recettes div.container div.mosaic_block div.txt {
      float: none;
      clear: both;
      width: 100%;
      padding: 30px;
      background: #fafafa;
      max-height: 0px;
      padding: 0px;
      transition: .8s ease;
      overflow: hidden; }
      section.mosaic_recettes div.container div.mosaic_block div.txt div.holder {
        padding: 30px; }
        section.mosaic_recettes div.container div.mosaic_block div.txt div.holder h3 {
          color: #e40215;
          margin-top: 0; }
        section.mosaic_recettes div.container div.mosaic_block div.txt div.holder ul, section.mosaic_recettes div.container div.mosaic_block div.txt div.holder ol {
          font-size: .9em;
          opacity: .9; }
        section.mosaic_recettes div.container div.mosaic_block div.txt div.holder ul {
          list-style-type: none;
          padding-left: 0; }
          section.mosaic_recettes div.container div.mosaic_block div.txt div.holder ul li {
            padding-top: 5px; }
            section.mosaic_recettes div.container div.mosaic_block div.txt div.holder ul li:before {
              content: "-";
              margin-right: 10px; }
        section.mosaic_recettes div.container div.mosaic_block div.txt div.holder ol {
          padding-left: 16px; }
          section.mosaic_recettes div.container div.mosaic_block div.txt div.holder ol li {
            padding-top: 5px; }
    section.mosaic_recettes div.container div.mosaic_block.open div.txt {
      max-height: 1000px; }
    section.mosaic_recettes div.container div.mosaic_block.open div.arrow {
      transform: rotate(90deg); }

.materialize-red {
  background-color: #e51c23 !important; }

.materialize-red-text {
  color: #e51c23 !important; }

.materialize-red.lighten-5 {
  background-color: #fdeaeb !important; }

.materialize-red-text.text-lighten-5 {
  color: #fdeaeb !important; }

.materialize-red.lighten-4 {
  background-color: #f8c1c3 !important; }

.materialize-red-text.text-lighten-4 {
  color: #f8c1c3 !important; }

.materialize-red.lighten-3 {
  background-color: #f3989b !important; }

.materialize-red-text.text-lighten-3 {
  color: #f3989b !important; }

.materialize-red.lighten-2 {
  background-color: #ee6e73 !important; }

.materialize-red-text.text-lighten-2 {
  color: #ee6e73 !important; }

.materialize-red.lighten-1 {
  background-color: #ea454b !important; }

.materialize-red-text.text-lighten-1 {
  color: #ea454b !important; }

.materialize-red.darken-1 {
  background-color: #d0181e !important; }

.materialize-red-text.text-darken-1 {
  color: #d0181e !important; }

.materialize-red.darken-2 {
  background-color: #b9151b !important; }

.materialize-red-text.text-darken-2 {
  color: #b9151b !important; }

.materialize-red.darken-3 {
  background-color: #a21318 !important; }

.materialize-red-text.text-darken-3 {
  color: #a21318 !important; }

.materialize-red.darken-4 {
  background-color: #8b1014 !important; }

.materialize-red-text.text-darken-4 {
  color: #8b1014 !important; }

.red {
  background-color: #F44336 !important; }

.red-text {
  color: #F44336 !important; }

.red.lighten-5 {
  background-color: #FFEBEE !important; }

.red-text.text-lighten-5 {
  color: #FFEBEE !important; }

.red.lighten-4 {
  background-color: #FFCDD2 !important; }

.red-text.text-lighten-4 {
  color: #FFCDD2 !important; }

.red.lighten-3 {
  background-color: #EF9A9A !important; }

.red-text.text-lighten-3 {
  color: #EF9A9A !important; }

.red.lighten-2 {
  background-color: #E57373 !important; }

.red-text.text-lighten-2 {
  color: #E57373 !important; }

.red.lighten-1 {
  background-color: #EF5350 !important; }

.red-text.text-lighten-1 {
  color: #EF5350 !important; }

.red.darken-1 {
  background-color: #E53935 !important; }

.red-text.text-darken-1 {
  color: #E53935 !important; }

.red.darken-2 {
  background-color: #D32F2F !important; }

.red-text.text-darken-2 {
  color: #D32F2F !important; }

.red.darken-3 {
  background-color: #C62828 !important; }

.red-text.text-darken-3 {
  color: #C62828 !important; }

.red.darken-4 {
  background-color: #B71C1C !important; }

.red-text.text-darken-4 {
  color: #B71C1C !important; }

.red.accent-1 {
  background-color: #FF8A80 !important; }

.red-text.text-accent-1 {
  color: #FF8A80 !important; }

.red.accent-2 {
  background-color: #FF5252 !important; }

.red-text.text-accent-2 {
  color: #FF5252 !important; }

.red.accent-3 {
  background-color: #FF1744 !important; }

.red-text.text-accent-3 {
  color: #FF1744 !important; }

.red.accent-4 {
  background-color: #D50000 !important; }

.red-text.text-accent-4 {
  color: #D50000 !important; }

.pink {
  background-color: #e91e63 !important; }

.pink-text {
  color: #e91e63 !important; }

.pink.lighten-5 {
  background-color: #fce4ec !important; }

.pink-text.text-lighten-5 {
  color: #fce4ec !important; }

.pink.lighten-4 {
  background-color: #f8bbd0 !important; }

.pink-text.text-lighten-4 {
  color: #f8bbd0 !important; }

.pink.lighten-3 {
  background-color: #f48fb1 !important; }

.pink-text.text-lighten-3 {
  color: #f48fb1 !important; }

.pink.lighten-2 {
  background-color: #f06292 !important; }

.pink-text.text-lighten-2 {
  color: #f06292 !important; }

.pink.lighten-1 {
  background-color: #ec407a !important; }

.pink-text.text-lighten-1 {
  color: #ec407a !important; }

.pink.darken-1 {
  background-color: #d81b60 !important; }

.pink-text.text-darken-1 {
  color: #d81b60 !important; }

.pink.darken-2 {
  background-color: #c2185b !important; }

.pink-text.text-darken-2 {
  color: #c2185b !important; }

.pink.darken-3 {
  background-color: #ad1457 !important; }

.pink-text.text-darken-3 {
  color: #ad1457 !important; }

.pink.darken-4 {
  background-color: #880e4f !important; }

.pink-text.text-darken-4 {
  color: #880e4f !important; }

.pink.accent-1 {
  background-color: #ff80ab !important; }

.pink-text.text-accent-1 {
  color: #ff80ab !important; }

.pink.accent-2 {
  background-color: #ff4081 !important; }

.pink-text.text-accent-2 {
  color: #ff4081 !important; }

.pink.accent-3 {
  background-color: #f50057 !important; }

.pink-text.text-accent-3 {
  color: #f50057 !important; }

.pink.accent-4 {
  background-color: #c51162 !important; }

.pink-text.text-accent-4 {
  color: #c51162 !important; }

.purple {
  background-color: #9c27b0 !important; }

.purple-text {
  color: #9c27b0 !important; }

.purple.lighten-5 {
  background-color: #f3e5f5 !important; }

.purple-text.text-lighten-5 {
  color: #f3e5f5 !important; }

.purple.lighten-4 {
  background-color: #e1bee7 !important; }

.purple-text.text-lighten-4 {
  color: #e1bee7 !important; }

.purple.lighten-3 {
  background-color: #ce93d8 !important; }

.purple-text.text-lighten-3 {
  color: #ce93d8 !important; }

.purple.lighten-2 {
  background-color: #ba68c8 !important; }

.purple-text.text-lighten-2 {
  color: #ba68c8 !important; }

.purple.lighten-1 {
  background-color: #ab47bc !important; }

.purple-text.text-lighten-1 {
  color: #ab47bc !important; }

.purple.darken-1 {
  background-color: #8e24aa !important; }

.purple-text.text-darken-1 {
  color: #8e24aa !important; }

.purple.darken-2 {
  background-color: #7b1fa2 !important; }

.purple-text.text-darken-2 {
  color: #7b1fa2 !important; }

.purple.darken-3 {
  background-color: #6a1b9a !important; }

.purple-text.text-darken-3 {
  color: #6a1b9a !important; }

.purple.darken-4 {
  background-color: #4a148c !important; }

.purple-text.text-darken-4 {
  color: #4a148c !important; }

.purple.accent-1 {
  background-color: #ea80fc !important; }

.purple-text.text-accent-1 {
  color: #ea80fc !important; }

.purple.accent-2 {
  background-color: #e040fb !important; }

.purple-text.text-accent-2 {
  color: #e040fb !important; }

.purple.accent-3 {
  background-color: #d500f9 !important; }

.purple-text.text-accent-3 {
  color: #d500f9 !important; }

.purple.accent-4 {
  background-color: #aa00ff !important; }

.purple-text.text-accent-4 {
  color: #aa00ff !important; }

.deep-purple {
  background-color: #673ab7 !important; }

.deep-purple-text {
  color: #673ab7 !important; }

.deep-purple.lighten-5 {
  background-color: #ede7f6 !important; }

.deep-purple-text.text-lighten-5 {
  color: #ede7f6 !important; }

.deep-purple.lighten-4 {
  background-color: #d1c4e9 !important; }

.deep-purple-text.text-lighten-4 {
  color: #d1c4e9 !important; }

.deep-purple.lighten-3 {
  background-color: #b39ddb !important; }

.deep-purple-text.text-lighten-3 {
  color: #b39ddb !important; }

.deep-purple.lighten-2 {
  background-color: #9575cd !important; }

.deep-purple-text.text-lighten-2 {
  color: #9575cd !important; }

.deep-purple.lighten-1 {
  background-color: #7e57c2 !important; }

.deep-purple-text.text-lighten-1 {
  color: #7e57c2 !important; }

.deep-purple.darken-1 {
  background-color: #5e35b1 !important; }

.deep-purple-text.text-darken-1 {
  color: #5e35b1 !important; }

.deep-purple.darken-2 {
  background-color: #512da8 !important; }

.deep-purple-text.text-darken-2 {
  color: #512da8 !important; }

.deep-purple.darken-3 {
  background-color: #4527a0 !important; }

.deep-purple-text.text-darken-3 {
  color: #4527a0 !important; }

.deep-purple.darken-4 {
  background-color: #311b92 !important; }

.deep-purple-text.text-darken-4 {
  color: #311b92 !important; }

.deep-purple.accent-1 {
  background-color: #b388ff !important; }

.deep-purple-text.text-accent-1 {
  color: #b388ff !important; }

.deep-purple.accent-2 {
  background-color: #7c4dff !important; }

.deep-purple-text.text-accent-2 {
  color: #7c4dff !important; }

.deep-purple.accent-3 {
  background-color: #651fff !important; }

.deep-purple-text.text-accent-3 {
  color: #651fff !important; }

.deep-purple.accent-4 {
  background-color: #6200ea !important; }

.deep-purple-text.text-accent-4 {
  color: #6200ea !important; }

.indigo {
  background-color: #3f51b5 !important; }

.indigo-text {
  color: #3f51b5 !important; }

.indigo.lighten-5 {
  background-color: #e8eaf6 !important; }

.indigo-text.text-lighten-5 {
  color: #e8eaf6 !important; }

.indigo.lighten-4 {
  background-color: #c5cae9 !important; }

.indigo-text.text-lighten-4 {
  color: #c5cae9 !important; }

.indigo.lighten-3 {
  background-color: #9fa8da !important; }

.indigo-text.text-lighten-3 {
  color: #9fa8da !important; }

.indigo.lighten-2 {
  background-color: #7986cb !important; }

.indigo-text.text-lighten-2 {
  color: #7986cb !important; }

.indigo.lighten-1 {
  background-color: #5c6bc0 !important; }

.indigo-text.text-lighten-1 {
  color: #5c6bc0 !important; }

.indigo.darken-1 {
  background-color: #3949ab !important; }

.indigo-text.text-darken-1 {
  color: #3949ab !important; }

.indigo.darken-2 {
  background-color: #303f9f !important; }

.indigo-text.text-darken-2 {
  color: #303f9f !important; }

.indigo.darken-3 {
  background-color: #283593 !important; }

.indigo-text.text-darken-3 {
  color: #283593 !important; }

.indigo.darken-4 {
  background-color: #1a237e !important; }

.indigo-text.text-darken-4 {
  color: #1a237e !important; }

.indigo.accent-1 {
  background-color: #8c9eff !important; }

.indigo-text.text-accent-1 {
  color: #8c9eff !important; }

.indigo.accent-2 {
  background-color: #536dfe !important; }

.indigo-text.text-accent-2 {
  color: #536dfe !important; }

.indigo.accent-3 {
  background-color: #3d5afe !important; }

.indigo-text.text-accent-3 {
  color: #3d5afe !important; }

.indigo.accent-4 {
  background-color: #304ffe !important; }

.indigo-text.text-accent-4 {
  color: #304ffe !important; }

.blue {
  background-color: #2196F3 !important; }

.blue-text {
  color: #2196F3 !important; }

.blue.lighten-5 {
  background-color: #E3F2FD !important; }

.blue-text.text-lighten-5 {
  color: #E3F2FD !important; }

.blue.lighten-4 {
  background-color: #BBDEFB !important; }

.blue-text.text-lighten-4 {
  color: #BBDEFB !important; }

.blue.lighten-3 {
  background-color: #90CAF9 !important; }

.blue-text.text-lighten-3 {
  color: #90CAF9 !important; }

.blue.lighten-2 {
  background-color: #64B5F6 !important; }

.blue-text.text-lighten-2 {
  color: #64B5F6 !important; }

.blue.lighten-1 {
  background-color: #42A5F5 !important; }

.blue-text.text-lighten-1 {
  color: #42A5F5 !important; }

.blue.darken-1 {
  background-color: #1E88E5 !important; }

.blue-text.text-darken-1 {
  color: #1E88E5 !important; }

.blue.darken-2 {
  background-color: #1976D2 !important; }

.blue-text.text-darken-2 {
  color: #1976D2 !important; }

.blue.darken-3 {
  background-color: #1565C0 !important; }

.blue-text.text-darken-3 {
  color: #1565C0 !important; }

.blue.darken-4 {
  background-color: #0D47A1 !important; }

.blue-text.text-darken-4 {
  color: #0D47A1 !important; }

.blue.accent-1 {
  background-color: #82B1FF !important; }

.blue-text.text-accent-1 {
  color: #82B1FF !important; }

.blue.accent-2 {
  background-color: #448AFF !important; }

.blue-text.text-accent-2 {
  color: #448AFF !important; }

.blue.accent-3 {
  background-color: #2979FF !important; }

.blue-text.text-accent-3 {
  color: #2979FF !important; }

.blue.accent-4 {
  background-color: #2962FF !important; }

.blue-text.text-accent-4 {
  color: #2962FF !important; }

.light-blue {
  background-color: #03a9f4 !important; }

.light-blue-text {
  color: #03a9f4 !important; }

.light-blue.lighten-5 {
  background-color: #e1f5fe !important; }

.light-blue-text.text-lighten-5 {
  color: #e1f5fe !important; }

.light-blue.lighten-4 {
  background-color: #b3e5fc !important; }

.light-blue-text.text-lighten-4 {
  color: #b3e5fc !important; }

.light-blue.lighten-3 {
  background-color: #81d4fa !important; }

.light-blue-text.text-lighten-3 {
  color: #81d4fa !important; }

.light-blue.lighten-2 {
  background-color: #4fc3f7 !important; }

.light-blue-text.text-lighten-2 {
  color: #4fc3f7 !important; }

.light-blue.lighten-1 {
  background-color: #29b6f6 !important; }

.light-blue-text.text-lighten-1 {
  color: #29b6f6 !important; }

.light-blue.darken-1 {
  background-color: #039be5 !important; }

.light-blue-text.text-darken-1 {
  color: #039be5 !important; }

.light-blue.darken-2 {
  background-color: #0288d1 !important; }

.light-blue-text.text-darken-2 {
  color: #0288d1 !important; }

.light-blue.darken-3 {
  background-color: #0277bd !important; }

.light-blue-text.text-darken-3 {
  color: #0277bd !important; }

.light-blue.darken-4 {
  background-color: #01579b !important; }

.light-blue-text.text-darken-4 {
  color: #01579b !important; }

.light-blue.accent-1 {
  background-color: #80d8ff !important; }

.light-blue-text.text-accent-1 {
  color: #80d8ff !important; }

.light-blue.accent-2 {
  background-color: #40c4ff !important; }

.light-blue-text.text-accent-2 {
  color: #40c4ff !important; }

.light-blue.accent-3 {
  background-color: #00b0ff !important; }

.light-blue-text.text-accent-3 {
  color: #00b0ff !important; }

.light-blue.accent-4 {
  background-color: #0091ea !important; }

.light-blue-text.text-accent-4 {
  color: #0091ea !important; }

.cyan {
  background-color: #00bcd4 !important; }

.cyan-text {
  color: #00bcd4 !important; }

.cyan.lighten-5 {
  background-color: #e0f7fa !important; }

.cyan-text.text-lighten-5 {
  color: #e0f7fa !important; }

.cyan.lighten-4 {
  background-color: #b2ebf2 !important; }

.cyan-text.text-lighten-4 {
  color: #b2ebf2 !important; }

.cyan.lighten-3 {
  background-color: #80deea !important; }

.cyan-text.text-lighten-3 {
  color: #80deea !important; }

.cyan.lighten-2 {
  background-color: #4dd0e1 !important; }

.cyan-text.text-lighten-2 {
  color: #4dd0e1 !important; }

.cyan.lighten-1 {
  background-color: #26c6da !important; }

.cyan-text.text-lighten-1 {
  color: #26c6da !important; }

.cyan.darken-1 {
  background-color: #00acc1 !important; }

.cyan-text.text-darken-1 {
  color: #00acc1 !important; }

.cyan.darken-2 {
  background-color: #0097a7 !important; }

.cyan-text.text-darken-2 {
  color: #0097a7 !important; }

.cyan.darken-3 {
  background-color: #00838f !important; }

.cyan-text.text-darken-3 {
  color: #00838f !important; }

.cyan.darken-4 {
  background-color: #006064 !important; }

.cyan-text.text-darken-4 {
  color: #006064 !important; }

.cyan.accent-1 {
  background-color: #84ffff !important; }

.cyan-text.text-accent-1 {
  color: #84ffff !important; }

.cyan.accent-2 {
  background-color: #18ffff !important; }

.cyan-text.text-accent-2 {
  color: #18ffff !important; }

.cyan.accent-3 {
  background-color: #00e5ff !important; }

.cyan-text.text-accent-3 {
  color: #00e5ff !important; }

.cyan.accent-4 {
  background-color: #00b8d4 !important; }

.cyan-text.text-accent-4 {
  color: #00b8d4 !important; }

.teal {
  background-color: #009688 !important; }

.teal-text {
  color: #009688 !important; }

.teal.lighten-5 {
  background-color: #e0f2f1 !important; }

.teal-text.text-lighten-5 {
  color: #e0f2f1 !important; }

.teal.lighten-4 {
  background-color: #b2dfdb !important; }

.teal-text.text-lighten-4 {
  color: #b2dfdb !important; }

.teal.lighten-3 {
  background-color: #80cbc4 !important; }

.teal-text.text-lighten-3 {
  color: #80cbc4 !important; }

.teal.lighten-2 {
  background-color: #4db6ac !important; }

.teal-text.text-lighten-2 {
  color: #4db6ac !important; }

.teal.lighten-1 {
  background-color: #26a69a !important; }

.teal-text.text-lighten-1 {
  color: #26a69a !important; }

.teal.darken-1 {
  background-color: #00897b !important; }

.teal-text.text-darken-1 {
  color: #00897b !important; }

.teal.darken-2 {
  background-color: #00796b !important; }

.teal-text.text-darken-2 {
  color: #00796b !important; }

.teal.darken-3 {
  background-color: #00695c !important; }

.teal-text.text-darken-3 {
  color: #00695c !important; }

.teal.darken-4 {
  background-color: #004d40 !important; }

.teal-text.text-darken-4 {
  color: #004d40 !important; }

.teal.accent-1 {
  background-color: #a7ffeb !important; }

.teal-text.text-accent-1 {
  color: #a7ffeb !important; }

.teal.accent-2 {
  background-color: #64ffda !important; }

.teal-text.text-accent-2 {
  color: #64ffda !important; }

.teal.accent-3 {
  background-color: #1de9b6 !important; }

.teal-text.text-accent-3 {
  color: #1de9b6 !important; }

.teal.accent-4 {
  background-color: #00bfa5 !important; }

.teal-text.text-accent-4 {
  color: #00bfa5 !important; }

.green {
  background-color: #4CAF50 !important; }

.green-text {
  color: #4CAF50 !important; }

.green.lighten-5 {
  background-color: #E8F5E9 !important; }

.green-text.text-lighten-5 {
  color: #E8F5E9 !important; }

.green.lighten-4 {
  background-color: #C8E6C9 !important; }

.green-text.text-lighten-4 {
  color: #C8E6C9 !important; }

.green.lighten-3 {
  background-color: #A5D6A7 !important; }

.green-text.text-lighten-3 {
  color: #A5D6A7 !important; }

.green.lighten-2 {
  background-color: #81C784 !important; }

.green-text.text-lighten-2 {
  color: #81C784 !important; }

.green.lighten-1 {
  background-color: #66BB6A !important; }

.green-text.text-lighten-1 {
  color: #66BB6A !important; }

.green.darken-1 {
  background-color: #43A047 !important; }

.green-text.text-darken-1 {
  color: #43A047 !important; }

.green.darken-2 {
  background-color: #388E3C !important; }

.green-text.text-darken-2 {
  color: #388E3C !important; }

.green.darken-3 {
  background-color: #2E7D32 !important; }

.green-text.text-darken-3 {
  color: #2E7D32 !important; }

.green.darken-4 {
  background-color: #1B5E20 !important; }

.green-text.text-darken-4 {
  color: #1B5E20 !important; }

.green.accent-1 {
  background-color: #B9F6CA !important; }

.green-text.text-accent-1 {
  color: #B9F6CA !important; }

.green.accent-2 {
  background-color: #69F0AE !important; }

.green-text.text-accent-2 {
  color: #69F0AE !important; }

.green.accent-3 {
  background-color: #00E676 !important; }

.green-text.text-accent-3 {
  color: #00E676 !important; }

.green.accent-4 {
  background-color: #00C853 !important; }

.green-text.text-accent-4 {
  color: #00C853 !important; }

.light-green {
  background-color: #8bc34a !important; }

.light-green-text {
  color: #8bc34a !important; }

.light-green.lighten-5 {
  background-color: #f1f8e9 !important; }

.light-green-text.text-lighten-5 {
  color: #f1f8e9 !important; }

.light-green.lighten-4 {
  background-color: #dcedc8 !important; }

.light-green-text.text-lighten-4 {
  color: #dcedc8 !important; }

.light-green.lighten-3 {
  background-color: #c5e1a5 !important; }

.light-green-text.text-lighten-3 {
  color: #c5e1a5 !important; }

.light-green.lighten-2 {
  background-color: #aed581 !important; }

.light-green-text.text-lighten-2 {
  color: #aed581 !important; }

.light-green.lighten-1 {
  background-color: #9ccc65 !important; }

.light-green-text.text-lighten-1 {
  color: #9ccc65 !important; }

.light-green.darken-1 {
  background-color: #7cb342 !important; }

.light-green-text.text-darken-1 {
  color: #7cb342 !important; }

.light-green.darken-2 {
  background-color: #689f38 !important; }

.light-green-text.text-darken-2 {
  color: #689f38 !important; }

.light-green.darken-3 {
  background-color: #558b2f !important; }

.light-green-text.text-darken-3 {
  color: #558b2f !important; }

.light-green.darken-4 {
  background-color: #33691e !important; }

.light-green-text.text-darken-4 {
  color: #33691e !important; }

.light-green.accent-1 {
  background-color: #ccff90 !important; }

.light-green-text.text-accent-1 {
  color: #ccff90 !important; }

.light-green.accent-2 {
  background-color: #b2ff59 !important; }

.light-green-text.text-accent-2 {
  color: #b2ff59 !important; }

.light-green.accent-3 {
  background-color: #76ff03 !important; }

.light-green-text.text-accent-3 {
  color: #76ff03 !important; }

.light-green.accent-4 {
  background-color: #64dd17 !important; }

.light-green-text.text-accent-4 {
  color: #64dd17 !important; }

.lime {
  background-color: #cddc39 !important; }

.lime-text {
  color: #cddc39 !important; }

.lime.lighten-5 {
  background-color: #f9fbe7 !important; }

.lime-text.text-lighten-5 {
  color: #f9fbe7 !important; }

.lime.lighten-4 {
  background-color: #f0f4c3 !important; }

.lime-text.text-lighten-4 {
  color: #f0f4c3 !important; }

.lime.lighten-3 {
  background-color: #e6ee9c !important; }

.lime-text.text-lighten-3 {
  color: #e6ee9c !important; }

.lime.lighten-2 {
  background-color: #dce775 !important; }

.lime-text.text-lighten-2 {
  color: #dce775 !important; }

.lime.lighten-1 {
  background-color: #d4e157 !important; }

.lime-text.text-lighten-1 {
  color: #d4e157 !important; }

.lime.darken-1 {
  background-color: #c0ca33 !important; }

.lime-text.text-darken-1 {
  color: #c0ca33 !important; }

.lime.darken-2 {
  background-color: #afb42b !important; }

.lime-text.text-darken-2 {
  color: #afb42b !important; }

.lime.darken-3 {
  background-color: #9e9d24 !important; }

.lime-text.text-darken-3 {
  color: #9e9d24 !important; }

.lime.darken-4 {
  background-color: #827717 !important; }

.lime-text.text-darken-4 {
  color: #827717 !important; }

.lime.accent-1 {
  background-color: #f4ff81 !important; }

.lime-text.text-accent-1 {
  color: #f4ff81 !important; }

.lime.accent-2 {
  background-color: #eeff41 !important; }

.lime-text.text-accent-2 {
  color: #eeff41 !important; }

.lime.accent-3 {
  background-color: #c6ff00 !important; }

.lime-text.text-accent-3 {
  color: #c6ff00 !important; }

.lime.accent-4 {
  background-color: #aeea00 !important; }

.lime-text.text-accent-4 {
  color: #aeea00 !important; }

.yellow {
  background-color: #ffeb3b !important; }

.yellow-text {
  color: #ffeb3b !important; }

.yellow.lighten-5 {
  background-color: #fffde7 !important; }

.yellow-text.text-lighten-5 {
  color: #fffde7 !important; }

.yellow.lighten-4 {
  background-color: #fff9c4 !important; }

.yellow-text.text-lighten-4 {
  color: #fff9c4 !important; }

.yellow.lighten-3 {
  background-color: #fff59d !important; }

.yellow-text.text-lighten-3 {
  color: #fff59d !important; }

.yellow.lighten-2 {
  background-color: #fff176 !important; }

.yellow-text.text-lighten-2 {
  color: #fff176 !important; }

.yellow.lighten-1 {
  background-color: #ffee58 !important; }

.yellow-text.text-lighten-1 {
  color: #ffee58 !important; }

.yellow.darken-1 {
  background-color: #fdd835 !important; }

.yellow-text.text-darken-1 {
  color: #fdd835 !important; }

.yellow.darken-2 {
  background-color: #fbc02d !important; }

.yellow-text.text-darken-2 {
  color: #fbc02d !important; }

.yellow.darken-3 {
  background-color: #f9a825 !important; }

.yellow-text.text-darken-3 {
  color: #f9a825 !important; }

.yellow.darken-4 {
  background-color: #f57f17 !important; }

.yellow-text.text-darken-4 {
  color: #f57f17 !important; }

.yellow.accent-1 {
  background-color: #ffff8d !important; }

.yellow-text.text-accent-1 {
  color: #ffff8d !important; }

.yellow.accent-2 {
  background-color: #ffff00 !important; }

.yellow-text.text-accent-2 {
  color: #ffff00 !important; }

.yellow.accent-3 {
  background-color: #ffea00 !important; }

.yellow-text.text-accent-3 {
  color: #ffea00 !important; }

.yellow.accent-4 {
  background-color: #ffd600 !important; }

.yellow-text.text-accent-4 {
  color: #ffd600 !important; }

.amber {
  background-color: #ffc107 !important; }

.amber-text {
  color: #ffc107 !important; }

.amber.lighten-5 {
  background-color: #fff8e1 !important; }

.amber-text.text-lighten-5 {
  color: #fff8e1 !important; }

.amber.lighten-4 {
  background-color: #ffecb3 !important; }

.amber-text.text-lighten-4 {
  color: #ffecb3 !important; }

.amber.lighten-3 {
  background-color: #ffe082 !important; }

.amber-text.text-lighten-3 {
  color: #ffe082 !important; }

.amber.lighten-2 {
  background-color: #ffd54f !important; }

.amber-text.text-lighten-2 {
  color: #ffd54f !important; }

.amber.lighten-1 {
  background-color: #ffca28 !important; }

.amber-text.text-lighten-1 {
  color: #ffca28 !important; }

.amber.darken-1 {
  background-color: #ffb300 !important; }

.amber-text.text-darken-1 {
  color: #ffb300 !important; }

.amber.darken-2 {
  background-color: #ffa000 !important; }

.amber-text.text-darken-2 {
  color: #ffa000 !important; }

.amber.darken-3 {
  background-color: #ff8f00 !important; }

.amber-text.text-darken-3 {
  color: #ff8f00 !important; }

.amber.darken-4 {
  background-color: #ff6f00 !important; }

.amber-text.text-darken-4 {
  color: #ff6f00 !important; }

.amber.accent-1 {
  background-color: #ffe57f !important; }

.amber-text.text-accent-1 {
  color: #ffe57f !important; }

.amber.accent-2 {
  background-color: #ffd740 !important; }

.amber-text.text-accent-2 {
  color: #ffd740 !important; }

.amber.accent-3 {
  background-color: #ffc400 !important; }

.amber-text.text-accent-3 {
  color: #ffc400 !important; }

.amber.accent-4 {
  background-color: #ffab00 !important; }

.amber-text.text-accent-4 {
  color: #ffab00 !important; }

.orange {
  background-color: #ff9800 !important; }

.orange-text {
  color: #ff9800 !important; }

.orange.lighten-5 {
  background-color: #fff3e0 !important; }

.orange-text.text-lighten-5 {
  color: #fff3e0 !important; }

.orange.lighten-4 {
  background-color: #ffe0b2 !important; }

.orange-text.text-lighten-4 {
  color: #ffe0b2 !important; }

.orange.lighten-3 {
  background-color: #ffcc80 !important; }

.orange-text.text-lighten-3 {
  color: #ffcc80 !important; }

.orange.lighten-2 {
  background-color: #ffb74d !important; }

.orange-text.text-lighten-2 {
  color: #ffb74d !important; }

.orange.lighten-1 {
  background-color: #ffa726 !important; }

.orange-text.text-lighten-1 {
  color: #ffa726 !important; }

.orange.darken-1 {
  background-color: #fb8c00 !important; }

.orange-text.text-darken-1 {
  color: #fb8c00 !important; }

.orange.darken-2 {
  background-color: #f57c00 !important; }

.orange-text.text-darken-2 {
  color: #f57c00 !important; }

.orange.darken-3 {
  background-color: #ef6c00 !important; }

.orange-text.text-darken-3 {
  color: #ef6c00 !important; }

.orange.darken-4 {
  background-color: #e65100 !important; }

.orange-text.text-darken-4 {
  color: #e65100 !important; }

.orange.accent-1 {
  background-color: #ffd180 !important; }

.orange-text.text-accent-1 {
  color: #ffd180 !important; }

.orange.accent-2 {
  background-color: #ffab40 !important; }

.orange-text.text-accent-2 {
  color: #ffab40 !important; }

.orange.accent-3 {
  background-color: #ff9100 !important; }

.orange-text.text-accent-3 {
  color: #ff9100 !important; }

.orange.accent-4 {
  background-color: #ff6d00 !important; }

.orange-text.text-accent-4 {
  color: #ff6d00 !important; }

.deep-orange {
  background-color: #ff5722 !important; }

.deep-orange-text {
  color: #ff5722 !important; }

.deep-orange.lighten-5 {
  background-color: #fbe9e7 !important; }

.deep-orange-text.text-lighten-5 {
  color: #fbe9e7 !important; }

.deep-orange.lighten-4 {
  background-color: #ffccbc !important; }

.deep-orange-text.text-lighten-4 {
  color: #ffccbc !important; }

.deep-orange.lighten-3 {
  background-color: #ffab91 !important; }

.deep-orange-text.text-lighten-3 {
  color: #ffab91 !important; }

.deep-orange.lighten-2 {
  background-color: #ff8a65 !important; }

.deep-orange-text.text-lighten-2 {
  color: #ff8a65 !important; }

.deep-orange.lighten-1 {
  background-color: #ff7043 !important; }

.deep-orange-text.text-lighten-1 {
  color: #ff7043 !important; }

.deep-orange.darken-1 {
  background-color: #f4511e !important; }

.deep-orange-text.text-darken-1 {
  color: #f4511e !important; }

.deep-orange.darken-2 {
  background-color: #e64a19 !important; }

.deep-orange-text.text-darken-2 {
  color: #e64a19 !important; }

.deep-orange.darken-3 {
  background-color: #d84315 !important; }

.deep-orange-text.text-darken-3 {
  color: #d84315 !important; }

.deep-orange.darken-4 {
  background-color: #bf360c !important; }

.deep-orange-text.text-darken-4 {
  color: #bf360c !important; }

.deep-orange.accent-1 {
  background-color: #ff9e80 !important; }

.deep-orange-text.text-accent-1 {
  color: #ff9e80 !important; }

.deep-orange.accent-2 {
  background-color: #ff6e40 !important; }

.deep-orange-text.text-accent-2 {
  color: #ff6e40 !important; }

.deep-orange.accent-3 {
  background-color: #ff3d00 !important; }

.deep-orange-text.text-accent-3 {
  color: #ff3d00 !important; }

.deep-orange.accent-4 {
  background-color: #dd2c00 !important; }

.deep-orange-text.text-accent-4 {
  color: #dd2c00 !important; }

.brown {
  background-color: #795548 !important; }

.brown-text {
  color: #795548 !important; }

.brown.lighten-5 {
  background-color: #efebe9 !important; }

.brown-text.text-lighten-5 {
  color: #efebe9 !important; }

.brown.lighten-4 {
  background-color: #d7ccc8 !important; }

.brown-text.text-lighten-4 {
  color: #d7ccc8 !important; }

.brown.lighten-3 {
  background-color: #bcaaa4 !important; }

.brown-text.text-lighten-3 {
  color: #bcaaa4 !important; }

.brown.lighten-2 {
  background-color: #a1887f !important; }

.brown-text.text-lighten-2 {
  color: #a1887f !important; }

.brown.lighten-1 {
  background-color: #8d6e63 !important; }

.brown-text.text-lighten-1 {
  color: #8d6e63 !important; }

.brown.darken-1 {
  background-color: #6d4c41 !important; }

.brown-text.text-darken-1 {
  color: #6d4c41 !important; }

.brown.darken-2 {
  background-color: #5d4037 !important; }

.brown-text.text-darken-2 {
  color: #5d4037 !important; }

.brown.darken-3 {
  background-color: #4e342e !important; }

.brown-text.text-darken-3 {
  color: #4e342e !important; }

.brown.darken-4 {
  background-color: #3e2723 !important; }

.brown-text.text-darken-4 {
  color: #3e2723 !important; }

.blue-grey {
  background-color: #607d8b !important; }

.blue-grey-text {
  color: #607d8b !important; }

.blue-grey.lighten-5 {
  background-color: #eceff1 !important; }

.blue-grey-text.text-lighten-5 {
  color: #eceff1 !important; }

.blue-grey.lighten-4 {
  background-color: #cfd8dc !important; }

.blue-grey-text.text-lighten-4 {
  color: #cfd8dc !important; }

.blue-grey.lighten-3 {
  background-color: #b0bec5 !important; }

.blue-grey-text.text-lighten-3 {
  color: #b0bec5 !important; }

.blue-grey.lighten-2 {
  background-color: #90a4ae !important; }

.blue-grey-text.text-lighten-2 {
  color: #90a4ae !important; }

.blue-grey.lighten-1 {
  background-color: #78909c !important; }

.blue-grey-text.text-lighten-1 {
  color: #78909c !important; }

.blue-grey.darken-1 {
  background-color: #546e7a !important; }

.blue-grey-text.text-darken-1 {
  color: #546e7a !important; }

.blue-grey.darken-2 {
  background-color: #455a64 !important; }

.blue-grey-text.text-darken-2 {
  color: #455a64 !important; }

.blue-grey.darken-3 {
  background-color: #37474f !important; }

.blue-grey-text.text-darken-3 {
  color: #37474f !important; }

.blue-grey.darken-4 {
  background-color: #263238 !important; }

.blue-grey-text.text-darken-4 {
  color: #263238 !important; }

.grey {
  background-color: #9e9e9e !important; }

.grey-text {
  color: #9e9e9e !important; }

.grey.lighten-5 {
  background-color: #fafafa !important; }

.grey-text.text-lighten-5 {
  color: #fafafa !important; }

.grey.lighten-4 {
  background-color: #f5f5f5 !important; }

.grey-text.text-lighten-4 {
  color: #f5f5f5 !important; }

.grey.lighten-3 {
  background-color: #eeeeee !important; }

.grey-text.text-lighten-3 {
  color: #eeeeee !important; }

.grey.lighten-2 {
  background-color: #e0e0e0 !important; }

.grey-text.text-lighten-2 {
  color: #e0e0e0 !important; }

.grey.lighten-1 {
  background-color: #bdbdbd !important; }

.grey-text.text-lighten-1 {
  color: #bdbdbd !important; }

.grey.darken-1 {
  background-color: #757575 !important; }

.grey-text.text-darken-1 {
  color: #757575 !important; }

.grey.darken-2 {
  background-color: #616161 !important; }

.grey-text.text-darken-2 {
  color: #616161 !important; }

.grey.darken-3 {
  background-color: #424242 !important; }

.grey-text.text-darken-3 {
  color: #424242 !important; }

.grey.darken-4 {
  background-color: #212121 !important; }

.grey-text.text-darken-4 {
  color: #212121 !important; }

.black {
  background-color: #000000 !important; }

.black-text {
  color: #000000 !important; }

.white {
  background-color: #FFFFFF !important; }

.white-text {
  color: #FFFFFF !important; }

.transparent {
  background-color: transparent !important; }

.transparent-text {
  color: transparent !important; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

ul:not(.browser-default) {
  padding-left: 0;
  list-style-type: none; }
  ul:not(.browser-default) li {
    list-style-type: none; }

a {
  color: #039be5;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; }

.valign-wrapper {
  display: flex;
  align-items: center; }
  .valign-wrapper .valign {
    display: block; }

.clearfix {
  clear: both; }

.z-depth-0 {
  box-shadow: none !important; }

.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }

.z-depth-1-half {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2); }

.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3); }

.z-depth-3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3); }

.z-depth-4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3); }

.z-depth-5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3); }

.hoverable {
  transition: box-shadow .25s;
  box-shadow: 0; }

.hoverable:hover {
  transition: box-shadow .25s;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.divider {
  height: 1px;
  overflow: hidden;
  background-color: #e0e0e0; }

blockquote {
  margin: 20px 0;
  padding-left: 1.5rem;
  border-left: 5px solid #ee6e73; }

i {
  line-height: inherit; }
  i.left {
    float: left;
    margin-right: 15px; }
  i.right {
    float: right;
    margin-left: 15px; }
  i.tiny {
    font-size: 1rem; }
  i.small {
    font-size: 2rem; }
  i.medium {
    font-size: 4rem; }
  i.large {
    font-size: 6rem; }

img.responsive-img,
video.responsive-video {
  max-width: 100%;
  height: auto; }

.pagination li {
  display: inline-block;
  border-radius: 2px;
  text-align: center;
  vertical-align: top;
  height: 30px; }
  .pagination li a {
    color: #444;
    display: inline-block;
    font-size: 1.2rem;
    padding: 0 10px;
    line-height: 30px; }
  .pagination li.active a {
    color: #fff; }
  .pagination li.active {
    background-color: #ee6e73; }
  .pagination li.disabled a {
    cursor: default;
    color: #999; }
  .pagination li i {
    font-size: 2rem; }

.pagination li.pages ul li {
  display: inline-block;
  float: none; }

@media only screen and (max-width: 992px) {
  .pagination {
    width: 100%; }
    .pagination li.prev,
    .pagination li.next {
      width: 10%; }
    .pagination li.pages {
      width: 80%;
      overflow: hidden;
      white-space: nowrap; } }

.breadcrumb {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7); }
  .breadcrumb i,
  .breadcrumb [class^="mdi-"], .breadcrumb [class*="mdi-"],
  .breadcrumb i.material-icons {
    display: inline-block;
    float: left;
    font-size: 24px; }
  .breadcrumb:before {
    content: '\E5CC';
    color: rgba(255, 255, 255, 0.7);
    vertical-align: top;
    display: inline-block;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 25px;
    margin: 0 10px 0 8px;
    -webkit-font-smoothing: antialiased; }
  .breadcrumb:first-child:before {
    display: none; }
  .breadcrumb:last-child {
    color: #fff; }

.parallax-container {
  position: relative;
  overflow: hidden;
  height: 500px; }

.parallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; }
  .parallax img {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    transform: translateX(-50%); }

.pin-top, .pin-bottom {
  position: relative; }

.pinned {
  position: fixed !important; }

/*********************
  Transition Classes
**********************/
ul.staggered-list li {
  opacity: 0; }

.fade-in {
  opacity: 0;
  transform-origin: 0 50%; }

/*********************
  Media Query Classes
**********************/
@media only screen and (max-width: 600px) {
  .hide-on-small-only, .hide-on-small-and-down {
    display: none !important; } }

@media only screen and (max-width: 992px) {
  .hide-on-med-and-down {
    display: none !important; } }

@media only screen and (min-width: 601px) {
  .hide-on-med-and-up {
    display: none !important; } }

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .hide-on-med-only {
    display: none !important; } }

@media only screen and (min-width: 993px) {
  .hide-on-large-only {
    display: none !important; } }

@media only screen and (min-width: 993px) {
  .show-on-large {
    display: block !important; } }

@media only screen and (min-width: 600px) and (max-width: 992px) {
  .show-on-medium {
    display: block !important; } }

@media only screen and (max-width: 600px) {
  .show-on-small {
    display: block !important; } }

@media only screen and (min-width: 601px) {
  .show-on-medium-and-up {
    display: block !important; } }

@media only screen and (max-width: 992px) {
  .show-on-medium-and-down {
    display: block !important; } }

@media only screen and (max-width: 600px) {
  .center-on-small-only {
    text-align: center; } }

footer.page-footer {
  margin-top: 20px;
  padding-top: 20px;
  background-color: #ee6e73; }
  footer.page-footer .footer-copyright {
    overflow: hidden;
    height: 50px;
    line-height: 50px;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(51, 51, 51, 0.08); }

table, th, td {
  border: none; }

table {
  width: 100%;
  display: table; }
  table.bordered > thead > tr,
  table.bordered > tbody > tr {
    border-bottom: 1px solid #d0d0d0; }
  table.striped > tbody > tr:nth-child(odd) {
    background-color: #f2f2f2; }
  table.striped > tbody > tr > td {
    border-radius: 0; }
  table.highlight > tbody > tr {
    transition: background-color .25s ease; }
    table.highlight > tbody > tr:hover {
      background-color: #f2f2f2; }
  table.centered thead tr th, table.centered tbody tr td {
    text-align: center; }

thead {
  border-bottom: 1px solid #d0d0d0; }

td, th {
  padding: 15px 5px;
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  border-radius: 2px; }

@media only screen and (max-width: 992px) {
  table.responsive-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    position: relative;
    /* sort out borders */ }
    table.responsive-table td:empty:before {
      content: '\00a0'; }
    table.responsive-table th,
    table.responsive-table td {
      margin: 0;
      vertical-align: top; }
    table.responsive-table th {
      text-align: left; }
    table.responsive-table thead {
      display: block;
      float: left; }
      table.responsive-table thead tr {
        display: block;
        padding: 0 10px 0 0; }
        table.responsive-table thead tr th::before {
          content: "\00a0"; }
    table.responsive-table tbody {
      display: block;
      width: auto;
      position: relative;
      overflow-x: auto;
      white-space: nowrap; }
      table.responsive-table tbody tr {
        display: inline-block;
        vertical-align: top; }
    table.responsive-table th {
      display: block;
      text-align: right; }
    table.responsive-table td {
      display: block;
      min-height: 1.25em;
      text-align: left; }
    table.responsive-table tr {
      padding: 0 10px; }
    table.responsive-table thead {
      border: 0;
      border-right: 1px solid #d0d0d0; }
    table.responsive-table.bordered th {
      border-bottom: 0;
      border-left: 0; }
    table.responsive-table.bordered td {
      border-left: 0;
      border-right: 0;
      border-bottom: 0; }
    table.responsive-table.bordered tr {
      border: 0; }
    table.responsive-table.bordered tbody tr {
      border-right: 1px solid #d0d0d0; } }

.collection {
  margin: 0.5rem 0 1rem 0;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
  position: relative; }
  .collection .collection-item {
    background-color: #fff;
    line-height: 1.5rem;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid #e0e0e0; }
    .collection .collection-item.avatar {
      min-height: 84px;
      padding-left: 72px;
      position: relative; }
      .collection .collection-item.avatar .circle {
        position: absolute;
        width: 42px;
        height: 42px;
        overflow: hidden;
        left: 15px;
        display: inline-block;
        vertical-align: middle; }
      .collection .collection-item.avatar i.circle {
        font-size: 18px;
        line-height: 42px;
        color: #fff;
        background-color: #999;
        text-align: center; }
      .collection .collection-item.avatar .title {
        font-size: 16px; }
      .collection .collection-item.avatar p {
        margin: 0; }
      .collection .collection-item.avatar .secondary-content {
        position: absolute;
        top: 16px;
        right: 16px; }
    .collection .collection-item:last-child {
      border-bottom: none; }
    .collection .collection-item.active {
      background-color: #26a69a;
      color: #eafaf9; }
      .collection .collection-item.active .secondary-content {
        color: #fff; }
  .collection a.collection-item {
    display: block;
    transition: .25s;
    color: #26a69a; }
    .collection a.collection-item:not(.active):hover {
      background-color: #ddd; }
  .collection.with-header .collection-header {
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 20px; }
  .collection.with-header .collection-item {
    padding-left: 30px; }
  .collection.with-header .collection-item.avatar {
    padding-left: 72px; }

.secondary-content {
  float: right;
  color: #26a69a; }

.collapsible .collection {
  margin: 0;
  border: none; }

span.badge {
  min-width: 3rem;
  padding: 0 6px;
  margin-left: 14px;
  text-align: center;
  font-size: 1rem;
  line-height: inherit;
  color: #757575;
  float: right;
  box-sizing: border-box; }
  span.badge.new {
    font-weight: 300;
    font-size: 0.8rem;
    color: #fff;
    background-color: #26a69a;
    border-radius: 2px; }
  span.badge.new:after {
    content: " new"; }
  span.badge[data-badge-caption]::after {
    content: " " attr(data-badge-caption); }

nav ul a span.badge {
  display: inline-block;
  float: none;
  margin-left: 4px;
  line-height: 22px;
  height: 22px; }

.side-nav span.badge.new,
.collapsible span.badge.new {
  position: relative;
  background-color: transparent; }
  .side-nav span.badge.new::before,
  .collapsible span.badge.new::before {
    content: '';
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 10px;
    left: 0;
    background-color: #26a69a;
    border-radius: 2px;
    z-index: -1; }

.collapsible span.badge.new {
  z-index: 1; }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }
  .video-container iframe, .video-container object, .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.progress {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #acece6;
  border-radius: 2px;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden; }
  .progress .determinate {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #26a69a;
    transition: width .3s linear; }
  .progress .indeterminate {
    background-color: #26a69a; }
    .progress .indeterminate:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite; }
    .progress .indeterminate:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 1.15s; }

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

/*******************
  Utility Classes
*******************/
.hide {
  display: none !important; }

.left-align {
  text-align: left; }

.right-align {
  text-align: right; }

.center, .center-align {
  text-align: center; }

.left {
  float: left !important; }

.right {
  float: right !important; }

.no-select, input[type=range],
input[type=range] + .thumb {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.circle {
  border-radius: 50%; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.no-padding {
  padding: 0 !important; }

em {
  font-style: italic; }

b, strong {
  font-weight: 700; }

small {
  font-size: 75%; }

.light, footer.page-footer .footer-copyright {
  font-weight: 300; }

.thin {
  font-weight: 200; }

select:focus {
  outline: 1px solid #c9f3ef; }

button:focus {
  outline: none;
  background-color: #2ab7a9; }

label {
  font-size: 0.8rem;
  color: #9e9e9e; }

/* Radio Buttons
   ========================================================================== */
[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0; }

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  transition: .28s ease;
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  user-select: none; }

[type="radio"] + label:before,
[type="radio"] + label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: .28s ease; }

/* Unchecked styles */
[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border-radius: 50%; }

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
  border: 2px solid #5a5a5a; }

[type="radio"]:not(:checked) + label:after {
  transform: scale(0); }

/* Checked styles */
[type="radio"]:checked + label:before {
  border: 2px solid transparent; }

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border: 2px solid #26a69a; }

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
  background-color: #26a69a; }

[type="radio"]:checked + label:after {
  transform: scale(1.02); }

/* Radio With gap */
[type="radio"].with-gap:checked + label:after {
  transform: scale(0.5); }

/* Focused styles */
[type="radio"].tabbed:focus + label:before {
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1); }

/* Disabled Radio With gap */
[type="radio"].with-gap:disabled:checked + label:before {
  border: 2px solid rgba(0, 0, 0, 0.26); }

[type="radio"].with-gap:disabled:checked + label:after {
  border: none;
  background-color: rgba(0, 0, 0, 0.26); }

/* Disabled style */
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.26); }

[type="radio"]:disabled + label {
  color: rgba(0, 0, 0, 0.26); }

[type="radio"]:disabled:not(:checked) + label:before {
  border-color: rgba(0, 0, 0, 0.26); }

[type="radio"]:disabled:checked + label:after {
  background-color: rgba(0, 0, 0, 0.26);
  border-color: #BDBDBD; }

/* Checkboxes
   ========================================================================== */
/* CUSTOM CSS CHECKBOXES */
form p {
  margin-bottom: 10px;
  text-align: left; }

form p:last-child {
  margin-bottom: 0; }

/* Remove default checkbox */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0; }

[type="checkbox"] {
  /* checkbox aspect */ }
  [type="checkbox"] + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */ }
  [type="checkbox"] + label:before,
  [type="checkbox"]:not(.filled-in) + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 2px solid #5a5a5a;
    border-radius: 1px;
    margin-top: 2px;
    transition: .2s; }
  [type="checkbox"]:not(.filled-in) + label:after {
    border: 0;
    transform: scale(0); }
  [type="checkbox"]:not(:checked):disabled + label:before {
    border: none;
    background-color: rgba(0, 0, 0, 0.26); }
  [type="checkbox"].tabbed:focus + label:after {
    transform: scale(1);
    border: 0;
    border-radius: 50%;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1); }

[type="checkbox"]:checked + label:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #26a69a;
  border-bottom: 2px solid #26a69a;
  transform: rotate(40deg);
  backface-visibility: hidden;
  transform-origin: 100% 100%; }

[type="checkbox"]:checked:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  border-bottom: 2px solid rgba(0, 0, 0, 0.26); }

/* Indeterminate checkbox */
[type="checkbox"]:indeterminate + label:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 2px solid #26a69a;
  border-bottom: none;
  transform: rotate(90deg);
  backface-visibility: hidden;
  transform-origin: 100% 100%; }

[type="checkbox"]:indeterminate:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  background-color: transparent; }

[type="checkbox"].filled-in + label:after {
  border-radius: 2px; }

[type="checkbox"].filled-in + label:before,
[type="checkbox"].filled-in + label:after {
  content: '';
  left: 0;
  position: absolute;
  /* .1s delay is for check animation */
  transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  z-index: 1; }

[type="checkbox"].filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  transform-origin: 100% 100%; }

[type="checkbox"].filled-in:not(:checked) + label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #5a5a5a;
  top: 0px;
  z-index: 0; }

[type="checkbox"].filled-in:checked + label:before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #26a69a;
  background-color: #26a69a;
  z-index: 0; }

[type="checkbox"].filled-in.tabbed:focus + label:after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, 0.1); }

[type="checkbox"].filled-in.tabbed:checked:focus + label:after {
  border-radius: 2px;
  background-color: #26a69a;
  border-color: #26a69a; }

[type="checkbox"].filled-in:disabled:not(:checked) + label:before {
  background-color: transparent;
  border: 2px solid transparent; }

[type="checkbox"].filled-in:disabled:not(:checked) + label:after {
  border-color: transparent;
  background-color: #BDBDBD; }

[type="checkbox"].filled-in:disabled:checked + label:before {
  background-color: transparent; }

[type="checkbox"].filled-in:disabled:checked + label:after {
  background-color: #BDBDBD;
  border-color: #BDBDBD; }

/* Switch
   ========================================================================== */
.switch,
.switch * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none; }

.switch label {
  cursor: pointer; }

.switch label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0; }
  .switch label input[type=checkbox]:checked + .lever {
    background-color: #84c7c1; }
    .switch label input[type=checkbox]:checked + .lever:after {
      background-color: #26a69a;
      left: 24px; }

.switch label .lever {
  content: "";
  display: inline-block;
  position: relative;
  width: 40px;
  height: 15px;
  background-color: #818181;
  border-radius: 15px;
  margin-right: 10px;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px; }
  .switch label .lever:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 21px;
    height: 21px;
    background-color: #F1F1F1;
    border-radius: 21px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
    left: -5px;
    top: -3px;
    transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease; }

input[type=checkbox]:checked:not(:disabled) ~ .lever:active::after,
input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1); }

input[type=checkbox]:not(:disabled) ~ .lever:active:after,
input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08); }

.switch input[type=checkbox][disabled] + .lever {
  cursor: default; }

.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
  background-color: #BDBDBD; }

/* Select Field
   ========================================================================== */
select {
  display: none; }

select.browser-default {
  display: block; }

select {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  padding: 5px;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  height: 3rem; }

.select-label {
  position: absolute; }

.select-wrapper {
  position: relative; }
  .select-wrapper input.select-dropdown {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    outline: none;
    height: 3rem;
    line-height: 3rem;
    width: 100%;
    font-size: 1rem;
    margin: 0 0 20px 0;
    padding: 0;
    display: block; }
  .select-wrapper span.caret {
    color: initial;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 10px;
    margin: auto 0;
    font-size: 10px;
    line-height: 10px; }
    .select-wrapper span.caret.disabled {
      color: rgba(0, 0, 0, 0.26); }
  .select-wrapper + label {
    position: absolute;
    top: -14px;
    font-size: 0.8rem; }

select:disabled {
  color: rgba(0, 0, 0, 0.3); }

.select-wrapper input.select-dropdown:disabled {
  color: rgba(0, 0, 0, 0.3);
  cursor: default;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -ms-user-select: none;
  /* IE10+ */
  border-bottom: 1px solid rgba(0, 0, 0, 0.3); }

.select-wrapper i {
  color: rgba(0, 0, 0, 0.3); }

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
  color: rgba(0, 0, 0, 0.3);
  background-color: transparent; }

.prefix ~ .select-wrapper {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem); }

.prefix ~ label {
  margin-left: 3rem; }

.select-dropdown li img {
  height: 40px;
  width: 40px;
  margin: 5px 15px;
  float: right; }

.select-dropdown li.optgroup {
  border-top: 1px solid #eee; }
  .select-dropdown li.optgroup.selected > span {
    color: rgba(0, 0, 0, 0.7); }
  .select-dropdown li.optgroup > span {
    color: rgba(0, 0, 0, 0.4); }
  .select-dropdown li.optgroup ~ li.optgroup-option {
    padding-left: 1rem; }

/* File Input
   ========================================================================== */
.file-field {
  position: relative; }
  .file-field .file-path-wrapper {
    overflow: hidden;
    padding-left: 10px; }
  .file-field input.file-path {
    width: 100%; }
  .file-field .btn {
    float: left;
    height: 3rem;
    line-height: 3rem; }
  .file-field span {
    cursor: pointer; }
  .file-field input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0); }

/* Range
   ========================================================================== */
.range-field {
  position: relative; }

input[type=range],
input[type=range] + .thumb {
  cursor: pointer; }

input[type=range] {
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  margin: 15px 0;
  padding: 0; }
  input[type=range]:focus {
    outline: none; }

input[type=range] + .thumb {
  position: absolute;
  border: none;
  height: 0;
  width: 0;
  border-radius: 50%;
  background-color: #26a69a;
  top: 10px;
  margin-left: -6px;
  transform-origin: 50% 50%;
  transform: rotate(-45deg); }
  input[type=range] + .thumb .value {
    display: block;
    width: 30px;
    text-align: center;
    color: #26a69a;
    font-size: 0;
    transform: rotate(45deg); }
  input[type=range] + .thumb.active {
    border-radius: 50% 50% 50% 0; }
    input[type=range] + .thumb.active .value {
      color: #fff;
      margin-left: -1px;
      margin-top: 8px;
      font-size: 10px; }

input[type=range] {
  -webkit-appearance: none; }

input[type=range]::-webkit-slider-runnable-track {
  height: 3px;
  background: #c2c0c2;
  border: none; }

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #26a69a;
  transform-origin: 50% 50%;
  margin: -5px 0 0 0;
  transition: .3s; }

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ccc; }

input[type=range] {
  /* fix for FF unable to apply focus style bug  */
  border: 1px solid white;
  /*required for proper track sizing in FF*/ }

input[type=range]::-moz-range-track {
  height: 3px;
  background: #ddd;
  border: none; }

input[type=range]::-moz-range-thumb {
  border: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: #26a69a;
  margin-top: -5px; }

input[type=range]:-moz-focusring {
  outline: 1px solid #fff;
  outline-offset: -1px; }

input[type=range]:focus::-moz-range-track {
  background: #ccc; }

input[type=range]::-ms-track {
  height: 3px;
  background: transparent;
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent; }

input[type=range]::-ms-fill-lower {
  background: #777; }

input[type=range]::-ms-fill-upper {
  background: #ddd; }

input[type=range]::-ms-thumb {
  border: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: #26a69a; }

input[type=range]:focus::-ms-fill-lower {
  background: #888; }

input[type=range]:focus::-ms-fill-upper {
  background: #ccc; }

@media screen and (max-width: 1000px) {
  .cd-handle {
    display: none; }
  .cd-image-container {
    background-image: url("../img/Bel_180502_1880_BDEF-compressor.jpg"); } }

@media screen and (max-width: 720px) {
  body,
  html {
    overflow-x: hidden; }
  .cd-handle {
    display: none; }
  .cd-image-container {
    background-image: url("../img/Bel_180502_1880_BDEF-compressor.jpg"); }
  div.cta-block div.container {
    display: none; }
  header {
    position: absolute;
    padding-left: 0;
    padding-right: 0; }
    header a.logo {
      top: 18vh;
      transform: translateY(-50%);
      width: 60%; }
      body.page a.logo,
      body.recipes a.logo {
        top: 18vh; }
    header .burger-holder {
      width: 40px; }
    header .burger-click-region {
      padding: 11px 9px; }
    header ul.language {
      left: 125px;
      top: 7px;
      transform: none; }
    header a.home {
      height: 40px;
      width: 40px; }
    header a.home {
      position: absolute;
      top: 1rem;
      left: 4.5rem; }
    header ul.mini-nav {
      top: 28px;
      right: 1rem;
      position: absolute; }
    header div.burger-holder {
      position: absolute;
      display: inline-block;
      top: 1rem;
      left: 1rem; }
  div.menu div.selector-holder {
    position: absolute;
    transform: translateY(-50%);
    top: 50%; }
    div.menu div.selector-holder div.selector {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      min-height: auto;
      margin: 0; }
      div.menu div.selector-holder div.selector:nth-child(2) {
        border: none; }
      div.menu div.selector-holder div.selector h2 {
        font-size: 1.3em;
        margin: 0.4em; }
      div.menu div.selector-holder div.selector p {
        font-size: 1.25em;
        margin-bottom: 40px; }
      div.menu div.selector-holder div.selector a.cta {
        position: absolute;
        font-size: 0.7em;
        height: 40px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        line-height: 19px; }
        div.menu div.selector-holder div.selector a.cta:before {
          border-radius: 0; }
  .product-modal-container .content .close {
    font-size: 2em;
    right: 0.5em;
    top: 0; }
  section.mosaic {
    padding-top: 0;
    padding-bottom: 0; }
    section.mosaic .container {
      text-align: center; }
      section.mosaic .container > * {
        text-align: left; }
    section.mosaic .container .back-row {
      text-align: left; }
      section.mosaic .container .back-row a {
        margin-left: 1rem; }
    section.mosaic .container > a {
      width: calc(100% - 2rem);
      margin-bottom: 1rem;
      display: inline-block; }
    section.mosaic div.mosaic_block {
      width: 100%;
      float: none;
      margin: 0;
      position: relative;
      display: block;
      height: 100vw; }
  section.inner div.container {
    padding: 0.5em 0.6em;
    padding-top: 3em; }
    body.page section.inner div.container {
      padding-top: 1em; }
  section.content-inner-page div.container p.breadcrumb {
    text-align: center; }
  section.content-inner-page div.container div.container-block {
    width: 100%;
    padding: 20px;
    margin: 0; }
  section.content-inner-page.fromages div.container div.container-block {
    width: 100%; }
  section.cheeseletter input[type=submit],
  section.cheeseletter input[type=text] {
    display: block;
    width: 100%;
    margin: 0;
    right: 0;
    border: none;
    border-radius: 0px;
    height: 3rem; }
  section.mosaic_recettes div.container div.selector:not(.search) p {
    width: 50%;
    margin: .5rem 0; }
  section.mosaic_recettes div.container div.selector:not(.search) p:first-of-type {
    width: 100%; }
  section.mosaic_recettes div.container div.selector {
    width: calc(100% - 2rem);
    margin-bottom: 1rem; }
  section.mosaic_recettes div.container div.mosaic_block div.mosaic_background {
    width: 100%; }
  section.inner div.container img.img-article {
    width: 100%;
    padding-left: 0;
    float: none;
    margin-bottom: 2em; }
  footer ul.mini-nav {
    display: none; }
  footer div.upper {
    margin-top: 0; }
    footer div.upper ul {
      display: block;
      padding: 0;
      margin-top: 1em;
      float: none;
      width: 50%;
      float: left;
      height: 160px; } }

section.timeline div.timeline-header {
  height: 450px;
  width: 100%;
  background-size: cover !important; }

@media screen and (max-width: 670px) {
  section.timeline .iframe-desktop {
    display: none !important; } }

section.timeline div.timeline-txt {
  background: #f0f0f3;
  overflow: hidden;
  text-align: center;
  color: rgba(8, 8, 8, 0.8); }
  section.timeline div.timeline-txt h2 {
    font-weight: 300;
    font-size: 3em;
    margin-top: 45px;
    margin-bottom: 20px; }
  section.timeline div.timeline-txt p {
    width: 65%;
    margin: 0 auto;
    margin-bottom: 50px;
    line-height: 25px; }

section.timeline div.timeline-content div.content-block {
  height: 480px;
  width: 100%;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 670px) {
    section.timeline div.timeline-content div.content-block {
      height: auto; } }
  section.timeline div.timeline-content div.content-block div.content-block-container {
    width: 100%;
    height: 100%;
    max-width: 1440px;
    position: relative;
    margin: 0 auto; }
  section.timeline div.timeline-content div.content-block:nth-child(2) div.block-timer:after {
    background-color: #e3cd7; }
  @media screen and (max-width: 1250px) {
    section.timeline div.timeline-content div.content-block:nth-child(2) div.block-content img {
      top: 170px;
      right: -50px; } }
  @media screen and (max-width: 670px) {
    section.timeline div.timeline-content div.content-block:nth-child(2) div.block-content img:nth-child(2), section.timeline div.timeline-content div.content-block:nth-child(2) div.block-content img:nth-child(3) {
      display: none; } }
  section.timeline div.timeline-content div.content-block:nth-child(3) div.block-timer:after {
    background-color: #cdc863; }
  section.timeline div.timeline-content div.content-block:nth-child(3) div.block-content img {
    right: -100px; }
    @media screen and (max-width: 1350px) {
      section.timeline div.timeline-content div.content-block:nth-child(3) div.block-content img {
        width: 630px; } }
    @media screen and (max-width: 1250px) {
      section.timeline div.timeline-content div.content-block:nth-child(3) div.block-content img {
        top: -150px;
        right: -320px; } }
  section.timeline div.timeline-content div.content-block:nth-child(4) div.block-timer:after {
    background-color: #ed984c; }
  section.timeline div.timeline-content div.content-block:nth-child(4) div.block-content img {
    transform: rotate(5deg);
    top: 90px;
    width: 500px;
    right: 0px;
    pointer-events: none; }
    @media screen and (max-width: 1250px) {
      section.timeline div.timeline-content div.content-block:nth-child(4) div.block-content img {
        width: 450px;
        right: -100px; } }
  section.timeline div.timeline-content div.content-block:nth-child(4) iframe {
    position: absolute;
    top: 110px;
    bottom: 0;
    right: 95px;
    width: 315px;
    height: 215px;
    margin: auto; }
    @media screen and (max-width: 1250px) {
      section.timeline div.timeline-content div.content-block:nth-child(4) iframe {
        top: 110px;
        bottom: 0;
        right: -45px;
        width: 295px;
        height: 190px; } }
  section.timeline div.timeline-content div.content-block:nth-child(5) div.block-timer:after {
    background-color: #635a52; }
  section.timeline div.timeline-content div.content-block:nth-child(5) div.block-content img {
    top: 90px; }
    @media screen and (max-width: 1250px) {
      section.timeline div.timeline-content div.content-block:nth-child(5) div.block-content img {
        right: -120px;
        top: 160px; } }
  section.timeline div.timeline-content div.content-block:nth-child(6) div.block-timer:after {
    background-color: #e8c584; }
  @media screen and (max-width: 1350px) {
    section.timeline div.timeline-content div.content-block:nth-child(6) div.block-content img {
      width: 500px; } }
  section.timeline div.timeline-content div.content-block:nth-child(7) div.block-timer:after {
    background-color: #f39c12; }
  section.timeline div.timeline-content div.content-block:nth-child(8) div.block-timer:after {
    background-color: #8b674f; }
  section.timeline div.timeline-content div.content-block:nth-child(8) div.block-content img {
    width: 700px;
    right: -25px;
    top: 100px;
    pointer-events: none; }
    @media screen and (max-width: 1250px) {
      section.timeline div.timeline-content div.content-block:nth-child(8) div.block-content img {
        width: 510px;
        right: -92px;
        top: 160px; } }
  section.timeline div.timeline-content div.content-block:nth-child(8) iframe {
    position: absolute;
    top: -12px;
    bottom: 0;
    right: 122px;
    width: 405px;
    height: 230px;
    margin: auto; }
    @media screen and (max-width: 1250px) {
      section.timeline div.timeline-content div.content-block:nth-child(8) iframe {
        top: 80px;
        bottom: 0;
        right: 15px;
        width: 295px;
        height: 164px; } }
  section.timeline div.timeline-content div.content-block:nth-child(9) div.block-timer:after {
    background-color: #a8120a; }
  section.timeline div.timeline-content div.content-block:nth-child(9) div.block-content img {
    top: 50px; }
    @media screen and (max-width: 1350px) {
      section.timeline div.timeline-content div.content-block:nth-child(9) div.block-content img {
        width: 500px; } }
  section.timeline div.timeline-content div.content-block:nth-child(10) div.block-timer:after {
    background-color: #f1c40f; }
  section.timeline div.timeline-content div.content-block:nth-child(10) div.block-content img {
    top: 160px;
    right: 10px; }
    @media screen and (max-width: 1250px) {
      section.timeline div.timeline-content div.content-block:nth-child(10) div.block-content img {
        right: -230px; } }
  section.timeline div.timeline-content div.content-block:nth-child(11) div.block-timer:after {
    background-color: #db7734; }
  section.timeline div.timeline-content div.content-block:nth-child(11) div.block-content img {
    width: 700px;
    right: -25px;
    top: 100px;
    pointer-events: none; }
    @media screen and (max-width: 1250px) {
      section.timeline div.timeline-content div.content-block:nth-child(11) div.block-content img {
        width: 528px;
        right: -65px;
        top: 90px;
        pointer-events: none; } }
  section.timeline div.timeline-content div.content-block:nth-child(11) iframe {
    position: absolute;
    top: 52px;
    bottom: 0;
    right: 88px;
    width: 372px;
    height: 215px;
    margin: auto; }
    @media screen and (max-width: 1250px) {
      section.timeline div.timeline-content div.content-block:nth-child(11) iframe {
        position: absolute;
        top: 52px;
        bottom: 0;
        right: 20px;
        width: 280px;
        height: 160px;
        margin: auto; } }
  section.timeline div.timeline-content div.content-block:nth-child(12) div.block-timer:after {
    background-color: #34dbdb; }
  section.timeline div.timeline-content div.content-block:nth-child(12) div.block-content img {
    width: 600px;
    right: -25px;
    top: 100px;
    pointer-events: none; }
    @media screen and (max-width: 1250px) {
      section.timeline div.timeline-content div.content-block:nth-child(12) div.block-content img {
        width: 528px;
        right: -65px;
        top: 90px;
        pointer-events: none; } }
  section.timeline div.timeline-content div.content-block div.block-timer {
    height: 100%;
    width: 350px;
    border-right: solid white 3px;
    position: relative;
    float: left;
    padding: 50px;
    background: white; }
    @media screen and (max-width: 1350px) {
      section.timeline div.timeline-content div.content-block div.block-timer {
        width: 260px; } }
    @media screen and (max-width: 670px) {
      section.timeline div.timeline-content div.content-block div.block-timer {
        display: none; } }
    section.timeline div.timeline-content div.content-block div.block-timer ul {
      padding: 0;
      margin: 0; }
      section.timeline div.timeline-content div.content-block div.block-timer ul:hover li {
        font-size: 22px !important;
        line-height: inherit !important; }
      section.timeline div.timeline-content div.content-block div.block-timer ul li {
        list-style: none;
        display: block;
        color: #4a4a4a;
        font-family: 'Malbeck';
        font-size: 22px;
        transition: .3s ease; }
        section.timeline div.timeline-content div.content-block div.block-timer ul li a {
          color: inherit; }
        section.timeline div.timeline-content div.content-block div.block-timer ul li.previous, section.timeline div.timeline-content div.content-block div.block-timer ul li.next {
          font-size: 60px !important; }
        section.timeline div.timeline-content div.content-block div.block-timer ul li.current {
          font-size: 80px !important; }
    section.timeline div.timeline-content div.content-block div.block-timer:before {
      position: absolute;
      content: '';
      top: 0;
      right: 0px;
      height: 100%;
      width: 1000px;
      background: white;
      border-bottom: solid 1px #c4c4c4;
      z-index: -1; }
    section.timeline div.timeline-content div.content-block div.block-timer:after {
      position: absolute;
      content: '';
      width: 31px;
      height: 31px;
      border-radius: 50px;
      border: 8px solid #fff;
      top: 80px;
      right: -17px;
      background-color: #f15a29; }
  section.timeline div.timeline-content div.content-block div.block-content {
    width: calc(100% - 350px);
    padding: 50px;
    height: 100%;
    float: right;
    color: white; }
    @media screen and (max-width: 1350px) {
      section.timeline div.timeline-content div.content-block div.block-content {
        width: calc(100% - 260px); } }
    @media screen and (max-width: 670px) {
      section.timeline div.timeline-content div.content-block div.block-content {
        width: 100%;
        float: none; } }
    @media screen and (max-width: 960px) {
      section.timeline div.timeline-content div.content-block div.block-content iframe {
        display: none; } }
    @media screen and (max-width: 670px) {
      section.timeline div.timeline-content div.content-block div.block-content iframe {
        display: block;
        opacity: .3 !important; } }
    section.timeline div.timeline-content div.content-block div.block-content iframe.iframe-mobile {
      display: none !important; }
      @media screen and (max-width: 670px) {
        section.timeline div.timeline-content div.content-block div.block-content iframe.iframe-mobile {
          display: block !important;
          width: 100% !important;
          height: auto !important;
          position: relative !important;
          opacity: 1 !important;
          left: initial;
          top: initial;
          right: initial;
          bottom: initial; } }
    section.timeline div.timeline-content div.content-block div.block-content h3 {
      font-size: 3.4em;
      margin: 0;
      line-height: 55px; }
      @media screen and (max-width: 1250px) {
        section.timeline div.timeline-content div.content-block div.block-content h3 {
          font-size: 3em;
          line-height: 45px; }
          section.timeline div.timeline-content div.content-block div.block-content h3 br {
            display: none; } }
      @media screen and (max-width: 770px) {
        section.timeline div.timeline-content div.content-block div.block-content h3 span.removable {
          display: none; }
        section.timeline div.timeline-content div.content-block div.block-content h3 span.removable ~ span {
          display: inline-block; }
          section.timeline div.timeline-content div.content-block div.block-content h3 span.removable ~ span:first-letter {
            text-transform: uppercase; } }
      @media screen and (max-width: 670px) {
        section.timeline div.timeline-content div.content-block div.block-content h3 {
          font-size: 2.5em; }
          section.timeline div.timeline-content div.content-block div.block-content h3 span.removable, section.timeline div.timeline-content div.content-block div.block-content h3 span.removable ~ span {
            display: inline; } }
    section.timeline div.timeline-content div.content-block div.block-content p {
      max-width: 400px;
      margin-top: 25px;
      line-height: 20px; }
      section.timeline div.timeline-content div.content-block div.block-content p a {
        color: white;
        text-decoration: underline; }
    section.timeline div.timeline-content div.content-block div.block-content img {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 50px;
      margin: auto; }
      @media screen and (max-width: 1250px) {
        section.timeline div.timeline-content div.content-block div.block-content img {
          right: -150px;
          opacity: 1 !important; } }
      @media screen and (max-width: 960px) {
        section.timeline div.timeline-content div.content-block div.block-content img {
          display: none; } }
      @media screen and (max-width: 670px) {
        section.timeline div.timeline-content div.content-block div.block-content img {
          display: block;
          opacity: .3 !important; } }

span.mobile-time {
  display: none; }
  @media screen and (max-width: 670px) {
    span.mobile-time {
      display: block;
      text-align: left;
      color: white;
      font-family: 'Malbeck';
      font-size: 80px;
      position: relative; } }

.page-whitecheese section {
  position: relative;
  overflow: hidden; }

.page-whitecheese .hero {
  height: 60vh;
  min-height: 540px;
  max-height: 720px;
  overflow: hidden; }

.page-whitecheese h1, .page-whitecheese h2,
.page-whitecheese h1 strong, .page-whitecheese h2 strong {
  font-family: 'Raleway', sans-serif;
  font-weight: 800;
  line-height: 1.2;
  text-transform: uppercase; }

.page-whitecheese .cover-image img,
.page-whitecheese .cover-image + img {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  @media screen and (max-width: 1280px) {
    .page-whitecheese .cover-image img,
    .page-whitecheese .cover-image + img {
      max-height: 100%; } }

.page-whitecheese .container {
  max-width: 960px;
  margin: auto;
  padding: 3rem 0;
  position: relative;
  z-index: 5; }
  .page-whitecheese .container::after {
    content: "";
    display: table;
    clear: both; }

.page-whitecheese .product {
  width: 65%;
  height: 100%;
  float: left;
  padding: 1rem 2rem .5rem 0;
  position: absolute;
  top: 0;
  left: 0; }

.page-whitecheese .product img {
  max-width: 100%;
  width: auto;
  max-height: 100%;
  height: auto;
  object-fit: contain; }

.page-whitecheese .content {
  float: right;
  width: 35%;
  padding-left: 1rem;
  line-height: 1.4;
  position: relative;
  z-index: 5; }
  .page-whitecheese .content h1 {
    font-size: 1.5rem; }
  .page-whitecheese .content h2 {
    font-size: 4rem;
    margin: 0 0 1rem; }
    .page-whitecheese .content h2 span {
      font-size: 1.2em; }

.page-whitecheese .promo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 20%, transparent 50%); }

.page-whitecheese .promo .content {
  float: left;
  color: white; }

.page-whitecheese .content a {
  display: inline-block;
  margin-top: .5rem; }
  .page-whitecheese .content a:hover span::after {
    top: 100%;
    opacity: 1; }

.page-whitecheese .mosaic.mosaic_blog {
  padding: 0; }

.page-whitecheese + .cheeseletter_old {
  background-color: #26241e;
  color: white; }
  .page-whitecheese + .cheeseletter_old b {
    color: #f7edab;
    font-weight: 500; }

@media screen and (max-width: 1024px), screen and (max-height: 768px) {
  .page-whitecheese .hero {
    min-height: 460px; } }

@media screen and (max-width: 960px) {
  .page-whitecheese .hero {
    min-height: 0; }
  .page-whitecheese .promo::after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 20%, transparent 60%); }
  .page-whitecheese .new_whitecheese .content {
    padding-left: 0;
    padding-right: 1rem; }
  .page-whitecheese .content {
    min-width: 320px; }
  .page-whitecheese .product {
    width: 60%;
    margin-top: 2rem; }
  .page-whitecheese .promo .content {
    margin-left: 2rem; } }

@media screen and (max-width: 720px) {
  .page-whitecheese .new_whitecheese .product {
    float: none;
    width: 100%;
    max-width: 480px;
    margin: 1rem auto;
    position: static; }
  .page-whitecheese .new_whitecheese .content {
    float: none;
    width: 100%;
    max-width: 540px;
    margin: auto;
    text-align: center;
    padding: 0 1rem; } }

@media screen and (max-width: 480px) {
  .page-whitecheese .promo::after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.25)); }
  .page-whitecheese .promo .content {
    margin-left: .5rem; }
  .page-whitecheese .content {
    width: 90%;
    margin-left: 0;
    padding: 1rem .5rem;
    min-width: 240px; }
    .page-whitecheese .content h2 {
      font-size: 3.75rem; } }

.page-whitecheese ~ #cheeseletter-box .cta-form-holder {
  background-image: linear-gradient(90deg, #00909f, #1cb9c5, #00909f); }
  .page-whitecheese ~ #cheeseletter-box .cta-form-holder form input.fullsubmit {
    background-image: linear-gradient(90deg, #00909f, #1cb9c5, #00909f); }
