@media screen and (max-width: 1000px) {

	.cd-handle {
		display: none;
	}
	
	.cd-image-container {
		background-image: url('../img/Bel_180502_1880_BDEF-compressor.jpg');
	} 
}

@media screen and (max-width: $mobile-breakpoint) {
	
	body,
	html {
		overflow-x: hidden;
	}
	
	// Hide animations

	.cd-handle {
		display: none;
	}
	
	.cd-image-container {
		background-image: url('../img/Bel_180502_1880_BDEF-compressor.jpg');
	}
	
	div.cta-block div.container {
		display: none;
	}
	
	header {
		
		position: absolute;
		padding-left: 0;
		padding-right: 0;
		
		a.logo {
			
			top: 18vh;
			transform: translateY(-50%);
			width: 60%;
			
			@at-root {
				body.page, 
				body.recipes {
					a.logo {
						top: 18vh;
					}
				}
			}
		}
		
		.burger-holder {
			width: 40px;
		}
		
		.burger-click-region {
			padding: 11px 9px;
		}
		
		// ul.mini-nav li a.inspiration {
			
		// 	width: 40px;
		// 	height: 40px;
		// 	overflow: hidden;
		// 	display: inline-block;
		// 	text-indent: 80px;
			
		// 	i {
		// 		font-size: 2em;
		// 		margin: 0;
		// 		margin-left: -80px;
		// 		padding: 0;
		// 		line-height: 1em;
		// 		position: absolute;
		// 		top: 15%;
		// 	}
		// }

		ul.language {
			left: 125px;
			top: 7px;
			transform: none;
		}
		
		a.home {
			height: 40px;
			width: 40px;
		}
		
		a.home {
			position: absolute;
			top: 1rem;
			left: 4.5rem;
		}
		
		// ul.mini-nav {
		// 	position: absolute;
		// 	top: 1rem;
		// 	left: 8rem;
		// 	display: none; // remove Display None to show "Inspirations" button, but needs to be fixed for iPhone 5

		// 	li {
		// 		// display: none;
		// 	}

		// 	li:first-of-type {
		// 		display: inline-block;
		// 		top: 0;
		// 		left: 0;
		// 		padding: 0;
		// 	}
		// }

		ul.mini-nav {
			top: 28px;
			right: 1rem;
			position: absolute;
		}
		
		div.burger-holder {
			position: absolute;
			display: inline-block;
			top: 1rem;
			left: 1rem;
		}
		
		// ul.language {
		// 	position: absolute;
		// 	top: 1em;
		// 	right: 1em;
		// 	left: auto;
			
		// 	li {
				
		// 		display: inline-block;
		// 		text-align: right;
				
		// 		a {
		// 			opacity: 1;
		// 		}
		// 	}
		// }
	
	}
	
	div.menu div.selector-holder {
		
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		
		div.selector {
			
			display: block;
			position: relative;
			width: 100%;
			height: auto;
			min-height: auto;
			margin: 0;

			&:nth-child(2) {
				border: none;
			}
			
			h2 {
				font-size: 1.3em;
				margin: 0.4em;
			}
			
			p {
				font-size: 1.25em;
				margin-bottom: 40px;

			}
			
			a.cta {
				position: absolute;
				// padding: 1.15em 0.5em;
				font-size: 0.7em;
				// width: 40px;
				height: 40px;
				display: block;
				white-space: nowrap;
				overflow: hidden;
				line-height: 19px;
				&:before {
					border-radius: 0;
					// height: 100vh;
					// width: 100vw;
				}
			}
		}
	}
	
	.product-modal-container {
		.content .close {
			font-size: 2em;
			right: 0.5em;
			top: 0;
		}
	}
	
	section.mosaic {
		
		padding-top: 0;
		padding-bottom: 0;
		
		.container {
			text-align: center;
			
			& > * {
				text-align: left;
			}
		}

		.container .back-row {
			text-align: left;
			
			a {
				margin-left: 1rem;
			}
		}

		.container > a {
			width: calc(100% - 2rem);
			margin-bottom: 1rem;
			display: inline-block;
		}
		
		div.mosaic_block {
			width: 100%;
			float: none;
			margin: 0;
			position: relative;
			display: block;
			height: 100vw;
			
			div.mosaic_content {
			//	position: absolute !important;
			//	top: 50%;
			//	left: 50%;
			//	transform: translate(-50%, 0%);	
			}			
		}
	}
	
	section.inner {
		div.container {
			padding: 0.5em 0.6em;
			padding-top: 3em;
			@at-root {
				body.page {
					section.inner div.container {
						padding-top: 1em;
					}
				}
			}
		}
	}
	
	section.content-inner-page div.container p.breadcrumb {
		text-align: center;
	}
	
	section.content-inner-page {
		div.container {
			div.container-block {
				width: 100%;
				//height: auto;
				padding: 20px;
				margin: 0;
			}
		}	
		
	}
	
	section.content-inner-page.fromages div.container div.container-block {
		
		width: 100%;
		
		div.container-block-txt {
			//transform: translateY(-100%);
			//background: white;
		}
		
		//div.container-block-background {
		//	height: 100% !important;
		//}
	}
	
	section.cheeseletter input[type=submit],
	section.cheeseletter input[type=text]{
		display: block;
		width: 100%;
		margin: 0;
		right: 0;
		border: none;
		border-radius: 0px;
		height: 3rem;
	}

	section.mosaic_recettes {
		div.container {
			div.selector:not(.search) {
				// display: none;
				
				p {
					width: 50%;
					margin: .5rem 0;
				}
				
				p:first-of-type {
					width: 100%;
				}
			}
			
			div.selector {
				width: calc(100% - 2rem);
				margin-bottom: 1rem;
			}
			
			div.mosaic_block div.mosaic_background {
				width: 100%;
			}
		}
	}

	section.inner div.container img.img-article {
		width: 100%;
		padding-left: 0;
		float: none;
		margin-bottom: 2em;
	}
	
	footer {
		
		ul.mini-nav {
			display: none;
		}
		
		div.upper {
			
			margin-top: 0;
			
			ul {
				display: block;
				padding: 0;
				margin-top: 1em;
				float: none;
			    width: 50%;
			    float: left;
			    height: 160px;
			}
		}
	}
	
	
}