.product-modal-container {
	
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(#171717, 0.75);
	
	height: 100%;
	width: 100%;
	
	overflow-y: scroll;
	z-index: 15;
	
	.breadcrumb,
	.breadcrump {
		display: none;
	}
	
	.content {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 3em;
		
		background: white;
		padding: 2em;
		max-width: 960px;
    	width: 100%;
    	margin-bottom: 50px;

    	h1 {
    		color: $red;
    	}
	
		.close {
			position: absolute;
			right: 0.5em;
			top: 0;
			font-size: 4em;
			cursor: pointer;
		}
	}
}