.load-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #fdba2c;
  z-index: 500;
}

.bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;

  &:nth-child(1) {
	  background-color: #da4733;
	  animation: loading 2s linear infinite;
  }
  
  &:nth-child(2) {
	  background-color: #fdba2c;
	  animation: loading 2s linear 1s infinite;
  }

}

@keyframes loading {
    from {left: 50%; width: 0;z-index:100;}
    33.3333% {left: 0; width: 100%;z-index: 10;}
    to {left: 0; width: 100%;}
}