section.timeline {
	div.timeline-header {
		height: 450px;
		width: 100%;
		background-size: cover !important;
	}

	.iframe-desktop {
		@media screen and (max-width: 670px) {
			display: none !important;
		} 
	}



	div.timeline-txt {
		background: #f0f0f3;
	    overflow: hidden;
	    text-align: center;
	    color: rgba(8,8,8,.8);

	    h2 {
	    	font-weight: 300;
		    font-size: 3em;
		    margin-top: 45px;
		    margin-bottom: 20px;
	    }

	    p {
	    	width: 65%;
		    margin: 0 auto;
		    margin-bottom: 50px;
		    line-height: 25px;
	    }
	}

	div.timeline-content {

		div.content-block {
			height: 480px;
			width: 100%;
			overflow: hidden;
			position: relative;

			@media screen and (max-width: 670px) {
				height: auto;
			}

			div.content-block-container {
				width: 100%;
				height: 100%;
				max-width: 1440px;
				position: relative;
				margin: 0 auto; 
			}

			&:first-child {

				div.block-content img {

					@media screen and (max-width: 1250px) {
						//right: -150px !important;
					}
 
				}
			}

			&:nth-child(2) {
				div.block-timer:after {
					background-color: #e3cd7;  
				}

				div.block-content img {
					@media screen and (max-width: 1250px) {
						top: 170px;
						right: -50px;
					}

					@media screen and (max-width: 670px) {
						 &:nth-child(2), &:nth-child(3) {
						 	display: none;
						 }
					}
				}

			}


			&:nth-child(3) {
				div.block-timer:after {
					background-color: #cdc863;
				}

				div.block-content img {
					right: -100px; 

					@media screen and (max-width: 1350px) {
						width: 630px;
					}

					@media screen and (max-width: 1250px) {
						top: -150px;
						right: -320px;
					}
				}

			}

			&:nth-child(4) {
				div.block-timer:after {
					background-color: #ed984c; 
				}

				div.block-content img {
					transform: rotate(5deg);
				    top: 90px;
				    width: 500px;
				    right: 0px;
				    pointer-events: none;

				    @media screen and (max-width: 1250px) {
				    	width: 450px;
    					right: -100px;
				    }
				}

				iframe {
				    position: absolute;
				    top: 110px;
				    bottom: 0;
				    right: 95px;
				    width: 315px;
				    height: 215px;
				    margin: auto;

				    @media screen and (max-width: 1250px) {
				    	top: 110px;
					    bottom: 0;
					    right: -45px;
					    width: 295px;
					    height: 190px;
				    } 
				}

			}

			&:nth-child(5) {
				div.block-timer:after {
					background-color: #635a52; 
				}

				div.block-content img {
					top: 90px;

					@media screen and (max-width: 1250px) {
						right: -120px;
						top: 160px;
					}

				}

			}

			&:nth-child(6) {
				div.block-timer:after {
					background-color: #e8c584; 
				}

				div.block-content img {
					@media screen and (max-width: 1350px) {
						width: 500px;
					}
				}
			}

			&:nth-child(7) {
				div.block-timer:after {
					background-color: #f39c12; 
				}


			}

			&:nth-child(8) {
				div.block-timer:after {
					background-color: #8b674f; 
				}

				div.block-content img {
					width: 700px;
				    right: -25px;
				    top: 100px;
				    pointer-events: none;

				    @media screen and (max-width: 1250px) {
				    	width: 510px;
					    right: -92px;
					    top: 160px;
				    }
				}

				iframe {
				    position: absolute;
				    top: -12px;
    				bottom: 0;
    				right: 122px;
				    width: 405px;
				    height: 230px;
				    margin: auto;

				    @media screen and (max-width: 1250px) {
				    	top: 80px;
					    bottom: 0;
					    right: 15px;
					    width: 295px;
					    height: 164px;
				    }
				}

			}

			&:nth-child(9) {
				div.block-timer:after {
					background-color: #a8120a; 
				}

				div.block-content img {
					top: 50px;

					@media screen and (max-width: 1350px) {
						width: 500px;
					}
				}

			}


			&:nth-child(10) {
				div.block-timer:after {
					background-color: #f1c40f; 
				}

				div.block-content img {
					top: 160px;
					right: 10px;


					@media screen and (max-width: 1250px) {
						right: -230px;
					}

				}

			}

			&:nth-child(11) {
				div.block-timer:after {
					background-color: #db7734; 
				}

				div.block-content img {
					width: 700px;
				    right: -25px;
				    top: 100px;
				    pointer-events: none;

				    @media screen and (max-width: 1250px) {
					    width: 528px;
					    right: -65px;
					    top: 90px;
					    pointer-events: none;
				    }
				}

				iframe {
				    position: absolute;
				    top: 52px;
				    bottom: 0;
				    right: 88px;
				    width: 372px;
				    height: 215px;
				    margin: auto;

				    @media screen and (max-width: 1250px) {
				    	position: absolute;
					    top: 52px;
					    bottom: 0;
					    right: 20px;
					    width: 280px;
					    height: 160px;
					    margin: auto;
				    }
				}

			}

			&:nth-child(12) {
				div.block-timer:after {
					background-color: #34dbdb; 
				}

				div.block-content img {
					width: 600px;
				    right: -25px;
				    top: 100px;
				    pointer-events: none;

				    @media screen and (max-width: 1250px) {
					    width: 528px;
					    right: -65px;
					    top: 90px;
					    pointer-events: none;
				    }
				}

			}

			div.block-timer {
				height: 100%;
				width: 350px;
				border-right: solid $white 3px;
				position: relative;
				float: left;
				padding: 50px;
				background: $white;
				



				@media screen and (max-width: 1350px) {
					width: 260px;
				}

				@media screen and (max-width: 670px) {
					display: none;
				}

				ul {
					padding: 0;
					margin: 0;

					&:hover li {
							font-size: 22px !important;
							line-height: inherit !important;
						}

					li {
						list-style: none;
						display: block;
						color: #4a4a4a;
						font-family: 'Malbeck';
						font-size: 22px;
						transition: .3s ease;

						a {
							color: inherit;
						}

						// &:hover {
						// 	font-size: 100px;
						// }

						// &:hover + li,
						// &:hover - li {
						//   font-size: 60px;

						// }



						&.previous, &.next {
							font-size: 60px !important;
							//line-height: 65px !important;
						}

						&.current {
							font-size: 80px !important;
							//line-height: 60px !important;
						}

					}
				} 

				&:before {
					position: absolute;
					content: '';
					top: 0;
					right: 0px;
					height: 100%;
					width: 1000px;
					background: $white;
					border-bottom: solid 1px rgba($greyer,1);
					z-index: -1;

				}

				&:after {
				    position: absolute;
				    content: '';
				    width: 31px;
				    height: 31px;
				    border-radius: 50px;
				    border: 8px solid #fff; 
				    top: 80px;
				    right: -17px;
				    background-color: #f15a29;
				}
			}

			div.block-content {
				width: calc(100% - 350px);
				padding: 50px;
				height: 100%; 
				float: right; 
				color: $white;

				@media screen and (max-width: 1350px) {
					width: calc(100% - 260px);
				}

				@media screen and (max-width: 670px) {
					width: 100%;
					float: none;
				}

				iframe {
					@media screen and (max-width: 960px) {
						display: none;
					}

					@media screen and (max-width: 670px) {
						display: block;
						opacity: .3 !important; 
					}

						&.iframe-mobile {
							display: none !important;
							@media screen and (max-width: 670px) {
								display: block !important;
								width: 100% !important;
								height: auto !important;
								position: relative !important;
								opacity: 1 !important;
								left: initial;
								top: initial;
								right: initial;
								bottom: initial;
							} 
						}
				}

				h3 {
					font-size: 3.4em;
					margin: 0;
					line-height: 55px;

					@media screen and (max-width: 1250px) {
						font-size: 3em;
						line-height: 45px;

						br {
							display: none;
						}
					}
					
					@media screen and (max-width: 770px) {
						span.removable {
							display: none;
						}
						
						span.removable ~ span {
							display: inline-block;
							
							&:first-letter {
								text-transform: uppercase;
							}
						}
					}

					@media screen and (max-width: 670px) {
						font-size: 2.5em;
						
						span.removable, span.removable ~ span {
							display: inline;
						}
					}
				}

				p {
					max-width: 400px;
					margin-top: 25px;
					line-height: 20px;

					a {
						color: $white;
						text-decoration: underline;
					}
				}

				img {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 50px;
					margin: auto;

					@media screen and (max-width: 1250px) {
						right: -150px;
						opacity: 1 !important;
					}

					@media screen and (max-width: 960px) {
						display: none;
					}

					@media screen and (max-width: 670px) {
						display: block;
						opacity: .3 !important; 
					}
				}
			}
		}
	}
}

// MOBILE

span.mobile-time {
	display: none;

	@media screen and (max-width: 670px) {
		display: block;
		text-align: left;
		color: $white;
		font-family: 'Malbeck';
		font-size: 80px;
		position: relative;

	}
}

















