div.menu {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba($black,.9);
	z-index: 10;
	top: 0;
	left: 0;

	transform: translateY(-100vh);
	transition: .5s ease;


	@media screen and (max-width: 670px) {
		height: 100%;
		transform: translateY(-200vh);
	}

	body.is-open & {
		transform: translateY(0);
	}

	div.selector-holder {
		max-width: 1200px;
		width: 100%;
		margin: 0 auto;
		overflow: hidden;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;

		div.selector {
			color: $white;
			width: calc(100% / 3);
			padding: 10px 60px;
			float: left;
			position: relative;
			min-height: 295px;
			
			@media screen and (max-width: 1030px) {
				padding: 10px 30px;
			}

			@media screen and (max-width: 1000px) {
				width: 100%;
				min-height: auto;
			}
			
			@media screen and (max-width: 820px) {
				.cta {
					margin: 0 -1rem;
					left: calc(50% + 1rem) !important;
				}
			}

			@media screen and (max-width: 670px) {
				max-height: 160px;
			}

			&:hover {
				h2 {
					color: $gold;
				}

				p {
					color: $white;
				}
			}

			&:nth-child(2) {
				border-left: 1px solid rgba($white, .8);
				border-right: 1px solid rgba($white, .8);
			}

			h2 {
				text-transform: uppercase;
				font-size: 2.2em;
				transition: $transition;
			}

			p {
				font-family: 'Malbeck';
				font-weight: 300;
				font-size: 2em;
				color: rgba($white, .9);
				transition: $transition;
				letter-spacing: 1px;
			}

			a.cta {
				position: absolute;
				bottom: 2px;
				font-weight: 500;
				left: 50%;
				transform: translateX(-50%);
				transition: $transition;
				border: 1px solid $white;
				background: none;

				&:hover {
        			&:before {
          				opacity: 1;
        			}
      			}

		        &:before {
		          position: absolute;
		          content: '';
		          left: 0;
		          top: 0;
		          height: 100%;
		          width: 100%;
		          background: linear-gradient(to right, #a2130b 0%,#e40215 50%,#a2130b 100%);
		          z-index: -1;
		          border-radius: 50px;
		          opacity: 0;
		          transition: $transition;
		        }
			}
		}
	}
}