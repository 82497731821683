.page-whitecheese {
	section {
		position: relative;
		overflow: hidden;
	}

	.hero {
		height: 60vh;
		min-height: 540px;
		max-height: 720px;
		overflow: hidden;
	}

	h1, h2,
	h1 strong, h2 strong {
		font-family: 'Raleway', sans-serif;
		font-weight: 800;
		line-height: 1.2;
		text-transform: uppercase;
	}

	.cover-image img,
	.cover-image + img {
		min-height: 100%;
		min-width: 100%;
		object-fit: cover;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		@media screen and(max-width: 1280px) {
			max-height: 100%;
		}
	}

	.container {
		max-width: 960px;
		margin: auto;
		padding: 3rem 0;
		position: relative;
		z-index: 5;

		&::after {
			content: "";
			display: table;
			clear: both;
		}
	}

	.product {
		width: 65%;
		height: 100%;
		float: left;
		padding: 1rem 2rem .5rem 0;
		position: absolute;
		top: 0;
		left: 0;
	}
	.product img {
		max-width: 100%;
		width: auto;
		max-height: 100%;
		height: auto;
		object-fit: contain;
	}
	.content {
		float: right;
		width: 35%;
		padding-left: 1rem;
		line-height: 1.4;
		position: relative;
		z-index: 5;

		h1 {
			font-size: 1.5rem;
		}
		h2 {
			font-size: 4rem;
			margin: 0 0 1rem;

			span {
				font-size: 1.2em;
			}
		}
	}

	.promo::after {
		content: "";
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		background-image: linear-gradient(to right, rgba(#000,.5) 20%, transparent 50%);
	}
	.promo .content {
		float: left;
		color: white;
	}

	.content a {
		// padding: 1rem 3rem;
		// color: black;
		// background-color: white;
		// text-transform: uppercase;
		// font-weight: bold;
		// border-radius: 2rem;
		display: inline-block;
		margin-top: .5rem;

		span {
			// display: inline-block;
			// position: relative;

			&::after {
				// content: "";
				// display: inline-block;
				// position: absolute;
				// width: 100%;
				// height: 2px;
				// background-color: black;
				// opacity: 0;
				// top: 120%;
				// left: 0;
				// transition: .4s;
			}
		}

		&:hover span::after {
			top: 100%;
			opacity: 1;
		}
	}

	.mosaic.mosaic_blog {
		padding: 0;
	}

	& + .cheeseletter_old {
		background-color: #26241e;

		color: white;

		b {
			color: #f7edab;
			font-weight: 500;
		}

	}

	@media screen and (max-width: 1024px), screen and (max-height: 768px) {
		.hero {
			min-height: 460px;
		}
	}

	@media screen and (max-width: 960px) {
		.hero {
			min-height: 0;
		}
		.promo::after {
			background-image: linear-gradient(to right, rgba(#000,.5) 20%, transparent 60%);
		}
		.new_whitecheese .content {
			padding-left: 0;
			padding-right: 1rem;
		}
		.content {
			min-width: 320px;
		}
		.product {
			width: 60%;
			margin-top: 2rem;
		}
		.promo .content {
			margin-left: 2rem;
		}
	}

	@media screen and (max-width: 720px) {
		.new_whitecheese {
			.product {
				float: none;
				width: 100%;
				max-width: 480px;
				margin: 1rem auto;
				position: static;
			}
			.content {
				float: none;
				width: 100%;
				max-width: 540px;
				margin: auto;
				text-align: center;
				padding: 0 1rem;
			}
		}
	}

	@media screen and (max-width: 480px) {
		.promo::after {
			background-image: linear-gradient(to right, rgba(#000,.6), rgba(#000,.25));
		}
		.promo .content {
			margin-left: .5rem;
		}
		.content {
			width: 90%;
			margin-left: 0;
			padding: 1rem .5rem;
			min-width: 240px;

			h2 {
				font-size: 3.75rem;
			}
		}

	}
}

.page-whitecheese ~ #cheeseletter-box {
	.cta-form-holder {
		background-image: linear-gradient(90deg, #00909f, #1cb9c5, #00909f);

		form input.fullsubmit {
			background-image: linear-gradient(90deg, #00909f, #1cb9c5, #00909f);
		}
	}
}