@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700');


@font-face {
	font-family: 'adjust';
	src:url('../fonts/adjust-font.eot');
	src:url('../fonts/adjust-font.eot?#iefix') format('embedded-opentype'),
		url('../fonts/adjust-font.woff') format('woff'),
		url('../fonts/adjust-font.ttf') format('truetype'),
		url('../fonts/adjust-font.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Malbeck';
	src: url('../fonts/Malbeck-Regular.eot');
	src: url('../fonts/Malbeck-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Malbeck-Regular.woff') format('woff'),
		url('../fonts/Malbeck-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

body {
	font-feature-settings: 'lnum';
	-moz-font-feature-settings: 'lnum=1';
	-ms-font-feature-settings: 'lnum';
	-webkit-font-feature-settings: 'lnum';
	-o-font-feature-settings: 'lnum';
}