
/* -------------------------------- 

	Primary style

-------------------------------- */

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.breakdown {
	display: none;
	-webkit-transform: translateX(1000vw);
	-ms-transform: translateX(1000vw);
	transform: translateX(1000vw);
	width: 100vw;
	height: 100vh;
	position: fixed;
	background: #2d2d2d;

	img {
			display: block;
			margin: 0 auto;
	}

	@media screen and (max-width: 700px) and (orientation: landscape) {
			display: block;
			-webkit-transform: translateX(0);
			-ms-transform: translateX(0);
			transform: translateX(0);
			z-index: 99999999999;
	}
}

.clearfix {
  clear: both !important;
}

a, .button {
	cursor: pointer;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;  
	font-family: 'Raleway', sans-serif;

	&.is-open {
		@media screen and (max-width: 700px) {
			position: fixed;
		}
		
	}

	a.adjust {
		font-family: 'adjust';
		color: $white;
	}
}

p {
	font-weight: 500;
}

embed {
	width: 100% !important;
	height: 400px !important;
}

.no-border {
	display: block;
	border: none;
}

.inner.articles blockquote {
	border-left: none;
}

blockquote p {
	margin-bottom: 0;
	font-style: italic;
}
blockquote .author {
	font-style: normal;
	font-size: .9em;
	display: block;

	&::before {
		content: "−";
		padding-left: 1rem;
		padding-right: .5rem;
	}
}

video {
	display: block;
	width: 80%;
	margin: 3rem auto;
}

div.linear-back {
	position: fixed;
	width: 100%;
	height: 100%; 
	content: '';
	background-size: 100% auto !important;
	top: 0; 
	left: 0;
}

.back-row {
	display: block;
	width: 100%;

	.back {
		display: inline-block;
		padding: 4px 8px;
		background: rgba(196, 196, 196, 0.4);
		font-weight: bold;
	}
}
.back {
	
	color: $red;
	font-size: 0.9em;
	text-transform: uppercase;
	margin-bottom: 10px;
	display: block;
	margin-left: 10px;
	
	&.inpage {
		margin-left: 0;
	}
	
	i {
		margin:0 0.5em;
		transition: 0.4s;
		margin-left: 0;
	}
	
	h2 {
		color: $red;
		font-size: 0.9em;
		i {
			margin:0 0.5em;
			transition: 0.4s;
			margin-left: 0;
		}
	}
	
	&:hover {
		h2 i, i {
			margin-right: 1em;
		}
	}
	
	&.blog {
		position: relative;
		color: white;
		h2 {
			color: white;
			text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
			i {
				margin-left: 0.5em;
			}
		}
	}
}

.discover {
	border: 1px solid $black;
	border-radius: 50px;
	display: inline-block;
	padding: 8px 20px;
	font-weight: 600;
	position: relative;
	transition: $transition;
	font-weight: 500;

	&:before {
		  position: absolute;
		  content: '';
		  left: 0;
		  top: 0;
		  height: 100%;
		  width: 100%;
		  background: linear-gradient(to right, #a2130b 0%,#e40215 50%,#a2130b 100%);
		  z-index: -1;
		  border-radius: 50px;
		  opacity: 0;
		  transition: $transition;
	}

	&:hover {
		color: $white;
		border: 1px solid rgba($black,0);

		&:before {
			opacity: 1;
		}
	}
}


/* -------------------------------- 

	Main components 

-------------------------------- */

a.cta {
	color: $white;
	text-transform: uppercase;
	background: linear-gradient(to right, #fff 50%, transparent), linear-gradient(to right, #a2130b 0%,#e40215 50%,#a2130b 100%);
	background-position: 200% 0, 50%;
	background-size: 200% 100%, 100% 100%;
	background-repeat: no-repeat, repeat;
	padding: 10px 20px;
	border-radius: 50px;
	text-decoration: none;
	font-size: .9em;
	font-weight: 500;
	border: 1px solid #FFF;
	transition: .3s;
	position: relative;
	overflow: hidden;
	&:hover {
		color: $red;
		background-position: 0 0, 50%;
		
		@at-root {
			.menu & {
				color: white;
			}
		}
	}

	&.white {
		color: $red;
		background-position: 0 0, 50%;
	}
	&.white:hover {
		background-position: 200% 0, 50%;
		color: white;
	}
}

header {
	position: absolute;
	z-index: 1;

	a.logo {
		text-align: center;
	}

	ul.language {
		display: inline;
		position: absolute;
		left: 230px;
		top: 50%;
		transform: translateY(-75%);
		
		li {
			display: inline-block;
			padding: 0.3em;
			
			a {
				color: #FFFFFF;
				text-decoration: none;
				opacity: 0;
				transition: .4s;

				&:hover {
					text-decoration: underline;
				}
				
				&.active {
					opacity: 1;
				}
				
				&:hover {
					color: #a2130b;				
				}
			}
		}

		&:hover li a {
			opacity: 1;
		}	
	}
}

.error-warning {
	animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}

@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}
	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}
	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}
	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}
}

.thanks {
	display: block;
	background: rgba(#000, 0.6);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;

	.content {
		background: white;
		padding: 40px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-size: 1.2em;
		border-top: 10px solid $red;

		a.close {
			position: absolute;
			right: 2px;
			bottom: 2px;
			cursor: pointer;
			font-size: .8em; 
			color: black;
			line-height: 0.5em;
			padding: 10px;
			opacity: .7;
		}
	}
}

// SPECIAL SECTION - SLIDER
 
section.slider {
	height: 100vh;
	width: 100%;
	// background: url('../img/Maredsous-slider-01-compressor.jpg');
	background-size: cover !important;
	background-position: 50% 50% !important;
	position: relative;
	overflow: hidden;

	div.scroll a {
		position: absolute;
		height: 45px;
		width: 45px;
		border: 2px solid $white;
		border-radius: 50px;
		left: 0;
		right: 0;
		margin: 0 auto;
		bottom: 20px;
		background: url('../img/arrow-pointing-to-down.svg') 50% 50%;
		background-size: cover;
	}

	@keyframes fadein {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	div.title {
		color: $white;
		margin: 0 auto;
		transform: translateY(-50%);
		background: radial-gradient(ellipse at center, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 75%);
		padding: 40px;
		//pointer-events:none; /* why */
		opacity: 0;

		@media screen and (max-width: 670px) {
			width: 100%;
		}

		&.animation-3 {
			opacity: 1;
			animation-name: fadein;
			animation-timing-function: ease;
			animation-duration: 1s;
			animation-delay: 2s;
			animation-fill-mode: backwards;

			@media screen and (max-width: 670px) {
				animation-delay: .5s;
			}
		}	

		h1 {
			font-family: 'Malbeck';
			font-size: 4em;
			font-weight: 100;
			margin: 0;
		}

		h2 {

			font-family: 'Malbeck';
			font-size: 2em;
			font-weight: 100;
			margin: 0;
			margin-bottom: 20px;
			margin-top: -20px
		}

		p {
			margin-top: 0;
			margin-bottom: 30px;
		}

		span.special {
			font-family: 'Montserrat', sans-serif;
			position: absolute;
			opacity: .5;
			font-size: 25em;
			font-weight: 700;
			z-index: -1;

			&:first-of-type {
				top: -130px;
				left: -50px;
			}
			&:last-of-type {
				bottom: -230px;
				right: -60px;
			}
		}

		a {
			pointer-events:auto;
			position: relative;
			left: 0;
			right: 0;
			margin: 0 auto;
			text-align: center;
			width: 150px;
			display: block;
		}
	}
}

// NO TEMPLATE - #WeAreFamily

section.wearefamily {
	background: #f0f0f3;
	overflow: hidden;
	text-align: center;
	color: rgba($black, .8);

	h2 {
		font-weight: 300;
		font-size: 3em;
		margin-top: 45px;
		margin-bottom: 20px;
	}

	p {
		width: 65%;
		margin: 0 auto;
		margin-bottom: 50px;
		line-height: 25px;
	}

	a.play {
		position: relative;
		top: -25px;

		img {
			height: 50px;
			width: 52px;
			opacity: .8;
		}
	}
}

// NO-TEMPLATE - New Cheese

section.newcheese {
	background: #f0f0f3;
	overflow: hidden;
	text-align: left;
	color: rgba($black, .8);
	padding: 36px 0 30px;

	.row {
		max-width:1300px;
		margin:0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media screen and (max-width:900px) {
			justify-content: center;
		}

		@media screen and (max-width:640px) {
			flex-direction: column;
		}

		.text-block {
			padding: 0 0 26px;
			width:40%;
			white-space: nowrap;

			@media screen and (max-width:1040px){
				padding:0 40px 26px 20px;
				width:auto;
			}
			@media screen and (max-width:640px){
				padding:25px 50px;
				width:100%;
			}
			@media screen and (max-width:550px){
				padding:25px;
			}
			@media screen and (max-width:380px){
				white-space: normal;
			}
			h2{
				text-transform: uppercase;
				font-size:4rem;
				margin:0;
				@media screen and (max-width:380px){
					font-size:3.4rem;
				}
			}
			p{
				line-height:1.4rem;
				margin-bottom: 36px;
			}
			a.cta{
				border:none;
			}
		}
		img{
			margin:0;
			padding:0;
			min-width: 0;
			min-height: 0;
			width:40%;
			height:auto;
			@media screen and (max-width:1000px){
				width:40%;
				padding:0 20px;
			}
			@media screen and (max-width:640px){
				width:70%;
			}
			@media screen and (max-width:380px){
				width:80%;
			}
			&.diapos{
				width:30%;
				margin-right:40px;
				@media screen and (max-width:1000px){
					display:none;
				}
			}
		}
	}
}

// TEMPLATE - MOSAIC

section.mosaic {

	&.mosaic_home {

		div.mosaic_block {

			div.mosaic_background {
				height: 100% !important;
			}

			div.mosaic_content {
				position: absolute;
				background: none;
				bottom: 0px;
				top: initial;
				height: auto;
				padding: 0;
			}

		}


		.discover {
			border: 2px solid $white;
			border-radius: 50px;
			display: inline-block;
			padding: 8px 20px;
			font-weight: 600;
			position: relative;
			transition: $transition;
			color: $white;
			z-index: 2;
			position: relative;
			top: 75%;

			@media screen and (max-width: 1000px) {
				font-size: 1.3em;
			}

			&:before {
				  position: absolute;
				  content: '';
				  left: 0;
				  top: 0;
				  height: 100%;
				  width: 100%;
				  background: linear-gradient(to right, #a2130b 0%,#e40215 50%,#a2130b 100%);
				  z-index: -1;
				  border-radius: 50px;
				  opacity: 0;
				  transition: $transition;
				  z-index: -1; 
			}


			&:hover {
				
				color: $white;

				&:before {
					opacity: 1;
				}
			}
		}

	}

	&.mosaic_blog {
		padding-top: 200px;
		background-size: cover;

			div.linear-back {
				position: fixed;
				width: 100%;
				height: 100%; 
				content: '';
				background-size: 100% auto !important;
				top: 0; 
				left: 0;
			}

		div.mosaic_block {
			
			.debug_id {
				position: absolute;
				top: 1em;
				right: 1em;
				
				background: white;
				padding: 0.5em;
				
				z-index: 30;
				
				a {
					color: black;
					&:hover {
						text-decoration: underline;
					}
				}
			}

			box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
			height: auto !important;

			.goto {
				position: absolute;
				right: 18px;
				bottom: 10px;
				width: 20px;
				opacity: .5;
				transition: .3s ease;
			}

			&:hover {

				.goto {
					right: 12px;
				}
			}




			// &:hover {
			// 	div.mosaic_content {
			// 		transform: translateY(-60px);
			// 		transition-delay: .1s;
			// 	}

			// 	div.link-holder {
			// 		transform: translateY(0);
			// 	}
			// }

			&.articles {
				div.mosaic_content {
					border-top: 10px solid $red;
					//box-shadow: 0px -10px 60px 20px rgba(255, 255, 255, 0.75);

					h3 {
						color: $red;
					}
				}

				div.link-holder {
					background: $red;
				}

				
			}

			&.recettes {
				div.mosaic_content {
					border-top: 10px solid $yellow;
					//box-shadow: 0px -10px 60px 20px rgba(10, 10, 10, 0.75);

					h3 {
						color: $yellow;
					}
				}

				div.link-holder {
					background: $yellow;
				}
			}

			&.tips {
				div.mosaic_content {
					border-top: 10px solid $greyer;
					//box-shadow: 0px -10px 60px 20px rgba(10, 10, 10, 0.75);

					h3 {
						color: $greyer;
					}
				}

				div.link-holder {
					background: $greyer;
				}
			}

			&.videos {
				div.mosaic_content {
					border-top: 10px solid $grey;
					//box-shadow: 0px -10px 60px 20px rgba(255, 255, 255, 0.75);

					h3 {
						color: $grey;
					}
				}

				div.link-holder {
					background: $grey;
				}
			}

			div.mosaic_content {
				height: 140px;
				padding: 10px 20px;
				
				bottom: 0;
				top: initial;
				color: $black;
				text-align: left;
				transition: $transition;

				position: relative;

				
				//@media screen and (max-width: 960px) {
				//	height: auto;
				//}

				h3 {
					margin-top: 0;
					text-transform: uppercase;
			// 		font-family: 'Rochester', cursive;
					// font-family: 'Niconne', cursive;
					// font-size: 1.8em;
					// line-height: 1em;
					margin-bottom: 8px;
					text-align: left;

				}

				p {
					font-weight: 600;
					margin: 0;
					font-weight: 500;
					font-size: 1.1em;
					max-width: 450px;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					color: rgba($black, .8);
					text-align: left;

				}

				a {
					text-align: center;
				}
			}

			div.link-holder {
				background: red;
				position: absolute;
				width: 100%;
				height: 60px;
				bottom: 0;
				left: 0;
				text-align: center;
				transform: translateY(60px);
				transition: $transition;

				a {
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					border: 2px solid $white;
					border-radius: 50px;
					width: 65px;
					margin: 0 auto;
					padding: 7px 0px;


					img {
						width: 13px;
						position: relative;
						top: 1px;
						transition: $transition;
					}
				}
			}
		}
	}

	width: 100%;
	padding-top: 60px;
	padding-bottom: 60px;
	overflow: hidden;

	div.container {
		max-width: 960px; 
		margin: 0 auto;
		overflow: hidden;

		@media screen and (max-width: 670px) {
			padding-top: 20px;
		}

	}

	div.mosaic_block {
		width: calc((100% / 3) - 20px);
		float: left;
		margin: 10px;
		position: relative;
		overflow: hidden;
		transition: $transition;

		@media screen and (max-width: 1000px) {
			width: calc((100% / 2) - 20px);
		}

		


		&:hover {

			// div.mosaic_background {
			// 	opacity: .8;
			// 	transform: scale(1.05);
			// }

			transform: scale(0.98);

			.goto {
				right: 12px;
			}
		}

		div.mosaic_background {
			width: 100%;
			height: 100%;
			background-size: cover !important;
			background-position: 50% 50% !important;
			top: 0;
			left: 0;
			background: $red;
			transition: $transition;

			position: relative;
			height: 210px;
		}

		div.mosaic_content {
			position: absolute;
			top: 0;
			left: 0;
			text-align: center;
			width: 100%;
			height: 100%;
			padding: 10px;

			position: relative;
			padding: 25px;
			float: left;
			height: 140px;
			width: 100%;

			background: $white;


				&:hover {
					img {
						// margin-left: 10px;
					}
				}

			button {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 30px;
				border: 2px solid $white;
				border-radius: 50px;
				width: 65px;
				margin: 0 auto;
				padding: 7px 0px;
				background: transparent;


				img {
					width: 13px;
					position: relative;
					top: 1px;
					transition: $transition;
				}
			}

			a.cta {
				position: absolute;
				bottom: 20px;
				font-weight: 500;
				margin: 0 auto;
				left: 0;
				right: 0;
				width: 80px;
				transition: $transition;
				border: 1px solid $white;
				background: none;

				&:hover {
					&:before {
						opacity: 1;
					}
				}

				&:before {
				  position: absolute;
				  content: '';
				  left: 0;
				  top: 0;
				  height: 100%;
				  width: 100%;
				  background: linear-gradient(to right, #a2130b 0%,#e40215 50%,#a2130b 100%);
				  z-index: -1;
				  border-radius: 50px;
				  opacity: 0;
				  transition: $transition;
				}
			}
		}
	}
}

// NO TEMPLATE - #APP

section.app {
	position: relative;
	background: url('../img/easydinner.jpg');
	background-size: cover;
	background-position: 0% 50%;
	overflow: hidden;
	width: 100%;
	height: 180px;

	img {
		width: 100%;
	}

	div.app_download {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		margin-left: 210px;

		@media screen and (min-width: 1620px) {
			margin-left: 310px;
		}

		h3 {
			text-transform: uppercase;
			color: $white;
			font-size: 1em;
			margin: 0;
			margin-bottom: 20px;
			position: relative;

			&:after {
				content:'';
				position: absolute;
				right: -25px;
				bottom: -20px;
				width: 30px;
				height: 30px;
				background: url('../img/arrow-red.png');
				background-size: cover;


			}
		}

		img {
			width: 140px;
			display: block;
			margin-top: 10px;
		}
	}
}

// NO TEMPLATE - #CHEESELETTER

section.front-banner,
section.cheeseletter,
section.cheeseletter_old{
	background: #f0f0f3;
	overflow: hidden;
	padding: 50px;
	color: rgba($black, .8);
	position: relative;

	>div:not(.cta-form){
		max-width:1200px;
		position: relative;
		margin:0 auto;
	}

	p {
		margin: 0;
	}

	h3 {
		text-transform: uppercase;
		font-weight: 500;
		margin: 0;
		margin-top: 5px;
		font-size: 1.5em;

		b {
			color: $black;
		}
	}


	form,
	a[data-call="cheeseletter-subscribe"] {
		position: absolute;
		right: 0;
		bottom: 0;
	}
	@media screen and (max-width: 1100px) {
		form,
		a[data-call="cheeseletter-subscribe"] {
			position: relative;
			bottom: initial;
			right: initial;
			margin-top: 30px;
		}
		a[data-call="cheeseletter-subscribe"] {
			display: inline-block;
		}
	}

	input {
		border: none;
		margin: 0;
		padding: 0;
		font-weight: 500;

	}

	input[type=text] {
		padding: 10px 20px;
		border-radius: 50px 0px 0px 50px;
		font-size: .9em;
		width: 300px;
		position: relative;
		right: -5px;
		
		&:focus {
			border-bottom: none !important;
			box-shadow: none !important;
		}
		
		
		@include placeholder {
			color: #171717 !important;
		}	

		background: $white;
		outline: initial;
		height: auto;
		box-shadow: initial;
		box-sizing: inherit;
		color: $black;
		
		@media screen and (max-width: 745px) {
			width: 100%;
			border-radius: 50px;
		}
	}	

	input[type=submit] {
		background: linear-gradient(to right, #a2130b 0%,#e40215 50%,#a2130b 100%);
		padding: 10px 20px;
		border-radius: 0px 50px 50px 0px;
		color: $white;
		font-size: .9em;
		
		@media screen and (max-width: 745px) {
			border-radius: 50px;
			width: 103%;
			margin-top: 1em;
		}
	}
}

section.cheeseletter a[data-call="cheeseletter-subscribe"] {
	position: relative;
}


// BLOG INNER / CONTENT

section.inner { 

	width: 100%;
	background-size: cover !important;
	background-position: 50% 0% !important;
	padding-top: 200px;
	padding-bottom: 60px; 

	&.articles {

	&.Recettes {
		div.container {
			border-top: 10px solid $yellow;
			
			h2, h3 {
				color: $yellow;

				a i {
					color: $yellow;
				}
			}
		}
	}

	&.Videos {
		div.container {
			border-top: 10px solid $grey;
			
			h2, h3 {
				color: $grey;

				a i {
					color: $grey;
				}
			}
		}
	}

	&.Tips {
		div.container {
			border-top: 10px solid $greyer;
			
			h2, h3 {
				color: $greyer;

				a i {
					color: $greyer;
				}
			}
		}
	}

		div.container {
			border-top: 10px solid $red;

			ul:first-of-type {
				list-style-type:disc;
				padding-left: 24px;

				li {
					list-style-type: inherit;
				}
			}

			ul:last-of-type, ol {
				
				padding-left: 20px;

				li {
					list-style-type:decimal;
					line-height: 25px;
					//color: white;
					color: inherit;
					position: relative;
					z-index: 2;
					padding-left: 5px;
					font-weight: 500;
					font-size: 14px;
										
					&:before {
						content: '';
						position: absolute;
						width: 20px;
						height: 20px;
						// border-radius: 20px;
						background: $red;
						top: 3px;
						left: -20px;
						z-index: -1;
					}

					span {
						color: $black;
						font-weight: 400;
						font-size: 16px;
						
					}

					&:first-letter {

					}
				}
			}

			ul {
				li {
					line-height: 20px;
				}
			}
			
			h2 {
				color: $red;

				a i {
					color: $red;
				}
			}

			p {
				span {
						color: $red;
						font-style: italic;
						text-align: center;
						display: block;
					}
			}

			h3 {
				color: $red;
			}

			p.breadcrumb {
					
					color: rgba($black, .8);
					margin-bottom: 20px;
					margin-top: -10px;
					font-size: .9em;
					opacity: .9;
					
					margin-left: 10px; 

					a {
						color: inherit;

						&.active {
							color: $red;
						}
					}
				}
		}
	}

	&.recettes {
		div.container {
			border-top: 10px solid $yellow;
			
			h2 {
				color: $yellow;

				a i {
					color: $yellow;
				}
			}
		}
	}

	&.videos {
		div.container {
			border-top: 10px solid $grey;
			
			h2 {
				color: $grey;

				a i {
					color: $grey;
				}
			}
		}
	}

	&.tips {
		div.container {
			border-top: 10px solid $greyer;
			
			h2 {
				color: $greyer;

				a i {
					color: $greyer;
				}
			}
		}
	}
	
	div.container {
		max-width: 960px; 
		margin: 0 auto;
		background: $white;
		padding: 40px 50px;
		position: relative;
		border-top: 10px solid red;
		box-shadow: 0px 10px 80px 0px rgba(0,0,0,0.4);
		color: rgba($black, .9);

		img.img-article {
			width: 400px; 
			float: right;
			padding-left: 10px;
		}
		
		.social {
			position: absolute;
			right: 1em;
			//right: 50px;
			bottom: 1em;
			
		}
		
		&:after {
		  content: "";
		  display: table;
		  clear: both;
		}

		h2 { 
			// position: absolute;
			// top: -65px;
			// left: 18px;
			margin-top: 0;
			text-transform: uppercase;
			transition: $transition;
			margin-top: -15px;

			&:hover {
				margin-left: -5px;
			}

			i {
				margin-right: 10px;
			}

			a {
				margin-right: 10px;
				position: relative;
				transition: $transition;
				left: 0;

				&:hover {
					left: -5px;
				}
			}
		}

		h1 {
			margin-top: 0;
			font-size: 1.4em;
			margin-bottom: 5px;
		}

		span.timing {
			opacity: .6;
			font-style: italic;
			font-weight: 500;
		}

		p.subline {
			font-weight: 700;
		}

		p {
			line-height: 1.4em;
		}
	}
}

// CTA BLOCK 

div.cta-block {

	@keyframes popup {
		from {
			transform: translateX(100%);
		}

		to {
			transform: translateX(0%);
		}
	} 

	&.nope {
		div.container {
			right: -100%;
			transition: 1s ease;
		}
	}

	div.container {
		position: fixed;
		z-index: 2;
		background: $white;
		top: 100px;
		right: 0;
		text-align: center;
		box-shadow: 0px 0px 80px 0px rgba(0,0,0,0.4);
		transform: translateX(100%);
		transition: $transition;
		max-width: 320px;

		div.form-container {
			max-height: 70vh;
			overflow: scroll;
			padding: 40px;
		}
		
		&.show {
			animation-name: popup;
			animation-timing-function: ease;
			animation-duration: 1s;
			animation-delay: 3s;
			animation-fill-mode: forwards;
		}
		
		&.moved {
			transform: translateX(0);
		}
		
		a.stored {
			position: absolute;
			top: 0;
			left: 0;
			transform: translateX(-100%);
			
			$size: 40px;
			width: $size;
			height: $size;
			
			cursor: pointer;
			background: $red;
			
			background-size: 60%;
			background-repeat: no-repeat;
			background-position: center center;
			background-image: url('../img/cheese.svg');
			
			display: none;
		}

		&.stored a.stored {
			display: block;
		}

		// position: absolute;
		// padding: 40px;
		// z-index: 2;
		// background: $white;
		// bottom: 100px;
		// right: 0;
		// left: 0;
		// top: 50%;
		// transform: translateY(-50%);
		// width: 350px;
		// text-align: center;
		// box-shadow: 0px 0px 80px 0px rgba(0,0,0,0.4);
	}

	h2 {
		margin-top: 0;
		font-size: 1.5em;
		text-transform: uppercase;
		font-weight: 500;
		font-weight: bolder;
	}

	a.close {
		position: absolute;
		right: 10px;
		top: 6px;
		color: $red;
		font-size: 1.2em;

		span {
			font-size: .6em;
			bottom:1px;
			position: relative;
		}
	}

	input {
		border: none;
		margin: 0;
		padding: 0;
		font-weight: 500;
	}

	::-webkit-input-placeholder {
		color: $black;
	}
	::-moz-input-placeholder {
		color: $black;
	}
	::-ms-input-placeholder {
		color: $black;
	}


	input[type=text] {
		padding: 10px 20px;
		border-radius: 50px;
		font-size: .9em;
		width: 100%;;
		position: relative;
		background: rgba($greyer, .3);
		display: block;
		outline: initial;
		height: auto;
		box-shadow: initial;
		box-sizing: inherit;
		color: $black;
		margin-bottom: .25rem;
	}

	input[type=submit] {
		background: linear-gradient(to right, #a2130b 0%,#e40215 50%,#a2130b 100%);
		padding: 10px 20px;
		border-radius: 50px;
		width: 100%;
		color: $white;
		font-size: .9em;
		margin-top: 15px;
	}

	form label {
		text-align: left;
		margin: .5rem 0 0;
		display: inline-block;
	}

	label + p {
		font-size: .8rem;
		color: #888;
	}

	label span {
		text-align: left;
		line-height: 1.34;
	}

	label input[type="checkbox"] {
		position: static;
		opacity: 1;
	}
}


//FONT BEFORE COLOR CHANGE 

section.inner.articles.Recettes div.container ul:last-of-type li:before {
	background: $yellow;
}

section.inner.articles.Videos div.container ul:last-of-type li:before {
	background: $grey;
}

section.inner.articles.Tips div.container ul:last-of-type li:before {
	background: $greyer;
}


// CHEESELTER ECOUPON SPECIAL 

section.front-banner,
section.cheeseletter {
	background: linear-gradient(90deg,#a2130b 0,#e40215 50%,#a2130b);
	padding-bottom: 60px;
	padding: 25px 100px 50px;
	
	span {
		color: white;
		text-transform: uppercase;
		font-size: 110px;
		font-weight: 800;
		position: relative;

		@media screen and (max-width: 1280px) {
			font-size: 80px;
		}
		
		// &:after {
		//     content:"";
		//     position: absolute;
		//     background: url("/sites/all/themes/maredsousfromages/dist/img/black-arrows.svg");
		//     background-size: cover;
		//     background-repeat: no-repeat;
		//     height: 140px;
		//     width: 200px;
		//     right: -230px;
		//     z-index: 9;
		// }
	}
	
	h3, p {
		color: white;
		margin-left: 8px;
		
		b {
			color: white;
		}
	}
	
	p {
		margin-bottom: 35px;
		font-size: 14px;
	}
	
	h3 {
		text-transform: initial;
		font-size: 20px;
		margin: initial;
		margin-bottom: 10px;
		margin-left: 8px;

		@media screen and (max-width: 1280px) {
			font-size: 18px;
		}
	}
	
	img {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		height: 120%;
		right: 100px;

		@media screen and (max-width: 1280px) {
			height: 100%;
		}

	}
	
	&.sos {
	
		background: #f24d67;
		text-align: center;
		padding: 2em;
		
		border-bottom: 60px solid white;
	
		p {
			font-size: 1.2em;
			margin: 0.8em;
			margin-bottom: 1.6em;
		}
		
		a.cta {
			
			background: transparent;
			color: white;
			text-transform: none;
			transition: 0.4s;
			
			&:hover {
				background: white;
				color: #f24d67;
			}
		}
			
		img {
			position: static;
			height: auto;
			width: 250px;
			transform: none;
		}
	}
}

// VOUCHER FIX
section.voucher{
	padding:25px 50px 50px;
	> div:not(.cta-form){
		margin:0 auto;
		max-width:1200px;
		position:relative;
	}
	h3,p,a{
		margin-left:5px;
	}
	@media screen and (max-width:550px){
		padding:15px 25px 40px!important;
		span{
			font-size:4rem;
		}
	}	
	img{
		right:0;
		top:56%;
		height:150%;
		@media screen and (max-width:1280px){
			height:160%;
		}
		@media screen and (max-width:940px){
			height:140%;
		}
		@media screen and (max-width:900px){
			display:none;
		}
	}
}

/* Cheeseletter box */
#cheeseletter-box {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: rgba(black, .6);
	z-index: 9999;
	
	display: none;


	
	div.cta-form-holder {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		margin: 0 auto;
		max-width: 750px;
		padding: 100px 50px;
		background: white;
		border-radius: 2px;
		background: linear-gradient(90deg,#a2130b,#e40215 50%,#a2130b);
		text-align: center;

		@media screen and (max-width: 745px) {
			padding: 50px 50px 20px;
		}
		
		h3 {
			margin-bottom: 20px;
			margin-left: 0;
			margin-top: 0;
			text-transform: initial;
			font-size: 20px;
			color: white;
			font-weight: 500;

			@media screen and (max-width: 1280px) {
				font-size: 18px;
			}
			@media screen and (max-width: 745px) {
				font-size: 15px;
				margin-bottom: 10px;
			}
		}
		
		form {
			position: initial;
			bottom: initial;
			right: initial;
			text-align: center;

			input:not([type="checkbox"]) {
				box-shadow: none;
				padding: 10px 20px;
				font-size: .9em;
				width: 300px;
				border: none;
				outline: none;
		
				@media screen and (max-width: 745px) {
					width: 100%;
					border-radius: 50px;
					padding: 6px 20px;
					font-size: .8em;
				}
			}
			
			.input-radius {
				border-radius: 50px;
				margin: 20px 10px;
				
				@media screen and (max-width: 745px) {
					margin-top: 0;
					margin-bottom: .5rem;
					margin-left: 0;
				}
			}

			input.fullradius {
				width: 96%;
				border-radius: 50px;
				
				@media screen and (max-width: 745px) {
					width: 100%;
				}
			}

			input.fullsubmit {
				border-radius: 50px;
				margin-top: 30px;
				width: 200px;
				font-size: 20px;
				font-weight: 600;
				cursor: pointer;
				transition: .3s ease;
				background-image: linear-gradient(left, #a2130b, #e40215 50%, #a2130b);
				color: white;
				outline: none;
				border: none;
				box-shadow: 0 0 20px 0 rgba(0,0,0,.25);

				&:hover {
					opacity: .8;
				}

				@media screen and (max-width: 745px) {
					margin-top: .5em;
					margin-left: auto;
					margin-right: auto;
					font-size: 15px;
				}
			}
		}

		p {
			font-size: .7rem;
			color: white;
			padding: 0 1rem;

			@media screen and (max-width: 745px) {
				margin: .25rem 0 .25rem -2rem;
				padding: 0;
				width: calc(100% + 4rem);
			}

			a {color: white; text-decoration: underline;}
		}

		label {
			display: block;
			width: 100%;
			color: white;
			position: relative;
			margin-top: 1rem;
			padding: 1rem;

			@media screen and (max-width: 745px) {
				margin-top: 10px;
				margin-bottom: .5rem;
			}

			& + label {
				margin-top: 0;
			}

			span {
				display: inline-block;
				padding-left: 2rem;
				text-align: left;
				width: 100%;

				a {color: white; text-decoration: underline;}
			}

			input {
				display: inline-block;
				opacity: 1;
				width: 16px;
				height: 16px;
				position: absolute;
				left: 1rem;
				top: 1rem;
				@media screen and (max-width: 745px) {
					top: 0;
				}
			}


			@media screen and (max-width: 745px) {
				padding: 0;
				width: calc(100% + 4rem);
				margin-left: -2rem;
				font-size: .65rem;
				line-height: 1.3;

				span {
					padding-left: 1.5rem;
				}
				
				input {
					left: 0;
				}
			}
		}

		a.close-cta-form {
			position: absolute;
			top: 20px;
			left: 20px;
			color: white;
			font-size: 16px;
			font-weight: 500;
			cursor: pointer;
			text-transform: uppercase;

			@media screen and (max-width: 745px) {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}


/*  Cookie popin  */
	.cookie-popin {
		width: 100%;
		padding: 2rem;
		position: fixed;
		bottom: 0;
		left: 0;
		background: linear-gradient(to right, #a2130b, #e40215, #a2130b);
		transition: .3s;
		z-index: 100;

		&.close {
			transform: translateY(100%);
		}

		.container {
			width: 100%;
			max-width: 71.875rem;
			margin: auto;
			color: white;
			&::after {
				content: "";
				display: table;
				clear: both;
			}
		}

		p {
			width: 80%;
			padding-right: 2rem;
			float: left;
			margin-bottom: 0;
			margin-top: 0;
			line-height: 1.4;

			a {
				color: #fff4f1;
				text-decoration: underline;
			}
		}

		p + a {
			width: 20%;
			float: right;
			color: #e40215;
			background: white;
			padding: .75rem 1rem;
			text-transform: uppercase;
			text-align: center;
			font-weight: 500;
		}

		@media screen and (max-width: 768px) {
			text-align: center;

			p {
				padding-right: 0; 
				padding-bottom: 1rem;
			}

			.container > * {
				float: none;
				margin-bottom: 1rem;
				width: 100%;
			}
			p + a {
				max-width: 420px;
			}
		}

		@media screen and (max-width: 420px) {
			p {
				font-size: .85rem;
			}
		}
	}