header {
	position: fixed;
	width: 100%;
	padding: 40px;
	// overflow: hidden;
	top: 0;
	left: 0;
  z-index: 12;

	background: linear-gradient(0deg,transparent 0%,hsla(0,0%,8%,.6));

	a.logo {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 200px;
		top: 20px;

		img {
			width: 100%;
		}
	}

	a.lang {
		color: $white;
		text-decoration: none;
		position: relative;
		top: 6px;
		font-weight: 500;
		margin-left: 150px;
	}

  a.home {

      color: $white;
      text-decoration: none;
      padding: 10px 19px;
      border: 1px solid $white;
      border-radius: 50px;
      position: relative;
      width: 10px;
      height: 10px;
      font-weight: 500;
      transition: $transition;
      bottom: 0;
      position: relative;
      top: 6px;
      z-index: 15;
	  
	  body.is-open & {
		  //position: fixed;
	  }

      &:hover {

        bottom: -3px;
        &:before {
          opacity: 1;
        }
      }

      &.home {

        // border: 1px solid rgba($red, .8);
        // &:before {
        //   opacity: 1;
        // }
      }

        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(to right, #a2130b 0%,#e40215 50%,#a2130b 100%);
          z-index: -1;
          border-radius: 50px;
          opacity: 0;
          transition: $transition;
        }

      &.inspiration {
        text-transform: uppercase;
        font-size: .9em;
        position: relative;
        overflow: hidden;

        i {
          position: initial;
          top: initial;
          transform: none;
          margin-right: 5px;
        }
      }

      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
      }
    }
  
}

// MINI NAV

header ul:not(.language) {

    @media screen and (max-width: 1000px) {

      li:nth-child(2), li:nth-child(3), li:nth-child(4) {
        display: none;
      }

    }

}

body.is-open {

  header ul {

    

      @media screen and (max-width: 1000px) {

        z-index: 15;

        li:nth-child(1) {
          display: none;
        }

        li:nth-child(2), li:nth-child(3), li:nth-child(4) {
          display: inline-block;
          
          
        }

      }
  }
}

ul.mini-nav {
	list-style: none;
	margin: 0 auto;
	padding: 0;
	float: right;
    position: relative;
    top: 6px;




	li {
		display: inline-block;
		padding-left: 10px;

		a {
			color: $white;
			text-decoration: none;
			padding: 10px 19px;
			border: 1px solid $white;
			border-radius: 50px;
			position: relative;
			width: 10px;
			height: 10px;
			font-weight: 500;
      transition: $transition;
      bottom: 0;

      &:hover {

        bottom: -3px;
        &:before {
          opacity: 1;
        }
      }

      &.home {

        border: 1px solid rgba($red, .8);
        &:before {
          opacity: 1;
        }
      }

        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: linear-gradient(to right, #a2130b 0%,#e40215 50%,#a2130b 100%);
          z-index: -1;
          border-radius: 50px;
          opacity: 0;
          transition: $transition;
        }

			&.inspiration {
				text-transform: uppercase;
				font-size: .9em;
        position: relative;
        overflow: hidden;

				i {
					position: initial;
					top: initial;
					transform: none;
					margin-right: 5px;
				}
			}

			i {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				right: 0;
				margin: 0 auto;
				text-align: center;
			}

		}
	}
}

// BURGER MENU

div.burger-holder { 
	border: 1px solid $white;
  	border-radius: 50px;
  	overflow: hidden;
  	float: left;
  	position: absolute;
  	top: 35px;
  	z-index: 15;
    left: 96px;
	overflow: hidden;

    body.is-open & {
      position: fixed;
    }
}

.burger-menu {
  position: relative;
  width: 118px;
  height: 38px;
  // margin: 0 auto;
  color: $white;
  text-transform: initial;
  // top: 40px;
  // left: 40px;
  float: left;
  //padding: 7px 15px;
  transition: .3s ease .12s;
  
  &.is-open {
	width: 120px;
	transition: .3s ease;
  }

  &:hover {


        &:before {
          opacity: 1;
        }
      }

        &:before {
          position: absolute;
          content: '';
          left: -20px;
          top: -20px;
          height: 200%;
          width: 200%;
          background: linear-gradient(to right, #a2130b 0%,#e40215 50%,#a2130b 100%);
          z-index: -1;
          border-radius: 50px;
          opacity: 0;
          transition: $transition;
        }

}

.burger-click-region {
  padding: 11px 19px;
  position: absolute;
  // left: 15px;
  // top: 7px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  z-index: 1;
}

.burger-menu-txt {
  display: block;
  position: absolute;
  left: 54px;
  top: 10px;
  cursor: default;
  transition: opacity 300ms ease-out, transform 300ms cubic-bezier(.34, .55, .25, .83);
  font-size: 1em;
  font-weight: 500;

  .is-open & {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }
}

.burger-menu-txt-2 {
  display: block;
  position: absolute;
  left: 54px;
  top: 10px;
  cursor: default;
  transition: opacity 300ms ease-out, transform 300ms cubic-bezier(.34, .55, .25, .83);
  font-size: 1em;
  font-weight: 500;
  opacity: 0;
  transform: translate3d(50px, 0, 0);

  .is-open & {
    opacity: 0;
    transform: translate3d(0px, 0, 0);
    opacity: 1;
  }
}

$menu-animation-duration: 400ms;
$menu-animation-timing:   ease-out;

.burger-menu-piece {
  display: block;
  position: absolute;
  width: 20px;
  border-top: 2px solid $white;
  transform-origin: 50% 50%;
  transition: transform $menu-animation-duration $menu-animation-timing;

  &:nth-child(1) {
    top: 11px;
  }

  &:nth-child(2) {
    top: 17px;
    opacity: 1;
    transition: transform $menu-animation-duration $menu-animation-timing, opacity 0ms linear $menu-animation-duration / 2;
  }

  &:nth-child(3) {
    top: 23px;
  }

  .active & {
    
      &:nth-child(1) {
        animation: burger-open-top $menu-animation-duration $menu-animation-timing forwards;
      }
    
      &:nth-child(2) {
        opacity: 0;
        transition: transform $menu-animation-duration $menu-animation-timing, opacity 0ms linear $menu-animation-duration / 2;
      }
    
      &:nth-child(3) {
        animation: burger-open-bot $menu-animation-duration $menu-animation-timing forwards;
      }
  }
  
  .closing & {
    &:nth-child(1) {
      animation: burger-close-top $menu-animation-duration $menu-animation-timing forwards;
    }
    
    &:nth-child(3) {
      animation: burger-close-bot $menu-animation-duration $menu-animation-timing forwards;
    }
  }
}

@keyframes burger-open-top {
  50% {
    transform: translate3d(0, 6px, 0);
  }
  100% {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
}

@keyframes burger-open-bot {
  50% {
    transform: translate3d(0, -6px, 0);
  }
  100% {
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
}

@keyframes burger-close-top {
  0% {
    transform: translate3d(0, 6px, 0) rotate(45deg);
  }
  50% {
    transform: translate3d(0, 6px, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes burger-close-bot {
  0% {
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
  50% {
    transform: translate3d(0, -6px, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
