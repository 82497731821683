div.content-inner-header {
	width: 100%;
	height: 450px;
	background-color: tomato;
	background-position: 50% 50% !important;
	background-size: cover !important;
}

div.no-hashtag {

	h2 {
		margin-top: 30px;
	}

	p {
		margin-bottom: 30px;
	}
}

table.table-product {
	width: 100%;

	tbody {

		td { 
			border-top: 1px solid #dddddd;
		}
	}
}
section.content-inner-page {
	width: 100%;
	background: $white;
	overflow: hidden;
	margin-bottom: 40px;

	&.fromages div.container div.container-block {

		width: calc(25% - 20px);

		@media screen and (max-width: 1000px) {
			width: calc(50% - 20px);
		}

		div.container-block-background {

			height: 250px !important;
			//width: 25%;

			img {
				width: 100%;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				padding: 20px;
			}
		}

		div.container-block-txt {
			//width: 75%;
		} 
	}

	div.container {

		max-width: 960px;
		width: 100%;
		margin: 0 auto;
		margin-top: 40px;
		margin-bottom: 40px;

		p.breadcrumb {

			color: rgba($black, .8);
			//margin-bottom: 30px;
					font-size: .9em;
					opacity: .9;
			margin-left: 10px;

			a {
				color: inherit;

				&.active {
					color: $red;
				}
			}
		}


		div.container-block {

			overflow: hidden;
			box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
			margin-bottom: 20px;
			transition: $transition;

			width: calc(33.33333% - 20px);
    		float: left;
    		margin: 10px;

			//height: 280px !important;

			&.inverted {

				div.container-block-background {
					// float: left;
				}

				div.container-block-txt {
					// float: right;
				}
			}

			&:hover {
				transform: scale(0.98);
			}


			div.container-block-background {
				width: 100%;
				height: 300px !important;
				// float: right;
				background-position: 50% 50% !important;
				background-size: cover !important;

			}

			div.container-block-txt {
				height: auto;
				width: 100%;
				// float: left;
				padding: 30px;
				color: rgba($black, .9);

				text-align: center;
				position: relative;

				h2 {
					margin-top: 0;
					font-size: 1.5em;
					font-weight: 500;
				}
				
				p {
					line-height: 20px;

					&.discover {
						margin: 0;
						opacity: .8;
					}

				}

				button {
					
					border: 2px solid $black;
					border-radius: 50px;
					width: 65px;
					margin: 0 auto;
					padding: 5px 0px;
					background: transparent;
					opacity: .9;


					img {
						width: 13px;
						position: relative;
						top: 1px;
						transition: $transition;
					}
				}
			}
			
			&.products {
				
				width: calc(33.33333% - 20px);
				box-shadow: none;
				
				.container-block-background {
					background-size: contain !important;
					background-repeat: no-repeat !important;
				}
				
				.container-block-txt {
				
					h2 {
						font-size: 1em;
					}
				
				}
			}

			div.packaging {
				position: absolute;
				background: $white;
				height: 120px;
				width: 120px;
				border-radius: 120px;
				top: 160px;
				left: 0;
				right: 0;
				margin: 0 auto;
				opacity: 0;
				transform: scale(.5);
				transition: .2s ease;
				overflow: hidden;

				img {
					max-width: 100%;
					position: relative;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			&:hover {
				div.packaging {
					opacity: 1;
					transform: scale(1);
				}
			}

		}

	}

}


// SPECIAL RULES INNER 

section.content-inner-page.fondus, section.content-inner-page.bloc, section.content-inner-page.tranches, section.content-inner-page.fagotin {

	div.container {

		a div.container-block {

			box-shadow: 0 0 20px 0 rgba(0,0,0,0);

			div.container-block-background {
				background-size: auto 100% !important;
				background-repeat: no-repeat !important;
				height: 200px !important;
			}

			div.container-block-txt {

				padding-top: 10px;

				h2 {
					height: 70px;
					font-family: 'Niconne', cursive;
					font-size: 1.8em;
					line-height: 1em;
					color: rgba($black, .8);
				}

				// p {
				// 	display: none;

				// 	&.discover {
				// 		display: block;
				// 	}

				// }
			}
		}
	}
}

section.content-inner-page.fagotin {
	div.container {
		a div.container-block {
			div.container-block-background {
				background-size: 100% !important;
			}
		}
	}
}












