section.mosaic_recettes {
	
	
	background: none !important;
	$red-second : #e40215;


	div.linear-back {
		position: fixed;
		width: 100%;
		height: 100%; 
		content: '';
		background-size: 100% auto !important;
		top: 0; 
		left: 0;
	}

	div.container {

		div.selector {
			background: $white;
			padding: 15px;
			padding-top: 20px;
			padding-left: 20px;
			width: calc(100% - 20px);
			margin: 0 auto;
			margin-bottom: 10px;
			position: relative;
			overflow: hidden;
			
			&.search {
				
				padding-top: 15px;
				
				input {
				
					clear: both;
					display: block;
					width: 100%;
					padding: 0.3em 0.6em;
					font-size: 1em;
					box-sizing: border-box;
					
					border: none;
					border-bottom: 1px solid #999;
					
					margin: 0;
					
					@include placeholder {
						color: #171717 !important;
					}
					
					&:focus {
						border-color: #171717;
						box-shadow: none;
					}
				
				}
			} 

			form {

				p {
					margin: 0;
					float: left;
					margin-right: 18px;
					

					label {
						line-height: 22px;
						color: #919191;
						font-size: .9em;
						padding-left: 24px;
					}

					[type=checkbox].filled-in:not(:checked)+label:after {
						background-color: rgba(90, 90, 90, 0.12);
					    border: 2px solid rgba(90, 90, 90, 0.0);
;
					}

					[type=checkbox].filled-in:checked+label:after {
						border: 2px solid $red;
						background-color: $red;
					}

					img {
						width: 17px;
						position: relative;
						top: 2px;
						margin-right: 4px;
						opacity: .6;
					}
				}
			}
		}
		
		a {
			&.filtered,
			&.hide {
				display: none;
			}
		}

		div.mosaic_block {
			// width: 50%;
			height: auto !important;
			background: $white;
			box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
			
			//&.filtered,
			//&.hide {
			//	display: none;
			//}
 
			&:hover {
				transform: scale(.99);
			}

			a.goto {
				position: absolute;
    			right: 18px;
    			bottom: 10px;
    			width: 20px;
    			opacity: .5;
    			transition: .3s ease;
 

			}

			&:hover {
				.goto {
					right: 12px;
				}
			}

			div.mosaic_background {
				width: 320px;
				float: left;
				position: relative;
				height: 210px;
				background-position: 80% 50% !important;
			}

			div.mosaic_infos {
				position: relative;
				padding: 25px;
				float: left;
				height: 140px;
				width: 100%;

				&.videos {
					p.timing {
						&:after {
							content: '';
							position: absolute;
							height: 18px;
							width: 18px;
							background: url('../img/youtube_picto.svg');
							right: -28px;
							top: 2px;
							opacity: .8;
						}
					}
				}

				@import url('https://fonts.googleapis.com/css?family=Niconne|Rochester');

				h3 {
					margin: 0;
					// text-transform: uppercase;
					color: $red-second;
					font-weight: 500;
					font-size: 1.1em;
					max-width: 450px;


	    			//LONG LIVE FLEXBOSS
	    			overflow: hidden;
	    			 display: -webkit-box;
	  				-webkit-line-clamp: 3;
	  				-webkit-box-orient: vertical; 

	  		// 		font-family: 'Rochester', cursive;
					// font-family: 'Niconne', cursive;
					// font-size: 1.6em;
					// line-height: 1em;


				}



				p {
					margin: 8px 0;
					color: rgba($black, .7);
					font-size: .9em;
					position: absolute;
    				bottom: 11px;

				}
 
				p.level {
					position: absolute;
					bottom: 20px;
					margin-bottom: 0;
					opacity: .8;
					font-size: .8em;
				}
				p.timing {
					img {
						width: 15px;
						opacity: .7;
						position: relative;
						top: 3px;

					}
				}
			}

			div.arrow {
				position: absolute;
				width: 50px;
				height: 50px;
				top: 48px;
				
				right: 100px;
				background: url('../img/next.svg') 50% 50%;
				background-size: cover;
				transition: .5s ease;
			}

			&:hover {
				div.arrow {
					// right: 90px;
				}

				.goto {
					opacity: 1;

				}
			}

			div.type {
				position: absolute;
				right: 0;
				top: 0;
				height: 210px;
				width: 50px;
				background: $red-second;
				background: rgba(48, 48, 48, 0.65);


				span {
					color: $white;
					text-transform: uppercase;
					font-weight: 600;
				    transform: rotate(-90deg);
				    transform-origin: 50% 50%;
				    height: 210px;
				    width: 210px;
				    text-align: center;
				    font-size: 1em;
				    position: absolute;
				    margin-left: 15px;

				    img {
				    	width: 30px;
				    	transform: rotate(90deg);
				    	margin-top: -6px;
				    }

				}
			}

			div.txt {
				float: none;
				clear: both;
				width: 100%;
				padding: 30px;
				background: #fafafa;
				max-height: 0px;
				padding: 0px;
				transition: .8s ease;
				overflow: hidden;

				div.holder {
					padding: 30px;

					h3 {
						color: $red-second;
						margin-top: 0;
					}

					ul,ol {
						font-size: .9em;
						opacity: .9;
					}

					ul {
						list-style-type: none;
						padding-left: 0;

						li {

							padding-top: 5px;
							&:before {
    							content: "-";
    							margin-right: 10px;
    						}
						}
					}

					ol {
						padding-left: 16px;

						li {
							padding-top: 5px;
						}
					}
				}

 			}

 			&.open {
 				div.txt {
 					max-height: 1000px;
 				}

 				div.arrow {
 					transform: rotate(90deg);
 				}
 			}
		}
	}
}

























