footer {
	min-height: 500px;
	width: 100%;
	background: linear-gradient(180deg,transparent 0%,hsla(0,0%,2%,.6)), url('../img/footer.jpg');
	background-size: cover;
	background-position: 50% 0%;
	overflow: hidden;
	padding: 50px;
	padding-bottom: 30px;
	position: relative;

	div.upper {
		margin-top: 150px;
		overflow: hidden;

		@media screen and (max-width: 1000px) {
			margin-top: 40px;
		}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		float: left;
		padding: 40px;

		@media screen and (max-width: 1000px) {
	    	width: 50%; 
	    }

	   	@media screen and (min-width: 1000px) {

			&:first-of-type { 
				padding-left: 0;
			}
		}

		h4 {
			text-transform: uppercase;
			margin: 0;
			color: $gold;
			margin-bottom: 5px;
		}

		li {
			margin: 0;
			padding: 2px 0px;

			a {
				text-decoration: none;
				color: $white;
				font-weight: 500;
				transition: .3s ease;
				position: relative;
				left: 0;

				&:hover {
					left: 5px;
				}
			}
		}
	}
	}



	div.lower {
		border-top: 1px solid rgba($white,.5);
		margin-top: 40px;
		padding-top: 30px;
		overflow: hidden;

		a.logo {
			width: 100px;
			float: left;

			img {
				width: 100px;
			}
		}
	}

	p.legal {
		color: $white;
		opacity: .8; 
		float: left;
		margin-left: 10px;
		font-size: .8em;
	}


	// MINI NAV

	ul.mini-nav {
		list-style: none;
		margin: 0 auto;
		padding: 0;
		float: right;
	    position: relative;
	    top: 6px;
	    opacity: .8;

	    @media screen and (max-width: 1000px) {
	    	width: 50%; 
	    }

		li {
			display: inline-block;
			padding-left: 10px;

			a {
				color: $white;
				text-decoration: none;
				padding: 10px 19px;
				border: 1px solid $white;
				border-radius: 50px;
				position: relative;
				width: 10px;
				height: 10px;
				font-weight: 500;

				&.inspiration {
					text-transform: uppercase;
					font-size: .9em;

					i {
						position: initial;
						top: initial;
						transform: none;
						margin-right: 5px;
					}
				}

				i {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
					right: 0;
					margin: 0 auto;
					text-align: center;
				}

			}
		}
	}
}