
div.slider-holder {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;

}


.cd-image-container {
	position: relative;
	width: 100%;
	height: 100vh;
	// max-width: $M; // breakpoints inside partials > _layout.scss
	margin: 0em auto;
		margin-top: 50vh;
    transform: translateY(-50%);

    background: url('../img/Bel_180502_1880_BDEF-compressor.jpg') 50% 50%;
    background-size: cover;

	img {
		display: block;
	}
}

.cd-image-label {
	position: absolute;
	top: 50%;
	left: 50%;
	color: $color-3;
	padding: 1em;
	opacity: 0;
	transition: transform .3s $bounce-in-duration, opacity .3s $bounce-in-duration;

	&.is-hidden {
		visibility: hidden;
	}

	.is-visible & {
		opacity: 1;
		transform: translateY(0);
	}
}

.cd-resize-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	overflow: hidden;

	transform: translateZ(0);
	backface-visibility: hidden;

	@media screen and (max-width: 1000px) {
		width: 100% !important;
	}

	div.img-holder-full {
			background: url('../img/Bel_180502_1880_BDEF-2-compressor.jpg') 50% 50%;
    		background-size: cover;
    		position: absolute;
    		top: 0;
    		left: 0;
    		height: 100%;
    		width: 100vw;
    		
    		
    		.title {
	    		position: absolute;
	    		top: 50%;
	    		left: 50%;
	    		transform: translate(-50%, -50%);

	    		@media screen and (max-width: 1000px) {
	    			text-align: center;
	    		}
    		}
    		
	}
	// border-right: 4px solid white;
	// outline-right: 4px solid white;

	img {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		height: 100%;
		width: auto;
		max-width: none;
	}

	.cd-image-label {
		//right: auto;
		//left: 0;
	}

	.is-visible & {
		width: 15%;
	}

	&.animation-1 {

			width: 85%;
			animation-name: discoverwidth;
			animation-timing-function: ease;
			animation-duration: 2s;
			animation-delay: .5s;
			animation-fill-mode: backwards;

						@media screen and (max-width: 670px) {
				animation-delay: 0s;
			}
	}
}

@keyframes discover {
	from {
		left: 15%;
	}
	to {
		left: 85%;
	}
}

@keyframes discoverwidth {
	from {
		width: 15%;
	}
	to {
		width: 85%;
	}
}

@keyframes cd-bounce-in {
	0% {
		width: 0;
	}

	60% {
		width: 55%;
	}
 
	100% {
		width: 50%;
	}
}

.cd-handle { 
	position: absolute;
	height: 140px;
	width: 140px;
	/* center the element */
	left: 15%;
	top: 50%;
	margin-left: -70px;
	margin-top: -70px;
	border-radius: 50%;
	// background: $color-2 url('../img/cd-arrows.svg') no-repeat center center;
	background: rgba(black,.5);
	// background: rgb(255, 207, 112);
	cursor: move;
	// box-shadow: 0 0 0 6px rgba(#000, .2), 0 0 10px rgba(#000, .6), inset 0 1px 0 rgba(#fff, .3);
	border: 5px solid white;
	opacity: 0;
	transform: translate3d(0,0,0) scale(0);

	&.animation-2 {

		left: 85%;
		animation-name: discover;
		animation-timing-function: ease;
		animation-duration: 2s;
		animation-delay: .5s;
		animation-fill-mode: backwards;

		@media screen and (max-width: 670px) {
				animation-delay: 0s;
			}
	}


	&:before , &:after{
		content: " ";
	    display: block;
	    width: 5px;
	    background: white;
	    height: 9999px;
	    position: absolute;
	    left: 50%;
	    margin-left: -2px;
	    z-index: 30;
	}

	&:before {
		bottom: 50%;
    	margin-bottom: 68px;
	}

	&:after {
		top: 50%;
    	margin-top: 68px;
	}

	img {
		max-width: initial;
		width: 215px;
		position: absolute;
		top: 50%;
		transform: translateY(-45%);
		left: -42px;

	}

	&.draggable {
		/* change background color when element is active */
		// background-color: rgba(black,.8);
	}

	.is-visible & {
		opacity: 1;
		transform: translate3d(0,0,0) scale(1);
		transition: transform .3s $bounce-in-duration, opacity 0s $bounce-in-duration;
	 }
}